import Intercom from "@intercom/messenger-js-sdk";
import { subdomain } from "./common";

export const showChatBox = () => {
  if (isNoIntercom()) return;
  window.Intercom("update", {
    hide_default_launcher: false,
  });
};

export const openChatWindow = () => {
  if (isNoIntercom()) return;
  window.Intercom("show");
};

export const hideChatBox = () => {
  if (isNoIntercom()) return;
  window.Intercom("update", {
    hide_default_launcher: true,
  });
};

export const isNoIntercom = () => {
  return window.Intercom === undefined;
};

export const bootChatBox = (user) => {
  if (!isNoIntercom()) {
    bootIntercom(user);
  }
};

const boot = (user, leftPadding) => {
  const client = subdomain();
  const userDetails = {
    alignment: "left",
    horizontal_padding: leftPadding,
    vertical_padding: 20,
    subdomain: client,
    company: {
      id: client,
      name: client,
    },
  };

  if (user) {
    userDetails.user_id = `${client}:user${user.id}`;
    userDetails.name =
      user?.firstName && user?.lastName
        ? `${user?.firstName} ${user?.lastName}`
        : "";
    userDetails.hide_default_launcher = true;
    userDetails.email = user.email;
    userDetails.subdomain = client;
  }

  Intercom({
    app_id: import.meta.env.VITE_INTERCOM_API,
    ...userDetails,
  });
};

export const bootIntercom = (user) => {
  if (isNoIntercom()) return;
  if (user) {
    boot(user, 80);
  } else {
    boot(user, 20);
  }
};

export const bootIntercomAsVisitor = (user) => {
  if (isNoIntercom()) return;
  user = null; // Start as visitor
  boot(user, 20);
};

export const shutdownChatBox = () => {
  if (isNoIntercom()) return;
  try {
    window.Intercom("shutdown");
  } catch (err) {
    console.error("Intercom error during shutdown", err);
  }
};
