import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { setProjectApprovers } from "@/store/reducers/company";
import { setIsFormSubmissionProgress } from "@/store/reducers/loadersError";
import { REIMBURSEMENT_SETTINGS_TYPE } from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/SettingsType";
import {
  getErrorToastMessage,
  getSuccessToastMessage,
  waitForMilliseconds,
} from "@/utils/common";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import { REPORT_ACTION_PARAMS, TAB_TYPES } from "@/utils/constants/myVolopay";
import {
  APPROVAL_TABS,
  PAGE_TYPE,
  PAYMENTS,
  REIMBURSEMENT_ACTION_PARAMS,
  REIMBURSEMENT_STATUS,
} from "@/utils/constants/reimbursement";
import vToast from "@/utils/vToast";

import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import API from "@/api";

export const REIMBURSEMENTS_SLICE_ATTRIBUTE_KEY = "reimbursements";

/**
 * To be used inside thunk or slice's reducer
 *
 * @param {{Object=}} actionPayload  thunk's first param or `action.payload` (`action` is the second param of the slice reducer)
 *
 * @returns {{key: REIMBURSEMENTS_SLICE_ATTRIBUTE_KEY  value: Object=}}
 */

const getListKeyAndPayload = (actionPayload) => {
  actionPayload ??= {};

  // Note: if 'key' is absent, 'value' is the same as payload
  // and it is assumed the operation is meant for purchaseBill, not recurring payments
  // Why do this: keeps existing code simple, i.e. `dispatch(someReducer(valueToSet))` works,
  // as opposed to forcing `dispatch(someReducer({value: valueToSet, key: ...}))` for purchaseBill ops

  return {
    key: actionPayload?.key || REIMBURSEMENTS_SLICE_ATTRIBUTE_KEY,
    value: actionPayload?.key ? actionPayload?.value : actionPayload,
  };
};

const reimbursementInitialState = {
  reimbursements: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: false,
    hasMore: true,
    pageType: null,
  },
  rejectedReimbursements: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: false,
    hasMore: true,
  },
  reports: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: false,
    hasMore: true,
  },
  userReimbursement: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: false,
    hasMore: true,
  },

  userReport: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: false,
    hasMore: true,
  },
  reimbursementPaymentHistory: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    totalAmount: null,
    totalPaid: null,
    isFetching: false,
    hasMore: true,
    pageType: null,
  },
  selectedUserReimbursement: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    totalClaims: null,
    isFetching: false,
    hasMore: true,
    createdBy: null,
    ctas: null,
    amount: null,
  },

  selectedUserReport: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    totalClaims: null,
    totalReports: null,
    isFetching: false,
    hasMore: true,
    createdBy: null,
    ctas: null,
    amount: null,
  },
  selectedPaymentHistoryData: null,
  selectedPaymentHistoryListingData: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: false,
    hasMore: true,
  },
  selectedReimbursement: null,
  isReimbursementFetching: false,
  isUserReimbursementFetching: false,
  isReimbursementHistoryFetching: false,
  isFetchingReimbursementReceipt: false,
  createClaimForm: null,

  deletedClaimsIdForReport: [],

  isCreateLoading: false,
  createReimbursementClaimForm: null,
  mileageRateList: {
    list: [],
    isFetching: false,
  },
  changesMade: {
    formData: { userReimbursement: [], selectedUserReimbursement: [] },
    needsyourAction: [],
    paid: [],
    "others-action": [],
    rejected: [],
  },
  claimPolicyData: null,
  isClaimPolicyDataFetching: false,
  reimSettingUsersList: {
    list: [],
    isFetching: false,
  },
  reimbursementSetting: {
    reimbursementAutopayEnabled: null,
    reimbursementAutopayDay: null,
  },
  automatedPaymentToggleSwitch: false,

  isLoading: false,
  currencyList: null,
  isCtaActionPendingForId: null,

  directionResponse: null,
  selectedPaymentsData: null,
  claimCategoryLimitDetails: null,
  recentlyUploadedFiles: [], // to check recently uploaded files
  isFetchingBulkUpload: false,
  creatingduplicateReimbursement: false,
  // reports related state
  isFetchingReport: false,
  selectedReport: null,
  selectedClaimForReport: [],
  selectedAllClaimsForReport: false,
  createReportFormData: null,
  isCreatingReport: false,
  showEmptyList: false,
  rejectReportReason: null,
  reimbursementClaimSubmissionPolicy: null,
  isFetchingReimbursementClaimSubmissionPolicy: false,
  bulkUploadResponse: null,
  bulkUploadRefreshData: [],
  bulkUploadRecordIds: [],
  bulkUploadCount: 0,

  filterKeys: {
    reimbursementDraft: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.claimType,
    ],
    approvals: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.reimbursementSubmissionDate,
      AVAILABLE_FILTER_KEYS.claimType,
    ],
    reportApprovals: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.datePeriods,
    ],
    approvalsAll: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.reimbursementSubmissionDate,
      AVAILABLE_FILTER_KEYS.claimType,
      AVAILABLE_FILTER_KEYS.claimStatus,
    ],
    reportApprovalsAll: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.reportStatus,
    ],
    payments: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.unsettledAmount,
    ],
    paid: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.settledAmount,
    ],
    reimbursementReport: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.datePeriods,
    ],
    paymentHistory: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.paymentStatus,
    ],
    history: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.claimType,
      AVAILABLE_FILTER_KEYS.paymentHistoryStatus,
    ],
    historyReport: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.paymentHistoryStatus,
    ],
  },
  rejectedClaimPayload: [],
  editAmount: null, // deliberately kept null
  editAmountReason: "",
  settingsExtraKeys: {}, // used for dynamic redux keys, e.g. for individual setting's isFetching state
};

const reimbursementSlice = createSlice({
  name: "reimbursement",
  initialState: reimbursementInitialState,
  reducers: {
    setMileageRateList(state, action) {
      state.mileageRateList.list = action.payload;
    },
    setMileageRateListIsFetching(state, action) {
      state.mileageRateList.isFetching = action.payload;
    },
    addMileageRateList(state, action) {
      const listToAppend = action.payload;
      state.mileageRateList.list =
        state.mileageRateList.list.concat(listToAppend);
    },
    updateMileageRateList(state, action) {
      const indexToReplace = state.mileageRateList.list.findIndex(
        (obj) => obj.id === action.payload[0].id
      );
      if (indexToReplace !== -1) {
        state.mileageRateList.list[indexToReplace] = { ...action.payload[0] };
      }
    },
    deleteMileageRateList(state, action) {
      state.mileageRateList.list = state.mileageRateList.list.filter(
        (item) => item.id !== action.payload
      );
    },
    setReimbursements(state, action) {
      state.reimbursements.list = action.payload;
    },
    updateReimbursement(state, action) {
      const { id, value } = action.payload;
      const reimbursementIndex = state.reimbursements.list.findIndex(
        (reimbursement) => Number(reimbursement.id) === Number(id)
      ); // linear search for now

      if (reimbursementIndex !== -1) {
        state.reimbursements.list[reimbursementIndex] = value;
      }
    },
    updateSingleReport(state, action) {
      const { id, value } = action.payload;
      const reportIndex = state.reports.list.findIndex(
        (report) => Number(report.id) === Number(id)
      );

      if (reportIndex !== -1) {
        state.reports.list[reportIndex] = value;
      }
    },

    setSelectedPaymentHistoryData(state, action) {
      state.selectedPaymentHistoryData = action.payload;
    },
    setReimbursementPaymentHistory(state, action) {
      state.reimbursementPaymentHistory.list = action.payload;
    },

    setReimbursementPaymentHistoryAmount(state, action) {
      state.reimbursementPaymentHistory.totalAmount = action.payload;
    },

    setReimbursementSetting(state, action) {
      state.reimbursementSetting = action.payload;
    },

    setClaimCategoryLimitDetails(state, action) {
      state.claimCategoryLimitDetails = action.payload;
    },
    setReimbursementInitialState(state) {
      state.reimbursements.list = [];
      state.reimbursements.page = 1;
      state.reimbursements.hasMore = true;
      state.reimbursements.total = 0;
      state.reimbursements.isFetching = false;
    },

    setReportInitialState(state) {
      state.reports.list = [];
      state.reports.page = 1;
      state.reports.hasMore = true;
      state.reports.total = 0;
      state.reports.isFetching = false;
    },

    setRejectedClaimsInitialState(state) {
      state.rejectedReimbursements.list = [];
      state.rejectedReimbursements.page = 1;
      state.rejectedReimbursements.hasMore = true;
      state.rejectedReimbursements.total = 0;
      state.rejectedReimbursements.isFetching = false;
    },
    setReimbursementPaymentHistoryInitialState(state) {
      state.reimbursementPaymentHistory.list = [];
      state.reimbursementPaymentHistory.page = 1;
      state.reimbursementPaymentHistory.hasMore = true;
      state.reimbursementPaymentHistory.total = 0;
      state.reimbursementPaymentHistory.isFetching = false;
    },

    setSelectedPaymentsData(state, action) {
      state.selectedPaymentsData = {
        ...state.selectedPaymentsData,
        ...action.payload,
      };
    },
    setDirectionResponseData(state, action) {
      state.directionResponse = action.payload;
    },

    setSelectedPaymentsInitialData(state) {
      state.selectedPaymentsData = null;
    },

    setCurrenciesList(state, action) {
      state.currencyList = action.payload;
    },

    setReimbursementHasMore(state) {
      state.reimbursements.hasMore =
        state.reimbursements.list.length < state.reimbursements.total;
    },
    setReimbursementPaymentHistoryHasMore(state) {
      state.reimbursementPaymentHistory.hasMore =
        state.reimbursementPaymentHistory.list.length <
        state.reimbursementPaymentHistory.total;
    },
    setCreateClaimFormDetails(state, action) {
      state.createClaimForm = action.payload;
    },

    setIsCreateLoading(state, action) {
      state.isCreateLoading = action.payload;
    },
    setReimbursementTotal(state, action) {
      state.reimbursements.total = action.payload;
    },
    setReimbursementTotalAmount(state, action) {
      state.reimbursements.totalAmount = action.payload;
    },

    setUserReimbursementHasMore(state) {
      state.userReimbursement.hasMore =
        state.userReimbursement.list.length < state.userReimbursement.total;
    },

    // reports
    setReportsList(state, action) {
      state.reports.list = action.payload;
    },
    appendReports(state, action) {
      state.reports.list = [...state.reports.list, ...action.payload];
    },
    setReportTotal(state, action) {
      state.reports.total = action.payload;
    },

    setReportHasMore(state) {
      state.reports.hasMore = state.reports.list.length < state.reports.total;
    },

    setReportLimit(state, action) {
      state.reports.limit = action.payload;
    },

    setReportPage(state, action) {
      state.reports.page = action.payload;
    },

    setReportIsFetching(state, action) {
      state.reports.isFetching = action.payload;
    },

    // User Reports
    setUserReportsList(state, action) {
      state.userReport.list = action.payload;
    },
    appendUserReports(state, action) {
      state.userReport.list = [...state.userReport.list, ...action.payload];
    },
    setUserReportTotal(state, action) {
      state.userReport.total = action.payload;
    },

    setUserReportHasMore(state, action) {
      state.userReport.hasMore =
        state.userReport.list.length < state.userReport.total;
    },

    setUserReportLimit(state, action) {
      state.userReport.limit = action.payload;
    },

    setUserReportPage(state, action) {
      state.userReport.page = action.payload;
    },

    setUserReportIsFetching(state, action) {
      state.userReport.isFetching = action.payload;
    },

    // rejected claims
    setRejectedReimbursementsList(state, action) {
      state.rejectedReimbursements.list = action.payload;
    },
    appendRejectedReimbursementList(state, action) {
      state.rejectedReimbursements.list = [
        ...state.rejectedReimbursements.list,
        ...action.payload,
      ];
    },
    setRejectedReimbursementsTotal(state, action) {
      state.rejectedReimbursements.total = action.payload;
    },

    setRejectedReimbursementsHasMore(state, action) {
      state.rejectedReimbursements.hasMore =
        state.rejectedReimbursements.list.length <
        state.rejectedReimbursements.total;
    },

    setRejectedReimbursementsLimit(state, action) {
      state.rejectedReimbursements.limit = action.payload;
    },

    setRejectedReimbursementsPage(state, action) {
      state.rejectedReimbursements.page = action.payload;
    },

    setRejectedReimbursementsIsFetching(state, action) {
      state.rejectedReimbursements.isFetching = action.payload;
    },

    // selected user reimbursement

    setSelectedUserReimbursementList(state, action) {
      state.selectedUserReimbursement.list = action.payload;
    },
    setSelectedUserReimbursementHasMore(state) {
      state.selectedUserReimbursement.hasMore =
        state.selectedUserReimbursement.list.length <
        state.selectedUserReimbursement.totalClaims;
    },

    setSelectedUserReimbursementTotalClaims(state, action) {
      state.selectedUserReimbursement.totalClaims = action.payload;
    },

    setSelectedUserReimbursementCreatedBy(state, action) {
      state.selectedUserReimbursement.createdBy = action.payload;
    },

    setSelectedUserReimbursementAmount(state, action) {
      state.selectedUserReimbursement.amount = action.payload;
    },

    setSelectedUserReimbursementCtas(state, action) {
      state.selectedUserReimbursement.ctas = action.payload;
    },
    setSelectedUserReimbursementPage(state, action) {
      state.selectedUserReimbursement.page = action.payload;
    },

    setSelectedUserReimbursementInitialState(state) {
      state.selectedUserReimbursement.list = [];
      state.selectedUserReimbursement.page = 1;
      state.selectedUserReimbursement.hasMore = true;
      state.selectedUserReimbursement.total = 0;
      state.selectedUserReimbursement.createdBy = null;
      state.selectedUserReimbursement.amount = null;
      state.selectedUserReimbursement.ctas = null;
    },
    appendSelectedUserReimbursements(state, action) {
      state.selectedUserReimbursement.list = [
        ...state.selectedUserReimbursement.list,
        ...action.payload,
      ];
    },

    // selected user report

    setSelectedUserReportList(state, action) {
      state.selectedUserReport.list = action.payload;
    },
    setSelectedUserReportHasMore(state) {
      state.selectedUserReport.hasMore =
        state.selectedUserReport.list.length <
        state.selectedUserReport.totalReports;
    },

    setSelectedUserReportTotalClaim(state, action) {
      state.selectedUserReport.totalClaims = action.payload;
    },
    setSelectedUserReportTotalReports(state, action) {
      state.selectedUserReport.totalReports = action.payload;
    },
    setSelectedUserReportCreatedBy(state, action) {
      state.selectedUserReport.createdBy = action.payload;
    },

    setSelectedUserReportAmount(state, action) {
      state.selectedUserReport.amount = action.payload;
    },

    setSelectedUserReportPage(state, action) {
      state.selectedUserReport.page = action.payload;
    },

    setSelectedUserReportInitialState(state) {
      state.selectedUserReport.list = [];
      state.selectedUserReport.page = 1;
      state.selectedUserReport.hasMore = true;
      state.selectedUserReport.total = 0;
      state.selectedUserReport.createdBy = null;
      state.selectedUserReport.amount = null;
      state.selectedUserReport.ctas = null;
    },

    setSelectedUserReportListInitialState(state) {
      state.selectedUserReport.list = [];
      state.selectedUserReport.page = 1;
      state.selectedUserReport.hasMore = true;
      state.selectedUserReport.total = 0;
    },
    appendSelectedUserReports(state, action) {
      state.selectedUserReport.list = [
        ...state.selectedUserReport.list,
        ...action.payload,
      ];
    },

    setSelectedUserReportCtas(state, action) {
      state.selectedUserReport.ctas = action.payload;
    },

    setUserReimbursementTotal(state, action) {
      state.userReimbursement.total = action.payload;
    },

    setUserReimbursementInitialState(state) {
      state.userReimbursement.list = [];
      state.userReimbursement.page = 1;
      state.userReimbursement.hasMore = true;
      state.userReimbursement.total = 0;
      state.userReimbursement.isFetching = false;
    },
    setUserReportInitialState(state) {
      state.userReport.list = [];
      state.userReport.page = 1;
      state.userReport.hasMore = true;
      state.userReport.total = 0;
      state.userReport.isFetching = false;
    },
    addReimbursements(state, action) {
      state.reimbursements.list = [
        ...state.reimbursements.list,
        ...action.payload,
      ];
    },

    addReimbursementHistory(state, action) {
      state.reimbursementPaymentHistory.list = [
        ...state.reimbursementPaymentHistory.list,
        ...action.payload,
      ];
    },

    setReimbursementPaymentHistoryTotalPaid(state, action) {
      state.reimbursementPaymentHistory.totalPaid = action.payload;
    },

    setReimbursementPaymentHistoryListTotal(state, action) {
      state.reimbursementPaymentHistory.total = action.payload;
    },

    setpage(state, action) {
      state.reimbursements.page = action.payload;
    },
    setReimbursementPageType(state, action) {
      state.reimbursements.pageType = action.payload;
    },
    setUserReimbursements(state, action) {
      state.userReimbursement.list = action.payload;
    },
    addUserReimbursements(state, action) {
      state.userReimbursement.list = [
        ...state.userReimbursement.list,
        ...action.payload,
      ];
    },

    setIsUserReimbursementsFetching(state, action) {
      state.userReimbursement.isFetching = action.payload;
    },

    setIsReimbursementHistoryFetching(state, action) {
      state.reimbursementPaymentHistory.isFetching = action.payload;
    },

    setIsUserReimbursementFetching(state, action) {
      state.isUserReimbursementFetching = action.payload;
    },
    setIsReimbursementsFetching(state, action) {
      state.reimbursements.isFetching = action.payload;
    },
    setIsReimbursementFetching(state, action) {
      state.isReimbursementFetching = action.payload;
    },

    setIsClaimPolicyDataFetching(state, action) {
      state.isClaimPolicyDataFetching = action.payload;
    },
    setSelectedReimbursement(state, action) {
      state.selectedReimbursement = action.payload;
    },
    updateSelectedReimbursement(state, action) {
      state.selectedReimbursement = {
        ...state.selectedReimbursement,
        ...action.payload,
      };
    },
    removeReimbursement(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);

      const id = value;
      state[key] ||= {};
      state[key].list ||= [];
      state[key].list = state[key].list.filter((val) => val.id !== id);
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    // company claim policy data
    setClaimPolicyData(state, action) {
      state.claimPolicyData = action.payload;
    },
    clearClaimPolicyData(state, action) {
      state.claimPolicyData = action.payload;
    },
    setIsFetchingReimbursementReceipt(state, action) {
      state.isFetchingReimbursementReceipt = action.payload;
    },

    prependReimbursements(state, action) {
      state.reimbursements.list = [
        ...action.payload,
        ...state.reimbursements.list,
      ];
    },

    prependReport(state, action) {
      state.reports.list = [...action.payload, ...state.reports.list];
    },

    setReport(state, action) {
      const { id, value } = action.payload;
      const reportIndex = state.reports.list.findIndex(
        (report) => Number(report.id) === Number(id)
      ); // linear search for now

      if (reportIndex !== -1) {
        state.reports.list[reportIndex] = value;
      }
    },
    setReimbursement(state, action) {
      const { id, value } = action.payload;
      const reimIndex = state.reimbursements.list.findIndex(
        (reim) => Number(reim.id) === Number(id)
      ); // linear search for now

      if (reimIndex !== -1) {
        state.reimbursements.list[reimIndex] = value;
      }
    },

    setAttachment(state, action) {
      state.selectedReimbursement = {
        ...state.selectedReimbursement,
        ...action.payload,
      };
    },

    setReimbursementAutomatedPaymentToggleSwitch(state, action) {
      state.automatedPaymentToggleSwitch = action.payload;
    },
    setIsCtaActionPendingForId(state, action) {
      state.isCtaActionPendingForId = action.payload;
    },
    setIsFetchingBulkUpload(state, action) {
      state.isFetchingBulkUpload = action.payload;
    },
    setCreatingduplicateReimbursement(state, action) {
      state.creatingduplicateReimbursement = action.payload;
    },
    setSelectedClaimForReport(state, action) {
      state.selectedClaimForReport = action.payload;
    },
    appendSelectedClaimForReport(state, action) {
      state.selectedClaimForReport = state.selectedClaimForReport.concat(
        action.payload
      );
    },
    setSelectedAllClaimsForReport(state, action) {
      state.selectedAllClaimsForReport = action.payload;
    },
    setCreateReportFormData(state, action) {
      state.createReportFormData = action.payload;
    },
    setShowEmptyList(state, action) {
      state.showEmptyList = action.payload;
    },
    setSelectedReport(state, action) {
      state.selectedReport = action.payload;
    },
    setIsFetchingReport(state, action) {
      state.isFetchingReport = action.payload;
    },
    removeReport(state, action) {
      state.reports.list = state.reports.list.filter(
        (val) => val.id !== action.payload
      );
    },
    setIsCreateReportLoading(state, action) {
      state.isCreatingReport = action.payload;
    },
    setRejectReportReason(state, action) {
      state.rejectReportReason = action.payload;
    },

    addRejectedClaimPayload(state, action) {
      const { id, comment } = action.payload ?? {};
      const existingItemIndex = state.rejectedClaimPayload.findIndex(
        (item) => item.id === id
      );
      if (existingItemIndex !== -1) {
        state.rejectedClaimPayload[existingItemIndex] = { id, comment };
      } else {
        state.rejectedClaimPayload.push({ id, comment });
      }
    },

    removeRejectedClaimPayload(state, action) {
      const { id } = action.payload ?? {};
      const existingItemIndex = state.rejectedClaimPayload.findIndex(
        (item) => item.id === id
      );
      if (existingItemIndex !== -1) {
        state.rejectedClaimPayload.splice(existingItemIndex, 1);
      }
    },

    setRejectedClaimPayload(state, action) {
      state.rejectedClaimPayload = action.payload;
    },

    setDeletedClaimIdsForReport(state, action) {
      state.deletedClaimsIdForReport = [
        ...state.deletedClaimsIdForReport,
        action.payload,
      ];
    },

    setDeletedClaimIdsForReportInititalState(state) {
      state.deletedClaimsIdForReport = [];
    },
    setSelectedPaymentHistoryListingDataList(state, action) {
      state.selectedPaymentHistoryListingData.list = action.payload;
    },

    setSelectedPaymentHistoryListingDataPage(state, action) {
      state.selectedPaymentHistoryListingData.page = action.payload;
    },
    setSelectedPaymentHistoryListingDataTotal(state, action) {
      state.selectedPaymentHistoryListingData.total = action.payload;
    },
    setSelectedPaymentHistoryListingDataIsFetching(state, action) {
      state.selectedPaymentHistoryListingData.isFetching = action.payload;
    },
    setSelectedPaymentHistoryListingDataHasMore(state) {
      state.selectedPaymentHistoryListingData.hasMore =
        state.selectedPaymentHistoryListingData.list.length <
        state.selectedPaymentHistoryListingData.total;
    },
    setSelectedPaymentHistoryListingDataLimit(state, action) {
      state.selectedPaymentHistoryListingData.limit = action.payload;
    },
    setSelectedPaymentHistoryListingDataInitialState(state) {
      state.selectedPaymentHistoryListingData.list = [];
      state.selectedPaymentHistoryListingData.page = 1;
      state.selectedPaymentHistoryListingData.hasMore = true;
      state.selectedPaymentHistoryListingData.total = 0;
      state.selectedPaymentHistoryListingData.isFetching = false;
    },

    appendSelectedPaymentHistoryListingDataList(state, action) {
      state.selectedPaymentHistoryListingData.list = [
        ...state.selectedPaymentHistoryListingData.list,
        ...action.payload,
      ];
    },
    setReimbursementClaimSubmissionPolicy(state, action) {
      state.reimbursementClaimSubmissionPolicy = action.payload;
    },
    setIsFetchingReimbursementClaimSubmissionPolicy(state, action) {
      state.isFetchingReimbursementClaimSubmissionPolicy = action.payload;
    },

    setEditAmountState(state, action) {
      state.editAmount = action.payload?.editAmount;
      state.editAmountReason = action.payload?.editAmountReason;
    },
    resetEditAmountState(state, action) {
      state.editAmount = reimbursementInitialState.editAmount;
      state.editAmountReason = reimbursementInitialState.editAmountReason;
    },
    setBulkUploadResponseData(state, action) {
      state.bulkUploadResponse = action.payload;
    },

    setBulkUploadRefreshData(state, action) {
      state.bulkUploadRefreshData = action.payload;
    },

    setBulkUploadRecordIds(state, action) {
      state.bulkUploadRecordIds = action.payload;
    },
    setBulkUploadCount(state, action) {
      state.bulkUploadCount = action.payload;
    },
    setReimbursementSettingExtraKey: (state, action) => {
      state.settingsExtraKeys[action.payload.key] = action.payload.value;
    },
  },
});

export const createReimbursementClaim = createAsyncThunk(
  "reimbursements/createReimbursementClaim",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setIsCreateLoading(true));
    const { payload, tab, noToast = false, onSuccess = () => {} } = params;
    const [error, response] = await to(API.Reimbursements.create(payload));

    if (response?.data?.data) {
      if (tab === TAB_TYPES.DRAFTS) {
        dispatch(prependReimbursements([response?.data?.data]));
      }
      onSuccess(response?.data?.data?.id, response?.data?.data);

      if (!noToast) {
        vToast(getSuccessToastMessage(response));
      }
    } else if (error) {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsCreateLoading(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const updateReimbursementsDraft = createAsyncThunk(
  "reimbursements/updateReimbursementsDraft",
  async (params, { dispatch }) => {
    dispatch(setIsCreateLoading(true));
    const { id, payload, noToast = false, onSuccess } = params;

    const [error, response] = await to(API.Reimbursements.update(id, payload));

    if (!error && response) {
      const reimbursement = response.data;

      if (onSuccess) {
        onSuccess(response?.data?.data);
      }
      dispatch(setReimbursement({ id, value: reimbursement }));

      if (!noToast) {
        vToast(getSuccessToastMessage(response));
      }
    } else {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsCreateLoading(false));
  }
);

export const fetchReimbursements = createAsyncThunk(
  "reimbursements/fetchReimbursements",

  async (params, { dispatch }) => {
    dispatch(setReimbursementPageType(params.tab));
    dispatch(setIsReimbursementsFetching(true));
    const [err, response] = await to(API.Reimbursements.all(params));

    if (!err && response) {
      const { data } = response;
      if (params.page === 1) {
        dispatch(setReimbursements(data.list));
      } else {
        dispatch(addReimbursements(data.list));
      }
      dispatch(setReimbursementTotal(data.total));
      dispatch(setReimbursementTotalAmount(data?.totalAmount));
      dispatch(setReimbursementHasMore());
      dispatch(setpage(params.page));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsReimbursementsFetching(false));
  }
);

export const fetchAndSelectReimbursement = createAsyncThunk(
  "reimbursements/fetchAndSelectReimbursement",
  async (params, { dispatch }) => {
    const { onSuccess = () => {}, ...rest } = params;
    dispatch(setIsReimbursementFetching(true));
    const [err, response] = await to(API.Reimbursements.get(rest));

    if (response) {
      dispatch(setSelectedReimbursement(response.data));
      if (onSuccess) onSuccess(response.data);
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsReimbursementFetching(false));
  }
);

export const fetchAndSelectCreateClaimFormDetails = createAsyncThunk(
  "reimbursements/fetchAndSelectCreateClaimFormDetails",
  async (params, { dispatch }) => {
    dispatch(setIsReimbursementFetching(true));
    const [err, response] = await to(API.Reimbursements.get(params));

    if (!err && response) {
      const { data } = response;

      const createClaimFormData = {
        reimbursementType: data?.type,
        transactionAmount: data?.amount?.value,
        transactionCurrency: data?.amount?.currency,
        transactionDate: data?.transactionDate,
        receipts: data.receipts,
        memo: data.memo,
        project_id: data?.linkedTo.id,
        totalMiles: data?.distanceTravelled,
        travelDate: data.travelDate,
        travelCountry: data?.travelCountry,
        startLocation: data?.startLocation,
        endLocation: data?.endLocation,
        merchant: data?.merchant?.name,
        categoryId: data?.category?.id,
      };
      dispatch(setCreateClaimFormDetails(createClaimFormData));
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsReimbursementFetching(false));
  }
);

export const fetchUserReimbursements = createAsyncThunk(
  "reimbursements/fetchuserReimbursement",
  async (params, { dispatch }) => {
    dispatch(setIsUserReimbursementsFetching(true));
    const [err, response] = await to(API.UserReimbursement.all(params));

    if (!err && response) {
      const { data } = response;
      if (params.page === 1) {
        dispatch(setUserReimbursements(data.list));
      } else {
        dispatch(addUserReimbursements(data.list));
      }
      dispatch(setUserReimbursementTotal(data.total));
      dispatch(setUserReimbursementHasMore());
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsUserReimbursementsFetching(false));
  }
);

export const fetchUserReport = createAsyncThunk(
  "reimbursements/fetchUserReport",
  async (params, { dispatch }) => {
    dispatch(setUserReportIsFetching(true));
    const [err, response] = await to(API.Reports.allUserReport(params));

    if (!err && response) {
      const { data } = response;
      if (params.page === 1) {
        dispatch(setUserReportsList(data.list));
      } else {
        dispatch(appendUserReports(data.list));
      }
      dispatch(setUserReportTotal(data.total));
      dispatch(setUserReportHasMore());
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setUserReportIsFetching(false));
  }
);

export const fetchAndSelectUserReimbursement = createAsyncThunk(
  "reimbursements/fetchAndSelectReimbursement",
  async (params, { dispatch }) => {
    dispatch(setIsUserReimbursementFetching(true));

    const { payload, sortingPayload } = params;
    try {
      const [reimbursementResponse, userReimbursementResponse] =
        await Promise.all([
          API.Reimbursements.all({ ...payload, ...sortingPayload }),
          API.UserReimbursement.get(payload),
        ]);

      if (reimbursementResponse?.data?.page === 1) {
        dispatch(
          setSelectedUserReimbursementList(reimbursementResponse.data.list)
        );
      } else {
        dispatch(
          appendSelectedUserReimbursements(reimbursementResponse.data.list)
        );
      }

      dispatch(
        setSelectedUserReimbursementCreatedBy(
          userReimbursementResponse.data.createdBy
        )
      );
      dispatch(
        setSelectedUserReimbursementPage(reimbursementResponse.data.page)
      );
      dispatch(
        setSelectedUserReimbursementAmount(
          userReimbursementResponse.data.amount
        )
      );
      dispatch(
        setSelectedUserReimbursementTotalClaims(
          reimbursementResponse.data.total
        )
      );
      dispatch(
        setSelectedUserReimbursementCtas(userReimbursementResponse.data.ctas)
      );
      dispatch(setSelectedUserReimbursementHasMore());
    } catch (error) {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsUserReimbursementFetching(false));
  }
);

export const fetchAndSelectUserReport = createAsyncThunk(
  "reimbursements/fetchAndSelectUserReport",
  async (params, { dispatch }) => {
    dispatch(setIsUserReimbursementFetching(true));

    const { payload, sortingPayload } = params;

    try {
      const [reportResponse, userReportResponse] = await Promise.all([
        API.Reports.all({ ...payload, ...sortingPayload }),
        API.Reports.getUserReportReimbursementDetails(payload),
      ]);

      if (params?.payload?.page === 1) {
        dispatch(setSelectedUserReportList(reportResponse.data.list));
      } else {
        dispatch(appendSelectedUserReports(reportResponse.data.list));
      }

      dispatch(setSelectedUserReportPage(reportResponse.data.page));
      dispatch(
        setSelectedUserReportCreatedBy(userReportResponse.data.createdBy)
      );
      dispatch(setSelectedUserReportAmount(userReportResponse.data.amount));
      dispatch(setSelectedUserReportTotalClaim(userReportResponse.data.claims));
      dispatch(
        setSelectedUserReportTotalReports(userReportResponse.data.reports)
      );
      dispatch(setSelectedUserReportCtas(userReportResponse.data.ctas));
      dispatch(setSelectedUserReportHasMore());
    } catch (error) {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsUserReimbursementFetching(false));
  }
);

export const fetchReimbursementPaymentHistory = createAsyncThunk(
  "reimbursements/fetchReimbursementPaymentHistory",
  async (params, { dispatch }) => {
    dispatch(setIsReimbursementHistoryFetching(true));
    const [err, response] = await to(API.ReimbursementSettlement.all(params));
    if (!err && response) {
      const { data } = response;
      if (params.page === 1) {
        dispatch(setReimbursementPaymentHistory(data.list));
      } else {
        dispatch(addReimbursementHistory(data.list));
      }
      dispatch(setReimbursementPaymentHistoryTotalPaid(data.totalPaid));
      dispatch(setReimbursementPaymentHistoryListTotal(data.total));

      dispatch(setReimbursementPaymentHistoryAmount(data?.totalAmount));
      dispatch(setReimbursementPaymentHistoryHasMore());
    }
    dispatch(setIsReimbursementHistoryFetching(false));
  }
);

export const fetchSelectedPaymentHistoryData = createAsyncThunk(
  "reimbursements/fetchSelectedPaymentHistoryData",
  async (params, { dispatch }) => {
    dispatch(setSelectedPaymentHistoryListingDataIsFetching(true));
    const [err, response] = await to(API.ReimbursementSettlement.get(params));
    if (response) {
      dispatch(setSelectedPaymentHistoryData(response?.data));
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setSelectedPaymentHistoryListingDataIsFetching(false));
  }
);

export const fetchAssociatedDataOfSelectedPaymentHistoryData = createAsyncThunk(
  "reimbursements/fetchAssociatedDataOfSelectedPaymentHistoryData",
  async (params, { dispatch }) => {
    dispatch(setSelectedPaymentHistoryListingDataIsFetching(true));
    const [err, response] = await to(
      API.ReimbursementSettlement.getAssociatedData(params)
    );

    if (response) {
      const { data } = response;
      if (params.page === 1) {
        dispatch(setSelectedPaymentHistoryListingDataList(data?.list));
      } else {
        dispatch(appendSelectedPaymentHistoryListingDataList(data?.list));
      }
      dispatch(setSelectedPaymentHistoryListingDataTotal(data?.total));
      dispatch(setSelectedPaymentHistoryListingDataPage(data?.page));
      dispatch(setSelectedPaymentHistoryListingDataLimit(data?.limit));
      dispatch(setSelectedPaymentHistoryListingDataHasMore());
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setSelectedPaymentHistoryListingDataIsFetching(false));
  }
);

export const fetchMileageRateList = createAsyncThunk(
  "reimbursement/fetchMileageRateList",
  async (params, { dispatch }) => {
    dispatch(setMileageRateListIsFetching(true));
    const [err, response] = await to(API.ReimbursementMileageRate.all(params));
    if (response) {
      dispatch(setMileageRateList(response.data));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setMileageRateListIsFetching(false));
  }
);
export const addMileageRate = createAsyncThunk(
  "reimbursement/addMileageRate",
  async (params, { dispatch }) => {
    const { data, onSuccess = () => {} } = params;
    const [err, response] = await to(
      API.ReimbursementMileageRate.update({ mileage_rates: data })
    );

    if (!err && response) {
      dispatch(addMileageRateList(response));
      if (onSuccess) {
        onSuccess();
      }
      dispatch(fetchMileageRateList());
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);
export const deleteMileageRate = createAsyncThunk(
  "reimbursement/deleteMileageRate",

  async (params, { dispatch }) => {
    const { id } = params;
    const [error, response] = await to(
      API.ReimbursementMileageRate.update({ mileage_rates: params.data })
    );

    if (!error && response) {
      dispatch(deleteMileageRateList(id));
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }
  }
);

export const updateMileageRate = createAsyncThunk(
  "reimbursement/updateMileageRate",
  async (params, { dispatch }) => {
    const { data, onSuccess = () => {} } = params;
    const [error, response] = await to(
      API.ReimbursementMileageRate.update({ mileage_rates: data })
    );

    if (!error && response) {
      dispatch(updateMileageRateList(data));
      dispatch(fetchMileageRateList());
      if (onSuccess) {
        onSuccess();
      }
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }
  }
);
export const saveClaimPolicyData = createAsyncThunk(
  "reimbursement/saveClaimPolicyData",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const [error, response] = await to(API.ClaimPolicies.upsert(params));
    if (!error && response) {
      dispatch(setClaimPolicyData(response.data));
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const fetchClaimCategoryLimitData = createAsyncThunk(
  "reimbursement/fetchClaimCategoryLimitData",
  async (params, { dispatch }) => {
    dispatch(setIsLoading(true));
    const [error, response] = await to(
      API.ReimbursementSetting.getClaimCategoryDetails(params)
    );
    if (!error && response) {
      dispatch(setClaimCategoryLimitDetails(response?.data));
    } else {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsLoading(false));
  }
);

export const fetchClaimPolicy = createAsyncThunk(
  "reimbursements/fetchClaimPolicy",
  async (params, { dispatch }) => {
    dispatch(setIsClaimPolicyDataFetching(true));

    const [err, response] = await to(API.ClaimPolicies.get(params));

    if (!err && response) {
      dispatch(setClaimPolicyData(response.data));
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsClaimPolicyDataFetching(true));
  }
);

// settings

export const approveReimbursement = createAsyncThunk(
  "payments/approve",
  async (params, { dispatch }) => {
    const { id, onSuccess = () => {} } = params;
    dispatch(setIsCtaActionPendingForId(id));

    const [error, response] = await to(
      API.Misc.approve({
        target_id: id,
        type: REIMBURSEMENT_ACTION_PARAMS.TYPE_REIMBURSEMENT,
      })
    );

    if (!error && response) {
      if (onSuccess) onSuccess();
      vToast(getSuccessToastMessage(response));
    } else if (error) {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsCtaActionPendingForId(null));
  }
);

// reject approval CTA
export const rejectReimbursement = createAsyncThunk(
  "payments/reject",
  async (params, { dispatch }) => {
    const { id, comment, onSuccess = () => {} } = params;

    dispatch(setIsLoading(true));

    const [error, response] = await to(
      API.Misc.reject({
        target_id: id,
        comment,
        type: REIMBURSEMENT_ACTION_PARAMS.TYPE_REIMBURSEMENT,
      })
    );

    if (!error && response) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) {
        onSuccess();
      }
    } else {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsLoading(false));
  }
);

export const skipApprovalReimbursement = createAsyncThunk(
  "reimbursment/skipApprovals",
  async (params, { dispatch }) => {
    const { onSuccess = () => {}, id } = params;
    dispatch(setIsCtaActionPendingForId(id));

    const [error, response] = await to(
      API.Reimbursements.performAction(id, {
        do: REIMBURSEMENT_ACTION_PARAMS.DO.SKIP_APPROVALS,
      })
    );

    if (!error && response) {
      dispatch(
        removeReimbursement({
          value: Number(id),
          key: REIMBURSEMENTS_SLICE_ATTRIBUTE_KEY,
        })
      );
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess();
    } else {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsCtaActionPendingForId(null));
  }
);

export const settleClaimReimbursement = createAsyncThunk(
  "reimbursment/settleClaim",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const { payload, onSuccess = () => {} } = params;
    dispatch(setIsLoading(true));
    const [error, response] = await to(
      API.ReimbursementSettlement.settleClaim(payload)
    );

    if (!error && response) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) {
        onSuccess();
      }
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsLoading(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const fetchReimbursementSetting = createAsyncThunk(
  "reimbursement/fetchReimbursementSetting",
  async (params, { dispatch }) => {
    const [err, response] = await to(API.ReimbursementSetting.all(params));
    if (!err && response) {
      dispatch(setReimbursementSetting(response.data));
    }
  }
);

export const updateReimbursementSetting = createAsyncThunk(
  "reimbursement/updateReimbursementSetting",
  async (params, { dispatch }) => {
    const {
      onSuccess = () => {},
      onError = () => {},
      payload,
      isHandleIndividualLoading,
    } = params;

    if (isHandleIndividualLoading) {
      Object.keys(payload).forEach((k) => {
        dispatch(
          setReimbursementSettingExtraKey({
            key: k,
            value: true,
          })
        );
      });
    }

    const [err, response] = await to(API.ReimbursementSetting.update(payload));
    if (response) {
      dispatch(setReimbursementSetting(response.data));
      dispatch(
        setReimbursementAutomatedPaymentToggleSwitch(
          payload?.reimbursement_autopay_enabled
        )
      );

      if (onSuccess && typeof onSuccess === typeof (() => {})) {
        onSuccess();
      }
    } else onError(err);

    if (isHandleIndividualLoading) {
      Object.keys(payload).forEach((k) => {
        dispatch(
          setReimbursementSettingExtraKey({
            key: k,
            value: false,
          })
        );
      });
    }
  }
);

export const bulkOperationReimbursement = createAsyncThunk(
  "reimbursement/bulkOperationReimbursement",
  async (params, { dispatch }) => {
    const { onSuccess = () => {}, param } = params;
    const [err, response] = await to(API.Reimbursements.bulkOperation(param));
    if (!err && response) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess();
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const bulkOperationReport = createAsyncThunk(
  "reimbursement/bulkOperationReimbursement",
  async (params, { dispatch }) => {
    const { onSuccess = () => {}, param } = params;

    const [err, response] = await to(API.Reports.bulkOperation(param));
    if (!err && response) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess();
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const fetchReimbursementProjectApprovers = createAsyncThunk(
  "company/fetchProjectApprovers",
  async (params, { dispatch }) => {
    const [err, response] = await to(API.Payments.getApprovers(params));
    if (!err && response) {
      dispatch(setProjectApprovers(response.data));
    }
  }
);

export const generateBeneficiaryAmount = createAsyncThunk(
  "reimbursements/generateBeneficiaryAmount",
  async (params, { dispatch }) => {
    const [err, response] = await to(
      API.Reimbursements.generateBeneficiaryAmount(params)
    );
    if (!err && response) {
      dispatch(setSelectedPaymentsData(response.data));
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const review = createAsyncThunk(
  "reimbursements/review",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const { id, onSuccess = () => {} } = params;
    dispatch(setIsCtaActionPendingForId(id));

    const [error, response] = await to(
      API.Reimbursements.performAction(id, {
        do: REIMBURSEMENT_ACTION_PARAMS.DO.VERIFIED,
      })
    );

    if (!error && response) {
      dispatch(
        removeReimbursement({
          value: Number(id),
          key: REIMBURSEMENTS_SLICE_ATTRIBUTE_KEY,
        })
      );
      if (onSuccess) {
        onSuccess();
      }
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsCtaActionPendingForId(null));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const deleteReimbursement = createAsyncThunk(
  "reimbursements/deleteDraft",
  async (params, { dispatch }) => {
    const { id } = params;
    dispatch(setIsCtaActionPendingForId(id));
    const [error, response] = await to(API.Reimbursements.delete(id));

    if (!error && response) {
      dispatch(
        removeReimbursement({
          value: Number(id),
          key: REIMBURSEMENTS_SLICE_ATTRIBUTE_KEY,
        })
      );

      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsCtaActionPendingForId(null));
  }
);

export const archiveReimbursement = createAsyncThunk(
  "reimbursements/archiveReimbursement",
  async (params, { dispatch }) => {
    const { id, onSuccess = () => {} } = params;
    dispatch(setIsCtaActionPendingForId(id));
    const [error, response] = await to(API.Reimbursements.archive(id));

    if (!error && response) {
      dispatch(
        removeReimbursement({
          value: Number(id),
          key: REIMBURSEMENTS_SLICE_ATTRIBUTE_KEY,
        })
      );

      if (onSuccess) {
        onSuccess();
      }

      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsCtaActionPendingForId(null));
  }
);
export const bulkUploadReimbursement = createAsyncThunk(
  "reimbursements/bulkUploadReimbursement",
  async (params, { dispatch }) => {
    const { onSuccess = () => {}, ...rest } = params;
    dispatch(setIsFetchingBulkUpload(true));
    const [error, response] = await to(API.Reimbursements.bulkUpload(rest));

    if (!error && response) {
      if (onSuccess) onSuccess(response?.data);
      dispatch(setBulkUploadResponseData(response?.data));
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFetchingBulkUpload(false));
  }
);

export const checkDuplicateReimbursement = createAsyncThunk(
  "reimbursements/checkDuplicateReimbursement",
  async (params, { dispatch }) => {
    const [error, response] = await to(
      API.Reimbursements.checkDuplicateReimbursment(params)
    );

    if (!error && response) {
      dispatch(setCreatingduplicateReimbursement(response?.data));
    }
  }
);

// MyVolopay report
export const fetchReports = createAsyncThunk(
  "reimbursements/fetchReports",

  async (params, { dispatch }) => {
    dispatch(setReportIsFetching(true));
    const [err, response] = await to(API.Reports.all(params));

    if (!err && response) {
      const { data } = response;

      if (params.page === 1) {
        dispatch(setReportsList(data.list));
      } else {
        dispatch(appendReports(data.list));
      }
      dispatch(setReportTotal(data.total));
      dispatch(setReportPage(data.page));
      dispatch(setReportLimit(data.limit));
      dispatch(setReportHasMore());
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setReportIsFetching(false));
  }
);

export const fetchAndSelectReport = createAsyncThunk(
  "reimbursements/fetchAndSelectReport",

  async (params, { dispatch }) => {
    dispatch(setIsFetchingReport(true));

    const [err, response] = await to(API.Reports.get(params));
    if (response) {
      dispatch(setSelectedReport(response.data));
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFetchingReport(false));
  }
);

export const submitReport = createAsyncThunk(
  "reimbursements/submitReport",
  async (params, { dispatch }) => {
    const { id, payload, onSuccess = () => {} } = params;
    dispatch(setIsCtaActionPendingForId(id));
    const [error, response] = await to(API.Reports.submit(id, payload));

    if (!error && response) {
      onSuccess(response?.data?.id);
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsCtaActionPendingForId(null));
  }
);

export const createReport = createAsyncThunk(
  "reimbursements/createReport",
  async (params, { dispatch }) => {
    dispatch(setIsCreateReportLoading(true));

    const { payload, tab, noToast = false, onSuccess = () => {} } = params;
    const [err, response] = await to(API.Reports.create(payload));

    if (response && !err) {
      if (tab === TAB_TYPES.DRAFTS) dispatch(prependReport([response?.data]));
      onSuccess(response?.data?.id);
      if (!noToast) {
        vToast(getSuccessToastMessage(response));
      }
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsCreateReportLoading(false));
  }
);

export const reviewReport = createAsyncThunk(
  "reimbursements/reviewReport",
  async (params, { dispatch }) => {
    const { id, onSuccess = () => {}, onFail = () => {} } = params;
    dispatch(setIsCtaActionPendingForId(id));

    const [error, response] = await to(
      API.Reports.performAction(id, {
        do: REPORT_ACTION_PARAMS.DO.VERIFIED,
      })
    );

    if (response && !error) {
      if (onSuccess) {
        onSuccess();
      }
      dispatch(removeReport(Number(id)));
      vToast(getSuccessToastMessage(response));
    } else if (error) {
      if (onFail) {
        onFail();
      }
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsCtaActionPendingForId(null));
  }
);

export const updateReport = createAsyncThunk(
  "reimbursements/updateReport",
  async (params, { dispatch }) => {
    dispatch(setIsCreateReportLoading(true));
    const { id, noToast = false, payload, onSuccess } = params;

    const [error, response] = await to(API.Reports.update(id, payload));

    if (!error && response) {
      if (!noToast) {
        vToast(getSuccessToastMessage(response));
      }

      dispatch(setReport({ id, value: response?.data }));
      if (onSuccess) {
        onSuccess(response?.data);
      }
    }

    if (error) {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsCreateReportLoading(false));
  }
);

export const updateReportClaim = createAsyncThunk(
  "reimbursements/updateReportClaim",
  async (params, { dispatch }) => {
    dispatch(setIsCreateLoading(true));
    const { id, payload, onSuccess } = params;
    const [error, response] = await to(API.Reports.updateClaim(id, payload));

    if (!error && response) {
      if (onSuccess) {
        onSuccess(response?.data);
      }
      dispatch(setReport({ id, value: response?.data }));
    }

    dispatch(setIsCreateLoading(false));
  }
);

export const updateClaim = createAsyncThunk(
  "reimbursements/updateClaim",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setIsCreateLoading(true));
    const { id, payload, noToast = false, onSuccess = () => {} } = params;
    const [error, response] = await to(API.Reimbursements.update(id, payload));

    if (!error && response) {
      const reimbursement = response?.data?.data;

      if (onSuccess) {
        onSuccess(response?.data?.data);
      }

      dispatch(setReimbursement({ id, value: reimbursement }));
      if (!noToast) {
        vToast(getSuccessToastMessage(response));
      }
    } else {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsCreateLoading(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const deleteReport = createAsyncThunk(
  "reimbursements/deleteReport",
  async (param, { dispatch }) => {
    const { id, params, onSuccess = () => {} } = param;
    dispatch(setIsCtaActionPendingForId(id));
    const [error, response] = await to(API.Reports.delete(id, params));

    if (!error && response) {
      dispatch(removeReport(Number(id)));

      if (onSuccess) onSuccess();
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsCtaActionPendingForId(null));
  }
);

export const fetchRejectedReportReimbursements = createAsyncThunk(
  "reimbursements/fetchRejectedReportReimbursements",
  async (params, { dispatch }) => {
    dispatch(setRejectedReimbursementsIsFetching(true));
    const [err, response] = await to(API.Reports.claims(params));

    if (!err && response) {
      const { data } = response;
      if (params.page === 1) {
        dispatch(setRejectedReimbursementsList(data.list));
      } else {
        dispatch(appendRejectedReimbursementList(data.list));
      }
      dispatch(setRejectedReimbursementsTotal(data.total));
      dispatch(setRejectedReimbursementsHasMore());
      dispatch(setRejectedReimbursementsPage(data.page));
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setRejectedReimbursementsIsFetching(false));
  }
);

export const fetchReportReimbursements = createAsyncThunk(
  "reimbursements/fetchReportReimbursements",

  async (params, { dispatch }) => {
    dispatch(setIsReimbursementsFetching(true));

    const [err, response] = await to(API.Reports.claims(params));

    if (!err && response) {
      const { data } = response;
      if (params.page === 1) {
        dispatch(setReimbursements(data.list));
      } else {
        dispatch(addReimbursements(data.list));
      }
      dispatch(setReimbursementTotal(data.total));
      dispatch(setReimbursementTotalAmount(data?.totalAmount));
      dispatch(setReimbursementHasMore());
      dispatch(setpage(params.page));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsReimbursementsFetching(false));
  }
);

export const fetchReimbursementClaimSubmissionPolicy = createAsyncThunk(
  "reimbursements/fetchReimbursementClaimSubmissionPolicy",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingReimbursementClaimSubmissionPolicy(true));

    const { projectId, type } = params;
    const [error, response] = await to(
      API.SubmissionPolicy.getPolicy({
        project_id: projectId,
        submission_policy_type: type,
      })
    );
    if (!error && response) {
      dispatch(setReimbursementClaimSubmissionPolicy(response.data));
    } else if (error) {
      vToast(getErrorToastMessage({ error }));
    }

    dispatch(setIsFetchingReimbursementClaimSubmissionPolicy(false));
  }
);

export const bulkUploadRefresh = createAsyncThunk(
  "reimbursements/bulkUploadRefresh",
  async (param, { dispatch }) => {
    const { payload, onSuccess = () => {} } = param;

    const [error, response] = await to(
      API.Reimbursements.bulkUploadStatus(payload)
    );

    if (!error && response) {
      dispatch(setBulkUploadRefreshData(response?.data?.data));

      if (onSuccess) onSuccess(response?.data?.data);
    } else if (error) {
      vToast(getErrorToastMessage(error));
    }
  }
);

export const {
  setReimbursements,
  updateReimbursement,
  addReimbursements,
  setIsReimbursementsFetching,
  setIsReimbursementFetching,
  setSelectedReimbursement,
  setCreateClaimFormDetails,
  setUserReimbursements,
  addUserReimbursements,
  setIsUserReimbursementsFetching,
  setIsReimbursementHistoryFetching,
  setSelectedUserReimbursementList,
  setIsUserReimbursementFetching,
  setReimbursementPageType,
  addChangesMade,
  setReimbursementInitialState,
  setUserReimbursementInitialState,
  setUserReportInitialState,
  setReimbursementHasMore,
  setpage,
  setReimbursementTotal,
  setReimbursementTotalAmount,
  setUserReimbursementHasMore,
  setSelectedUserReimbursementHasMore,
  setSelectedUserReimbursementCtas,
  setSelectedUserReimbursementPage,
  setSelectedUserReimbursementTotalClaims,
  setSelectedUserReimbursementInitialState,
  setUserReimbursementTotal,
  setIsCreateLoading,
  setMileageRateList,
  addMileageRateList,
  setCurrenciesList,
  updateMileageRateList,
  deleteMileageRateList,
  setClaimPolicyData,
  clearClaimPolicyData,
  setReimUserDataList,
  setFetchingReimUserData,
  setReimbursementPaymentHistory,
  addReimbursementHistory,
  setReimbursementPaymentHistoryTotalPaid,
  setReimbursementPaymentHistoryListTotal,
  setReimbursementPaymentHistoryHasMore,
  setReimbursementPaymentHistoryInitialState,
  setSelectedPaymentHistoryData,
  setReimbursementSetting,
  setIsFetchingReimbursementReceipt,
  deleteAttachment,
  setAttachment,
  removeReimbursement,
  setSelectedPaymentsData,
  setSelectedPaymentsInitialData,
  setClaimCategoryLimitDetails,
  setDirectionResponseData,
  // reimbursement drafts

  prependReimbursements,
  prependReport,
  setReimbursement,
  setReport,
  setIsClaimPolicyDataFetching,
  setIsLoading,
  setIsCtaActionPendingForId,
  setReimbursementAutomatedPaymentToggleSwitch,
  appendSelectedUserReimbursements,
  setSelectedUserReimbursementAmount,
  setSelectedUserReimbursementCreatedBy,
  setIsFetchingBulkUpload,
  updateSelectedReimbursement,
  setCreatingduplicateReimbursement,
  setMileageRateListIsFetching,
  // reports
  setReportsList,
  setReportIsFetching,
  setReportHasMore,
  setReportPage,
  setReportTotal,
  setReportLimit,
  appendReports,
  setSelectedClaimForReport,
  appendSelectedClaimForReport,
  setSelectedAllClaimsForReport,
  setCreateReportFormData,
  setShowEmptyList,
  setSelectedReport,
  setIsFetchingReport,
  removeReport,
  setIsCreateReportLoading,
  // rejected reimbursements
  setRejectedReimbursementsList,
  setRejectedReimbursementsPage,
  setRejectedReimbursementsTotal,
  setRejectedReimbursementsLimit,
  setRejectedReimbursementsHasMore,
  setRejectedReimbursementsIsFetching,
  appendRejectedReimbursementList,
  setRejectReportReason,
  setReportInitialState,
  setRejectedClaimsInitialState,
  setRejectedClaimPayload,
  removeRejectedClaimPayload,
  addRejectedClaimPayload,
  // user reports
  setUserReportsList,
  setUserReportPage,
  setUserReportHasMore,
  setUserReportIsFetching,
  setUserReportLimit,
  setUserReportTotal,
  appendUserReports,
  // selected user report
  setSelectedUserReportList,
  setSelectedUserReportHasMore,
  setSelectedUserReportTotalClaim,
  setSelectedUserReportCreatedBy,
  setSelectedUserReportAmount,
  setSelectedUserReportInitialState,
  setSelectedUserReportListInitialState,
  appendSelectedUserReports,
  setSelectedUserReportTotalReports,
  setSelectedUserReportPage,
  setSelectedUserReportCtas,
  setDeletedClaimIdsForReport,
  setDeletedClaimIdsForReportInititalState,
  updateSingleReport,
  setReimbursementPaymentHistoryAmount,

  setSelectedPaymentHistoryListingDataList,
  setSelectedPaymentHistoryListingDataPage,
  setSelectedPaymentHistoryListingDataTotal,
  setSelectedPaymentHistoryListingDataLimit,
  setSelectedPaymentHistoryListingDataIsFetching,
  setSelectedPaymentHistoryListingDataHasMore,
  setSelectedPaymentHistoryListingDataInitialState,
  appendSelectedPaymentHistoryListingDataList,

  setReimbursementClaimSubmissionPolicy,
  setIsFetchingReimbursementClaimSubmissionPolicy,
  setEditAmountState,
  resetEditAmountState,
  setBulkUploadResponseData,
  setBulkUploadRefreshData,
  setBulkUploadRecordIds,
  setBulkUploadCount,
  setReimbursementSettingExtraKey,
} = reimbursementSlice.actions;
export default reimbursementSlice.reducer;
