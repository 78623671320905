import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { getErrorToastMessage, getSuccessToastMessage } from "@/utils/common";
import vToast from "@/utils/vToast";
import API from "@/api";

import { setIsFormSubmissionProgress } from "./loadersError";

const initialState = {
  accountingVendors: [],
  isFetchingAccountingVendors: false,
  isFetchedAccountingVendors: false,
  accountingIntegrations: {
    accountingAuthUrl: "",
    accountingSettingsList: null,
    syncFrequency: null,
    bankAccountId: null,
    reimbursementBankAccountId: null,
    entityOptions: [],
    autoReconcileEnabled: false,
    journalAutoApproved: true,
    autoSyncExpensesEnabled: true,
    transferFeeSyncEnabled: false,
    defaultAccountingValues: null,
    accountingExportFormatType: null,
    supportedExportFormatOptions: [],
    bankAccounts: [],
    subsidiaryOptions: [],
    syncMode: "",
    continuousBillSync: false,
    overrideValues: false,
    subsidiary: {},
    importFileFields: [
      {
        name: "chart of accounts",
        icon: "AssignmentAddOn",
        value: "chart_of_account",
      },
      {
        name: "tax codes",
        icon: "Percentage",
        value: "tax",
      },
      {
        name: "vendors",
        icon: "Groups",
        value: "vendor",
      },
    ],
    importedFiles: null,
    importResponse: [],
    isFetching: false,
    tallyAccessTokenPayload: {},
    pendingTabCount: {},
    isFetchingPendingCount: false,
    isLoading: false,
  },
  files: {
    data: null,
    error: null,
  },
};

const accountingSlice = createSlice({
  name: "accounting",
  initialState,
  reducers: {
    setAccountingVendors(state, action) {
      state.accountingVendors = action.payload;
    },
    appendToAccountingVendors(state, action) {
      state.accountingVendors = [...state.accountingVendors, action.payload];
    },
    updateAccountingVendor(state, action) {
      const { id, value } = action.payload;

      const idx = state.accountingVendors.findIndex(
        (accVendor) => accVendor.id.toString() === id?.toString()
      );
      if (idx === -1) return;
      state.accountingVendors[idx] = value;
    },
    setIsFetchingAccountingVendors(state, action) {
      state.isFetchingAccountingVendors = action.payload;
    },
    setIsFetchedAccountingVendors(state, action) {
      state.isFetchedAccountingVendors = action.payload;
    },
    setAccountingIntegrationEntityOptions(state, action) {
      state.accountingIntegrations.entityOptions = action.payload;
    },
    setAccountingAuthUrl(state, action) {
      state.accountingIntegrations.accountingAuthUrl = action.payload;
    },
    setAccountingIntegrationSettings(state, action) {
      state.accountingIntegrations.accountingSettingsList = action.payload;
    },
    setBankAccounts(state, action) {
      state.accountingIntegrations.bankAccounts = action.payload;
    },
    setAccountingIntegrationImportFileFields(state, action) {
      state.accountingIntegrations.importFileFields = action.payload;
    },
    addAccountingIntegrationImportFileFields(state, action) {
      state.accountingIntegrations.importFileFields = [
        ...state.accountingIntegrations.importFileFields,
        action.payload,
      ];
    },
    setAccountingIntegrationImportedFiles(state, action) {
      state.accountingIntegrations.importedFiles = action.payload;
    },
    addAccountingIntegrationImportedFiles(state, action) {
      state.accountingIntegrations.importedFiles = {
        ...state.accountingIntegrations.importedFiles,
        ...action.payload,
      };
    },
    setAccountingExportFormatType(state, action) {
      state.accountingIntegrations.accountingExportFormatType = action.payload;
    },
    setImportResponse(state, action) {
      state.accountingIntegrations.importResponse = action.payload;
    },
    setJournalAutoApproved(state, action) {
      state.accountingIntegrations.journalAutoApproved = action.payload;
    },
    setSyncMode(state, action) {
      state.accountingIntegrations.syncMode = action.payload;
    },
    setContinuousBillSync(state, action) {
      state.accountingIntegrations.continuousBillSync = action.payload;
    },
    setSubsidiaryOptions(state, action) {
      state.accountingIntegrations.subsidiaryOptions = action.payload;
    },
    setSupportedExportFormatOptions(state, action) {
      state.accountingIntegrations.supportedExportFormatOptions =
        action.payload;
    },
    setAccountingIntegrationSyncFields(state, action) {
      state.accountingIntegrations.syncFields = action.payload;
    },
    addAccountingIntegrationSyncFields(state, action) {
      state.accountingIntegrations.syncFields = {
        ...state.accountingIntegrations.syncFields,
        ...action.payload,
      };
    },
    setTallyAccessTokenPayload(state, action) {
      state.accountingIntegrations.tallyAccessTokenPayload = action.payload;
    },
    setIsLoadingConnect(state, action) {
      state.accountingIntegrations.isLoading = action.payload;
    },
    setPendingTabCount(state, action) {
      state.pendingTabCount = action.payload;
    },
    setIsFetchingPendingCount(state, action) {
      state.isFetchingPendingCount = action.payload;
    },
    setFilesData(state, action) {
      state.files.data = action.payload;
    },
    setFilesError(state, action) {
      state.files.error = action.payload;
    },
  },
});

export const {
  setAccountingVendors,
  updateAccountingVendor,
  appendToAccountingVendors,
  setIsFetchingAccountingVendors,
  setIsFetchedAccountingVendors,
  setAccountingIntegrationEntityOptions,
  setAccountingAuthUrl,
  setAccountingIntegrationSettings,
  setBankAccounts,
  setSyncedFields,
  setBankAccountId,
  setReimbursementBankAccountId,
  setJournalAutoApproved,
  setContinuousBillSync,
  setSubsidiaryOptions,
  setSyncMode,
  setAccountingIntegrationImportedFiles,
  addAccountingIntegrationImportedFiles,
  setAccountingExportFormatType,
  setImportResponse,
  setAccountingIntegrationImportFileFields,
  addAccountingIntegrationImportFileFields,
  setSupportedExportFormatOptions,
  setAccountingIntegrationSyncField,
  addAccountingIntegrationSyncFields,
  setTallyAccessTokenPayload,
  setIsLoadingConnect,
  setPendingTabCount,
  setIsFetchingPendingCount,
  setFilesError,
  setFilesData,
} = accountingSlice.actions;

export const showErrorMessageToast = ({ error, dispatch, rejectWithValue }) => {
  vToast(getErrorToastMessage(error));
  dispatch(setIsLoadingConnect(false));
  return rejectWithValue(getErrorToastMessage(error)?.description);
};

export const fetchAccountingVendors = createAsyncThunk(
  "accounting/fetchAccountingVendors",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingAccountingVendors(true));
    const [error, response] = await to(
      API.Accounting.accountingVendors(params)
    );
    if (response?.data) {
      dispatch(setAccountingVendors(response.data)); // .data needed for await-to-js, API response is an array
      dispatch(setIsFetchedAccountingVendors(true));
    }
    dispatch(setIsFetchingAccountingVendors(false));
  }
);

export const connectAccountingSoftware = createAsyncThunk(
  "accounting/connectAccountingSoftware",
  async (params, { dispatch, rejectWithValue }) => {
    dispatch(setIsLoadingConnect(true));
    const [error, response] = await to(API.Accounting.connect(params));

    if (error)
      return showErrorMessageToast({ error, dispatch, rejectWithValue });

    if (response?.data)
      dispatch(setAccountingAuthUrl(response.data.accountingAuthUrl));

    dispatch(setIsLoadingConnect(false));
  }
);

export const disconnectAccountingSoftware = createAsyncThunk(
  "accounting/disconnectAccountingSoftware",
  async (params, { dispatch, rejectWithValue }) => {
    dispatch(setIsLoadingConnect(true));
    dispatch(setIsFormSubmissionProgress(true));

    const [error, response] = await to(API.Accounting.disconnect(params));

    if (error) {
      dispatch(setIsFormSubmissionProgress(false));
      return showErrorMessageToast({ error, dispatch, rejectWithValue });
    }

    if (response?.data) {
      dispatch(setIsLoadingConnect(false));
      vToast(getSuccessToastMessage(response, response?.data));
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const connectToNetsuite = createAsyncThunk(
  "accounting/addNetsuiteConnection",
  async (params, { dispatch, rejectWithValue }) => {
    dispatch(setIsLoadingConnect(true));
    dispatch(setIsFormSubmissionProgress(true));
    const [error, response] = await to(
      API.Accounting.addNetsuiteConnection(params)
    );

    if (error)
      return showErrorMessageToast({ error, dispatch, rejectWithValue });

    if (response?.data) {
      const { data } = response;
      dispatch(setJournalAutoApproved(data?.journalAutoApproved));
      dispatch(setSubsidiaryOptions(data?.tenants));
      dispatch(setSyncMode(data?.syncMode));
      dispatch(setContinuousBillSync(data?.continuousBillSync));
    }
    dispatch(setIsLoadingConnect(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const getNetsuiteConfigurations = createAsyncThunk(
  "accounting/getNetsuiteConfigurations",
  async (_, { dispatch }) => {
    const [err, response] = await to(
      API.Accounting.getNetsuiteConfigurations()
    );

    if (response?.data) {
      const { data } = response;
      dispatch(setJournalAutoApproved(data?.journalAutoApproved));
      dispatch(setSubsidiaryOptions(data?.tenants));
      dispatch(setSyncMode(data?.syncMode));
      dispatch(setContinuousBillSync(data?.continuousBillSync));
      return data;
    }
  }
);

export const setSettings = createAsyncThunk(
  "accounting/setAccountingIntegrationSettings",
  async (params, { dispatch, rejectWithValue }) => {
    dispatch(setIsLoadingConnect(true));
    dispatch(setIsFormSubmissionProgress(true));
    const [error, response] = await to(API.Accounting.setSettings(params));

    if (error) {
      dispatch(setIsFormSubmissionProgress(false));
      return showErrorMessageToast({ error, dispatch, rejectWithValue });
    }

    if (response?.data) {
      dispatch(fetchAccountingIntegrationDetails());
      dispatch(setIsLoadingConnect(false));
      vToast(getSuccessToastMessage(response, response?.data));
    }

    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const fetchAccountingIntegrationDetails = createAsyncThunk(
  "accounting/fetchAccountingIntegrationDetails",
  async (_, { dispatch }) => {
    const [err, response] = await to(
      API.Accounting.getAccountingIntegrationSettings()
    );

    if (response?.data) {
      const { data } = response;
      dispatch(setAccountingIntegrationSettings(data));
      dispatch(setSyncMode(data?.syncMode));
      dispatch(setJournalAutoApproved(data?.journalAutoApproved));
      dispatch(setContinuousBillSync(data?.continuousBillSync));
      return data;
    }
  }
);

export const fetchBankAccounts = createAsyncThunk(
  "accounting/fetchBankAccounts",
  async (_, { dispatch }) => {
    const [err, response] = await to(API.Accounting.getBankAccounts());
    if (response.data) {
      dispatch(setBankAccounts(response.data));
    }
  }
);

export const fetchReceipts = createAsyncThunk(
  "accounting/fetchReceipts",
  async (params, { dispatch }) => {
    const [err, response] = await to(API.Accounting.downloadSpendFile(params));
    if (response?.data) {
      const data = window.URL.createObjectURL(response?.data);
      dispatch(setFilesData(data));
    } else if (err) dispatch(setFilesError(err));
  }
);

export const fetchSupportedExportFormats = createAsyncThunk(
  "accounting/fetchSupportedExportFormats",
  async (params, { dispatch }) => {
    const [err, response] = await to(
      API.Accounting.getUCSVSupportedFormatOptions()
    );

    if (response?.data)
      dispatch(setSupportedExportFormatOptions(response?.data));
  }
);

export const deleteAccountingField = createAsyncThunk(
  "accounting/deleteAccountingField",
  async (params, { rejectWithValue, dispatch }) => {
    const [error, response] = await to(API.Accounting.deleteTag(params));

    if (error)
      return showErrorMessageToast({ error, dispatch, rejectWithValue });
  }
);

export const editAccountingPayee = createAsyncThunk(
  "accounting/editAccountingField",
  async (params, { dispatch }) => {
    const [err, response] = await to(
      API.Accounting.editAccountingPayee(params)
    );

    if (response?.data) {
      const { data } = response;
      const { alias, currency, id, name, visible } = data;

      dispatch(
        updateAccountingVendor({
          id: data.id,
          value: { alias, currency, id, name, visible },
        })
      );
    }
  }
);

export const setExportFormat = createAsyncThunk(
  "accounting/setExportFormat",
  async (params, { rejectWithValue, dispatch }) => {
    dispatch(setIsLoadingConnect(true));
    const [error, response] = await to(API.Accounting.setExportFormat(params));
    dispatch(setIsLoadingConnect(false));

    if (error)
      return showErrorMessageToast({ error, dispatch, rejectWithValue });
  }
);

export const syncChartOfAccounts = createAsyncThunk(
  "accounting/syncChartOfAccounts",
  async (_, { dispatch, rejectWithValue }) => {
    const [error, response] = await to(
      API.Accounting.syncAccountingChartOfAccounts()
    );

    if (error) return rejectWithValue();
  }
);

export const syncVendors = createAsyncThunk(
  "accounting/syncVendors",
  async (_, { dispatch, rejectWithValue }) => {
    const [error, response] = await to(API.Accounting.syncAccountingVendors());

    if (response?.data) setAccountingVendors(response.data);
    if (error) return rejectWithValue();
  }
);

export const syncTaxCodes = createAsyncThunk(
  "accounting/syncTaxCodes",
  async (_, { dispatch, rejectWithValue }) => {
    const [error, response] = await to(API.Accounting.syncAccountingTaxes());

    if (error) return rejectWithValue();
  }
);

export const syncBankAccounts = createAsyncThunk(
  "accounting/syncBankAccounts",
  async (_, { dispatch, rejectWithValue }) => {
    const [error, response] = await to(
      API.Accounting.syncAccountingBankAccounts()
    );
    if (response?.data) {
      setBankAccounts(response.data);
    }

    if (error) return rejectWithValue();
  }
);

export const syncTrackingCategories = createAsyncThunk(
  "accounting/syncTrackingCategories",
  async (_, { dispatch, rejectWithValue }) => {
    const [error, response] = await to(
      API.Accounting.syncAccountingTrackingCategories()
    );

    if (error) return rejectWithValue();
  }
);

export const getTallyAccessToken = createAsyncThunk(
  "accounting/getTallyAccessToken",
  async (_, { dispatch }) => {
    const [error, response] = await to(API.Accounting.getTallyAccessToken());

    if (response?.data) dispatch(setTallyAccessTokenPayload(response.data));
  }
);

export const importUCSVFiles = createAsyncThunk(
  "accounting/importUCSVFiles",
  async (params, { dispatch, rejectWithValue }) => {
    const formData = new FormData();
    const { csv_file, template, override_values, field_name } = params;

    formData.append("csv_file", csv_file[0]);
    formData.append("template", template);
    formData.append("override_values", override_values);
    if (field_name) {
      formData.append("field_name", field_name);
    }

    dispatch(setIsLoadingConnect(true));
    const [error, response] = await to(
      API.Accounting.importUCSVFiles(formData)
    );

    if (error)
      return showErrorMessageToast({ error, dispatch, rejectWithValue });

    if (response?.data) {
      dispatch(setIsLoadingConnect(false));
      dispatch(setImportResponse(response?.data));
    }
  }
);

export const connectToUCSV = createAsyncThunk(
  "accounting/connectToUCSV",
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setIsLoadingConnect(true));
    const [error, response] = await to(API.Accounting.addUCSVConnection());

    if (error)
      return showErrorMessageToast({ error, dispatch, rejectWithValue });

    dispatch(setIsLoadingConnect(false));

    // vToast(getSuccessToastMessage(response, response?.data));
  }
);

export const setNetsuiteConfig = createAsyncThunk(
  "accounting/setNetsuiteConfig",
  async (params, { dispatch, rejectWithValue }) => {
    dispatch(setIsLoadingConnect(true));

    const [error, response] = await to(
      API.Accounting.setNetsuiteConfiguration(params)
    );

    if (error)
      return showErrorMessageToast({ error, dispatch, rejectWithValue });

    if (response?.data) {
      dispatch(setIsLoadingConnect(false));
    }
  }
);

export const fetchAccountingIntegrationSyncOptions = createAsyncThunk(
  "accounting/fetchAccountingIntegrationSyncOptions",
  async (_, { dispatch }) => {
    const [err, response] = await to(API.Accounting.getSyncOptions());
  }
);

export const fetchEntityOptions = createAsyncThunk(
  "accounting/fetchAccountingIntegrationEntity",
  async (params, { dispatch }) => {
    const [err, response] = await to(API.Accounting.getCompanyFiles(params));
    if (response?.data) {
      dispatch(setAccountingIntegrationEntityOptions(response.data));
    }
  }
);

export const setAccountingIntegrationEntity = createAsyncThunk(
  "accounting/setAccountingIntegrationEntity",
  async (params, { dispatch, rejectWithValue }) => {
    dispatch(setIsLoadingConnect(true));
    const [error, response] = await to(API.Accounting.setCompany(params));

    if (error)
      return showErrorMessageToast({ error, dispatch, rejectWithValue });

    if (response?.data) {
      vToast(getSuccessToastMessage(response, response?.data));
      dispatch(setIsLoadingConnect(false));
      return response.data;
    }
  }
);

export const createAccountingVendor = createAsyncThunk(
  "accounting/createAccountingVendor",
  async (params, { dispatch }) => {
    const [error, response] = await to(
      API.Accounting.createAccountingVendor(params)
    );

    if (!error && response) {
      dispatch(appendToAccountingVendors(response.data));
    }
  }
);
export const fetchPendingTabCount = createAsyncThunk(
  "accounting/fetchPendingTabCount",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingPendingCount(true));
    const [err, response] = await to(API.Accounting.getPendingTabCount(params));
    if (response?.data) {
      dispatch(setPendingTabCount(response.data));
    }
    dispatch(setIsFetchingPendingCount(false));
  }
);
export default accountingSlice.reducer;
