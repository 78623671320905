import { lazily } from "react-lazily";

const { ReactComponent: CompanyNotFound } = lazily(
  () => import("../images/CompanyNotFound.svg")
);
const { ReactComponent: Error404 } = lazily(() => import("./404Error.svg"));
const { ReactComponent: AcUnit } = lazily(() => import("./AcUnit.svg"));
const { ReactComponent: AcUnits } = lazily(() => import("./AcUnits.svg"));
const { ReactComponent: AccessDeniedError } = lazily(
  () => import("./AccessDeniedError.svg")
);
const { ReactComponent: AccountBalance } = lazily(
  () => import("./AccountBalance.svg")
);
const { ReactComponent: AccountBalanceWallet } = lazily(
  () => import("./AccountBalanceWallet.svg")
);
const { ReactComponent: AccountBox } = lazily(() => import("./AccountBox.svg"));
const { ReactComponent: AccountDetails } = lazily(
  () => import("./AccountDetails.svg")
);
const { ReactComponent: AccountSetUp } = lazily(
  () => import("./AccountSetUp.svg")
);
const { ReactComponent: Accounting } = lazily(() => import("./Accounting.svg"));
const { ReactComponent: AccountingTree } = lazily(
  () => import("./AccountingTree.svg")
);
const { ReactComponent: Add } = lazily(() => import("./Add.svg"));
const { ReactComponent: AddBusiness } = lazily(
  () => import("./AddBusiness.svg")
);
// Dashboard Quick access icons
const { ReactComponent: AddCard } = lazily(() => import("./AddCard.svg"));
const { ReactComponent: AddLocationAlt } = lazily(
  () => import("./AddLocationAlt.svg")
);
const { ReactComponent: AddMileageRate } = lazily(
  () => import("./AddMileageRate.svg")
);
const { ReactComponent: AddNotes } = lazily(() => import("./AddNotes.svg"));
const { ReactComponent: AddOnField } = lazily(() => import("./AddOnField.svg"));
// Dashboard My accounts section icons
const { ReactComponent: AddReceiveAmount } = lazily(
  () => import("./AddReceiveAmount.svg")
);
const { ReactComponent: Adjustment } = lazily(() => import("./Adjustment.svg"));
const { ReactComponent: Admin } = lazily(() => import("./Admin.svg"));
const { ReactComponent: AdsClick } = lazily(() => import("./AdsClick.svg"));
const { ReactComponent: AirlineStops } = lazily(
  () => import("./AirlineStops.svg")
);
const { ReactComponent: AirplaneTicket } = lazily(
  () => import("./AirplaneTicket.svg")
);
const { ReactComponent: AlarmOn } = lazily(() => import("./AlarmOn.svg"));
const { ReactComponent: Alert } = lazily(() => import("./Alert.svg"));
const { ReactComponent: Analytics } = lazily(() => import("./Analytics.svg"));
const { ReactComponent: ArmingCountdown } = lazily(
  () => import("./ArmingCountdown.svg")
);
const { ReactComponent: ArrowDownward } = lazily(
  () => import("./ArrowDownward.svg")
);
const { ReactComponent: ArrowDropDown } = lazily(
  () => import("./ArrowDropDown.svg")
);
const { ReactComponent: ArrowDropUp } = lazily(
  () => import("./ArrowDropUp.svg")
);
const { ReactComponent: ArrowForward } = lazily(
  () => import("./ArrowForward.svg")
);
const { ReactComponent: ArrowLeft } = lazily(() => import("./ArrowLeft.svg"));
const { ReactComponent: ArrowUpward } = lazily(
  () => import("./ArrowUpward.svg")
);
const { ReactComponent: Article2 } = lazily(() => import("./Article2.svg"));
const { ReactComponent: Article } = lazily(() => import("./Article.svg"));
const { ReactComponent: Assignment } = lazily(() => import("./Assignment.svg"));
const { ReactComponent: AssignmentAddOn } = lazily(
  () => import("./AssignmentAddOn.svg")
);
const { ReactComponent: Attach } = lazily(() => import("./Attach.svg"));
const { ReactComponent: Authenticator } = lazily(
  () => import("./Authenticator.svg")
);
const { ReactComponent: AverageTimer } = lazily(
  () => import("./AverageTimer.svg")
);
const { ReactComponent: BackArrow } = lazily(() => import("./BackArrow.svg"));
const { ReactComponent: Backup } = lazily(() => import("./Backup.svg"));
const { ReactComponent: Badge } = lazily(() => import("./Badge.svg"));
// Alert Icons
const { ReactComponent: BellPlus } = lazily(() => import("./BellPlus.svg"));
const { ReactComponent: BellWarning } = lazily(
  () => import("./BellWarning.svg")
);
const { ReactComponent: BillPay } = lazily(() => import("./BillPay.svg"));
const { ReactComponent: BillPayCancel } = lazily(
  () => import("./BillPayCancel.svg")
);
const { ReactComponent: BillPayRequests } = lazily(
  () => import("./BillPayRequests.svg")
);
const { ReactComponent: BinClock } = lazily(() => import("./BinClock.svg"));
const { ReactComponent: Block } = lazily(() => import("./Block.svg"));
const { ReactComponent: Bolt } = lazily(() => import("./Bolt.svg"));
const { ReactComponent: Book } = lazily(() => import("./Book.svg"));
const { ReactComponent: BookOpen } = lazily(() => import("./BookOpen.svg"));
const { ReactComponent: Bulb } = lazily(() => import("./Bulb.svg"));
const { ReactComponent: CSV } = lazily(() => import("./CSV.svg"));
const { ReactComponent: CSVFile } = lazily(() => import("./CSVFile.svg"));
const { ReactComponent: Cached } = lazily(() => import("./Cached.svg"));
const { ReactComponent: Calculate } = lazily(() => import("./Calculate.svg"));
const { ReactComponent: Calendar } = lazily(() => import("./Calendar.svg"));
const { ReactComponent: CalendarAddOn } = lazily(
  () => import("./CalendarAddOn.svg")
);
const { ReactComponent: CalendarClock } = lazily(
  () => import("./CalendarClock.svg")
);
const { ReactComponent: Call } = lazily(() => import("./Call.svg"));
const { ReactComponent: CallReceived } = lazily(
  () => import("./CallReceived.svg")
);
const { ReactComponent: CallReceivedReverse } = lazily(
  () => import("./CallReceivedReverse.svg")
);
const { ReactComponent: CallSplit } = lazily(() => import("./CallSplit.svg"));
const { ReactComponent: Cancel } = lazily(() => import("./Cancel.svg"));
const { ReactComponent: CaptivePortal } = lazily(
  () => import("./CaptivePortal.svg")
);
const { ReactComponent: Card } = lazily(() => import("./Card.svg"));
const { ReactComponent: CardChecked } = lazily(
  () => import("./CardChecked.svg")
);
const { ReactComponent: CardState } = lazily(() => import("./CardState.svg"));
const { ReactComponent: CardStraps } = lazily(() => import("./CardStraps.svg"));
const { ReactComponent: Cashback } = lazily(() => import("./Cashback.svg"));
const { ReactComponent: CategoriesIcon } = lazily(
  () => import("./CategoriesIcon.svg")
);
const { ReactComponent: Celebration } = lazily(
  () => import("./Celebration.svg")
);
const { ReactComponent: ChangePin } = lazily(() => import("./ChangePin.svg"));
const { ReactComponent: Chat } = lazily(() => import("./Chat.svg"));
const { ReactComponent: CheckCircle } = lazily(
  () => import("./CheckCircle.svg")
);
const { ReactComponent: CheckedHeart } = lazily(
  () => import("./CheckedHeart.svg")
);
const { ReactComponent: Checklist } = lazily(() => import("./Checklist.svg"));
const { ReactComponent: ChevronLeft } = lazily(
  () => import("./ChevronLeft.svg")
);
const { ReactComponent: Right } = lazily(() => import("./ChevronRight.svg"));
const { ReactComponent: ChevronUp } = lazily(() => import("./ChevronUp.svg"));
const { ReactComponent: ChevronDown } = lazily(
  () => import("./Chevron_Down.svg")
);
const { ReactComponent: CircleCheck } = lazily(
  () => import("./CircleCheck.svg")
);
const { ReactComponent: ClearInput } = lazily(() => import("./ClearInput.svg"));
const { ReactComponent: Close } = lazily(() => import("./Close.svg"));
const { ReactComponent: Cloud } = lazily(() => import("./Cloud.svg"));
const { ReactComponent: CloudSync } = lazily(() => import("./CloudSync.svg"));
const { ReactComponent: Collapse } = lazily(() => import("./Collapse.svg"));
const { ReactComponent: Columns } = lazily(() => import("./Columns.svg"));
const { ReactComponent: Company } = lazily(() => import("./Company.svg"));
const { ReactComponent: Contacts } = lazily(() => import("./Contacts.svg"));
const { ReactComponent: ContentPaste } = lazily(
  () => import("./ContentPaste.svg")
);
const { ReactComponent: ContentPasteSearch } = lazily(
  () => import("./ContentPasteSearch.svg")
);
const { ReactComponent: Copy } = lazily(() => import("./Copy.svg"));
const { ReactComponent: CreditCard } = lazily(() => import("./CreditCard.svg"));
const { ReactComponent: CreditCardGear } = lazily(
  () => import("./CreditCardGear.svg")
);
const { ReactComponent: CreditPaid } = lazily(() => import("./CreditPaid.svg"));
const { ReactComponent: CreditPending } = lazily(
  () => import("./CreditPending.svg")
);
const { ReactComponent: Dashboard2 } = lazily(() => import("./Dashboard2.svg"));
const { ReactComponent: Dashboard } = lazily(() => import("./Dashboard.svg"));
const { ReactComponent: DashboardCustomise } = lazily(
  () => import("./DashboardCustomise.svg")
);
const { ReactComponent: DashboardOther } = lazily(
  () => import("./DashboardOther.svg")
);
const { ReactComponent: DateRangeArrow } = lazily(
  () => import("./DateRangeArrow.svg")
);
const { ReactComponent: Delete } = lazily(() => import("./Delete.svg"));
const { ReactComponent: Department } = lazily(() => import("./Department.svg"));
const { ReactComponent: Discussion } = lazily(() => import("./Discussion.svg"));
const { ReactComponent: DoNotDisturb } = lazily(
  () => import("./DoNotDisturb.svg")
);
const { ReactComponent: Dollar } = lazily(() => import("./Dollar.svg"));
const { ReactComponent: Done } = lazily(() => import("./Done.svg"));
const { ReactComponent: DonutLarge } = lazily(() => import("./DonutLarge.svg"));
const { ReactComponent: Dot } = lazily(() => import("./Dot.svg"));
const { ReactComponent: DottedDesign } = lazily(
  () => import("./DottedDesign.svg")
);
const { ReactComponent: Down } = lazily(() => import("./Down.svg"));
const { ReactComponent: DownArrow } = lazily(() => import("./DownArrow.svg"));
const { ReactComponent: DownRight } = lazily(() => import("./DownRight.svg"));
const { ReactComponent: Download } = lazily(() => import("./Download.svg"));
const { ReactComponent: Downtime } = lazily(() => import("./Downtime.svg"));
const { ReactComponent: EArrow } = lazily(() => import("./EArrow.svg"));
const { ReactComponent: Edit } = lazily(() => import("./Edit.svg"));
const { ReactComponent: EditCalendar } = lazily(
  () => import("./EditCalendar.svg")
);
const { ReactComponent: EditCard } = lazily(() => import("./EditCard.svg"));
const { ReactComponent: EditInternationalCard } = lazily(
  () => import("./EditInternationalCard.svg")
);
const { ReactComponent: EditNotification } = lazily(
  () => import("./EditNotification.svg")
);
const { ReactComponent: EditUnderline } = lazily(
  () => import("./EditUnderline.svg")
);
const { ReactComponent: Ellipse } = lazily(() => import("./Ellipse.svg"));
const { ReactComponent: Email } = lazily(() => import("./Email.svg"));
const { ReactComponent: Emoji } = lazily(() => import("./Emoji.svg"));
const { ReactComponent: EmojiFlagSolid } = lazily(
  () => import("./EmojiFlagSolid.svg")
);
const { ReactComponent: Encrypted } = lazily(() => import("./Encrypted.svg"));
const { ReactComponent: Entity } = lazily(() => import("./Entity.svg"));
const { ReactComponent: Error } = lazily(() => import("./Error.svg"));
const { ReactComponent: EventRepeat } = lazily(
  () => import("./EventRepeat.svg")
);
// Dashboard My accounts section icons
const { ReactComponent: Exchange } = lazily(() => import("./Exchange.svg"));
const { ReactComponent: Expand } = lazily(() => import("./Expand.svg"));
const { ReactComponent: ExpandMore } = lazily(() => import("./ExpandMore.svg"));
const { ReactComponent: Expense } = lazily(() => import("./Expense.svg"));
const { ReactComponent: ExpenseChart } = lazily(
  () => import("./ExpenseChart.svg")
);
const { ReactComponent: Eye } = lazily(() => import("./Eye.svg"));
const { ReactComponent: EyeTracking } = lazily(
  () => import("./EyeTracking.svg")
);
const { ReactComponent: FactCheck } = lazily(() => import("./FactCheck.svg"));
const { ReactComponent: FeatureSearch } = lazily(
  () => import("./FeatureSearch.svg")
);
const { ReactComponent: FileCopy } = lazily(() => import("./FileCopy.svg"));
const { ReactComponent: FilledSchedule } = lazily(
  () => import("./FilledSchedule.svg")
);
const { ReactComponent: FilterList } = lazily(() => import("./FilterList.svg"));
const { ReactComponent: Flag } = lazily(() => import("./Flag.svg"));
const { ReactComponent: FlexibleLimit } = lazily(
  () => import("./FlexibleLimit.svg")
);
const { ReactComponent: FormatLetterSpacing } = lazily(
  () => import("./FormatLetterSpacing.svg")
);
const { ReactComponent: Forum } = lazily(() => import("./Forum.svg"));
const { ReactComponent: Frequency } = lazily(() => import("./Frequency.svg"));
const { ReactComponent: Google } = lazily(() => import("./Google.svg"));
const { ReactComponent: Group } = lazily(() => import("./Group.svg"));
const { ReactComponent: Groups } = lazily(() => import("./Groups.svg"));
const { ReactComponent: GroupsAdd } = lazily(() => import("./GroupsAdd.svg"));
const { ReactComponent: Headset } = lazily(() => import("./Headset.svg"));
const { ReactComponent: Heart } = lazily(() => import("./Heart.svg"));
const { ReactComponent: Help } = lazily(() => import("./Help.svg"));
const { ReactComponent: Hourglass } = lazily(() => import("./Hourglass.svg"));
const { ReactComponent: HowToReg } = lazily(() => import("./HowToReg.svg"));
const { ReactComponent: InCompleteCircle } = lazily(
  () => import("./InCompleteCircle.svg")
);
const { ReactComponent: Inbox } = lazily(() => import("./Inbox.svg"));
const { ReactComponent: Info } = lazily(() => import("./Info.svg"));
// Dashboard Quick access icons
const { ReactComponent: Integrations } = lazily(
  () => import("./Integrations.svg")
);
const { ReactComponent: InternationalCardTopUpRequest } = lazily(
  () => import("./InternationalCardTopUpRequest.svg")
);
const { ReactComponent: InternationalCards } = lazily(
  () => import("./InternationalCards.svg")
);
const { ReactComponent: InvoiceFromMail } = lazily(
  () => import("./InvoiceFromMail.svg")
);
const { ReactComponent: InvoicePayment } = lazily(
  () => import("./InvoicePayment.svg")
);
const { ReactComponent: InvoiceUploaded } = lazily(
  () => import("./InvoiceUploaded.svg")
);
const { ReactComponent: LearnMore } = lazily(() => import("./LearnMore.svg"));
const { ReactComponent: LeftArrow } = lazily(() => import("./LeftArrow.svg"));
const { ReactComponent: LightBulb } = lazily(() => import("./LightBulb.svg"));
const { ReactComponent: LightiningBolt } = lazily(
  () => import("./LightiningBolt.svg")
);
const { ReactComponent: LightningBolt } = lazily(
  () => import("./LightningBolt.svg")
);
const { ReactComponent: LightningBoltCustom } = lazily(
  () => import("./LightningBoltCustom.svg")
);
const { ReactComponent: Link } = lazily(() => import("./Link.svg"));
const { ReactComponent: ListAlt } = lazily(() => import("./ListAlt.svg"));
const { ReactComponent: LoadingCircle } = lazily(
  () => import("./LoadingCircle.svg")
);
const { ReactComponent: LocalATM } = lazily(() => import("./LocalATM.svg"));
const { ReactComponent: Location } = lazily(() => import("./Location.svg"));
const { ReactComponent: LocationOn } = lazily(() => import("./LocationOn.svg"));
const { ReactComponent: Lock } = lazily(() => import("./Lock.svg"));
const { ReactComponent: LockClock } = lazily(() => import("./LockClock.svg"));
const { ReactComponent: Mail } = lazily(() => import("./Mail.svg"));
const { ReactComponent: ManageAccount } = lazily(
  () => import("./ManageAccount.svg")
);
const { ReactComponent: MapArrow } = lazily(() => import("./MapArrow.svg"));
const { ReactComponent: MarkEmail } = lazily(() => import("./MarkEmail.svg"));
const { ReactComponent: MasterCard } = lazily(() => import("./MasterCard.svg"));
const { ReactComponent: MeatballsMenu } = lazily(
  () => import("./MeatballsMenu.svg")
);
const { ReactComponent: Memo } = lazily(() => import("./Memo.svg"));
const { ReactComponent: Memo_NotPresent } = lazily(
  () => import("./Memo_NotPresent.svg")
);
const { ReactComponent: Memo_Present } = lazily(
  () => import("./Memo_Present.svg")
);
const { ReactComponent: Menu } = lazily(() => import("./Menu.svg"));
const { ReactComponent: MintMark } = lazily(() => import("./MintMark.svg"));
const { ReactComponent: Minus } = lazily(() => import("./Minus.svg"));
const { ReactComponent: MissingBadge } = lazily(
  () => import("./MissingBadge.svg")
);
const { ReactComponent: Mobile } = lazily(() => import("./Mobile.svg"));
const { ReactComponent: ModeComment } = lazily(
  () => import("./ModeComment.svg")
);
const { ReactComponent: MoneyIn } = lazily(() => import("./MoneyIn.svg"));
const { ReactComponent: MoneyOut } = lazily(() => import("./MoneyOut.svg"));
const { ReactComponent: MoreVerticle } = lazily(
  () => import("./MoreVerticle.svg")
);
const { ReactComponent: MoveDown } = lazily(() => import("./MoveDown.svg"));
const { ReactComponent: MoveToInbox } = lazily(
  () => import("./MoveToInbox.svg")
);
const { ReactComponent: NeedAnyHelp } = lazily(
  () => import("./NeedAnyHelp.svg")
);
const { ReactComponent: NewReleases } = lazily(
  () => import("./NewReleases.svg")
);
const { ReactComponent: NoChange } = lazily(() => import("./Nochange.svg"));
const { ReactComponent: NonFlexibleLimit } = lazily(
  () => import("./NonFlexibleLimit.svg")
);
const { ReactComponent: NEArrow } = lazily(
  () => import("./NorthEastArrow.svg")
);
const { ReactComponent: NotificationActive } = lazily(
  () => import("./NotificaitonActive.svg")
);
// navigation
const { ReactComponent: Notification } = lazily(
  () => import("./Notification.svg")
);
const { ReactComponent: Notifications } = lazily(
  () => import("./Notifications.svg")
);
const { ReactComponent: OCRHigh } = lazily(() => import("./OCRHigh.svg"));
const { ReactComponent: OCRLow } = lazily(() => import("./OCRLow.svg"));
const { ReactComponent: OCRMedium } = lazily(() => import("./OCRMedium.svg"));
const { ReactComponent: OCRPending } = lazily(() => import("./OCRPending.svg"));
const { ReactComponent: OfflineBolt } = lazily(
  () => import("./OfflineBolt.svg")
);
const { ReactComponent: OpenInNew } = lazily(() => import("./OpenInNew.svg"));
const { ReactComponent: OutgoingMail } = lazily(
  () => import("./OutgoingMail.svg")
);
const { ReactComponent: OutstandingClaims } = lazily(
  () => import("./OutstandingClaims.svg")
);
const { ReactComponent: Pace } = lazily(() => import("./Pace.svg"));
const { ReactComponent: PageInfo } = lazily(() => import("./PageInfo.svg"));
const { ReactComponent: PageNotFound } = lazily(
  () => import("./PageNotFound.svg")
);
const { ReactComponent: Paid } = lazily(() => import("./Paid.svg"));
const { ReactComponent: Password } = lazily(() => import("./Password.svg"));
const { ReactComponent: PayOutsideVolopay } = lazily(
  () => import("./PayOutsideVolopay.svg")
);
const { ReactComponent: Payments } = lazily(() => import("./Payments.svg"));
const { ReactComponent: PaymentColor } = lazily(
  () => import("./PaymentsColor.svg")
);
const { ReactComponent: PendingActions } = lazily(
  () => import("./PendingActions.svg")
);
const { ReactComponent: PendingToVerify } = lazily(
  () => import("./PendingToVerify.svg")
);
const { ReactComponent: Percentage } = lazily(() => import("./Percentage.svg"));
const { ReactComponent: Person } = lazily(() => import("./Person.svg"));
const { ReactComponent: PersonAdd } = lazily(() => import("./PersonAdd.svg"));
const { ReactComponent: PersonCircled } = lazily(
  () => import("./PersonCircled.svg")
);
const { ReactComponent: PersonSearch } = lazily(
  () => import("./PersonSearch.svg")
);
const { ReactComponent: PersonWithCircle } = lazily(
  () => import("./PersonWithCircle.svg")
);
const { ReactComponent: PhotoCamera } = lazily(
  () => import("./PhotoCamera.svg")
);
const { ReactComponent: PhysicalCardEditLimit } = lazily(
  () => import("./PhysicalCardEditLimit.svg")
);
const { ReactComponent: PhysicalCardRequest } = lazily(
  () => import("./PhysicalCardRequest.svg")
);
const { ReactComponent: PieChart } = lazily(() => import("./PieChart.svg"));
const { ReactComponent: PineLabs } = lazily(() => import("./Pinelabs.svg"));
const { ReactComponent: Play } = lazily(() => import("./Play.svg"));
const { ReactComponent: PlayCircle } = lazily(() => import("./PlayCircle.svg"));
const { ReactComponent: Review } = lazily(() => import("./Review.svg"));
const { ReactComponent: PlaylistRemove } = lazily(
  () => import("./PlaylistRemove.svg")
);
const { ReactComponent: Plus } = lazily(() => import("./Plus.svg"));
const { ReactComponent: PriceChange } = lazily(
  () => import("./PriceChange.svg")
);
const { ReactComponent: PriceCheck } = lazily(() => import("./PriceCheck.svg"));
const { ReactComponent: Priority } = lazily(() => import("./Priority.svg"));
const { ReactComponent: Processing } = lazily(() => import("./Processing.svg"));
const { ReactComponent: QrCode } = lazily(() => import("./QrCode.svg"));
const { ReactComponent: QrPay } = lazily(() => import("./QrPay.svg"));
const { ReactComponent: Quiz } = lazily(() => import("./Quiz.svg"));
const { ReactComponent: ReadInbox } = lazily(() => import("./ReadInbox.svg"));
const { ReactComponent: Recurring } = lazily(() => import("./Reccuring.svg"));
const { ReactComponent: Receipt } = lazily(() => import("./Receipt.svg"));
const { ReactComponent: ReceiptChecked } = lazily(
  () => import("./ReceiptChecked.svg")
);
const { ReactComponent: ReceiptStatus } = lazily(
  () => import("./ReceiptStatus.svg")
);
const { ReactComponent: ReceiptAutoCaptured } = lazily(
  () => import("./Receipt_AutoCaptured.svg")
);
const { ReactComponent: ReceiptAutoVerified } = lazily(
  () => import("./Receipt_AutoVerified.svg")
);
const { ReactComponent: ReceiptMissing } = lazily(
  () => import("./Receipt_Missing.svg")
);
const { ReactComponent: ReceiptNotRequested } = lazily(
  () => import("./Receipt_NotRequested.svg")
);
const { ReactComponent: ReceiptSubmitted } = lazily(
  () => import("./Receipt_Submitted.svg")
);
const { ReactComponent: Received } = lazily(() => import("./Received.svg"));
const { ReactComponent: Recreated } = lazily(() => import("./Recreated.svg"));
const { ReactComponent: Redeem } = lazily(() => import("./Redeem.svg"));
const { ReactComponent: Refresh } = lazily(() => import("./Refresh.svg"));
const { ReactComponent: RefreshTime } = lazily(
  () => import("./RefreshTime.svg")
);
const { ReactComponent: Reimbursement } = lazily(
  () => import("./Reimbursement.svg")
);
const { ReactComponent: ReimbursementRequests } = lazily(
  () => import("./ReimbursementRequests.svg")
);
const { ReactComponent: Remind } = lazily(() => import("./Remind.svg"));
const { ReactComponent: Repeat } = lazily(() => import("./Repeat.svg"));
const { ReactComponent: Replay } = lazily(() => import("./Replay.svg"));
const { ReactComponent: Report } = lazily(() => import("./Report.svg"));
const { ReactComponent: ReportFailed } = lazily(
  () => import("./ReportFailed.svg")
);
const { ReactComponent: RequestClaim } = lazily(
  () => import("./RequestClaim.svg")
);
const { ReactComponent: RightArrow } = lazily(() => import("./RightArrow.svg"));
const { ReactComponent: Rotate } = lazily(() => import("./Rotate.svg"));
const { ReactComponent: RoundedCloud } = lazily(
  () => import("./RoundedCloud.svg")
);
const { ReactComponent: RoundedCreditCard } = lazily(
  () => import("./RoundedCreditCard.svg")
);
const { ReactComponent: RoundedEditCloud } = lazily(
  () => import("./RoundedEditCloud.svg")
);
const { ReactComponent: RoundedEditCreditCard } = lazily(
  () => import("./RoundedEditCreditCard.svg")
);
const { ReactComponent: Rupay } = lazily(() => import("./Rupay.svg"));
const { ReactComponent: Savings } = lazily(() => import("./Savings.svg"));
const { ReactComponent: SavingsFill } = lazily(
  () => import("./SavingsFill.svg")
);
const { ReactComponent: Schedule } = lazily(() => import("./Schedule.svg"));
const { ReactComponent: Search } = lazily(() => import("./Search.svg"));
const { ReactComponent: SelectGesture } = lazily(
  () => import("./SelectGesture.svg")
);
const { ReactComponent: Send } = lazily(() => import("./Send.svg"));
const { ReactComponent: Settings } = lazily(() => import("./Settings.svg"));
const { ReactComponent: SettingsAccount } = lazily(
  () => import("./SettingsAccount.svg")
);
const { ReactComponent: SettingsSync } = lazily(
  () => import("./SettingsSync.svg")
);
const { ReactComponent: Share } = lazily(() => import("./Share.svg"));
const { ReactComponent: Sheild } = lazily(() => import("./Sheild.svg"));
const { ReactComponent: ShieldCross } = lazily(
  () => import("./ShieldCross.svg")
);
const { ReactComponent: ShoppingBag } = lazily(
  () => import("./ShoppingBag.svg")
);
const { ReactComponent: Slack } = lazily(() => import("./Slack.svg"));
const { ReactComponent: SlashEye } = lazily(() => import("./SlashEye.svg"));
const { ReactComponent: Snowflake } = lazily(() => import("./Snowflake.svg"));
const { ReactComponent: SolidCancel } = lazily(
  () => import("./SolidCancel.svg")
);
const { ReactComponent: SolidCheckCircle } = lazily(
  () => import("./SolidCheckCircle.svg")
);
const { ReactComponent: SpinLoader } = lazily(() => import("./SpinLoader.svg"));
const { ReactComponent: Store } = lazily(() => import("./Store.svg"));
const { ReactComponent: SubPolicy_Met } = lazily(
  () => import("./SubPolicy_Met.svg")
);
const { ReactComponent: SubPolicy_NotMet } = lazily(
  () => import("./SubPolicy_NotMet.svg")
);
const { ReactComponent: SubdirectoryArrowRight } = lazily(
  () => import("./SubdirectoryArrowRight.svg")
);
const { ReactComponent: SubmissionPolicy } = lazily(
  () => import("./SubmissionPolicy.svg")
);
const { ReactComponent: SupervisorAccount } = lazily(
  () => import("./SupervisorAccount.svg")
);
const { ReactComponent: SupportAgent } = lazily(
  () => import("./SupportAgent.svg")
);
const { ReactComponent: SwapHorizontal } = lazily(
  () => import("./SwapHorizontal.svg")
);
const { ReactComponent: SwapHorzontal } = lazily(
  () => import("./SwapHorzontal.svg")
);
const { ReactComponent: SwiftLogo } = lazily(() => import("./SwiftLogo.svg"));
const { ReactComponent: Sync } = lazily(() => import("./Sync.svg"));
const { ReactComponent: SyncAlt } = lazily(() => import("./SyncAlt.svg"));
const { ReactComponent: SyncDisabled } = lazily(
  () => import("./SyncDisabled.svg")
);
const { ReactComponent: SyncFailed } = lazily(() => import("./SyncFailed.svg"));
const { ReactComponent: TArrow } = lazily(() => import("./TArrow.svg"));
const { ReactComponent: Task } = lazily(() => import("./Task.svg"));
const { ReactComponent: TaskAlt } = lazily(() => import("./TaskAlt.svg"));
const { ReactComponent: TaskCheck } = lazily(() => import("./TaskCheck.svg"));
const { ReactComponent: TeamDashboard } = lazily(
  () => import("./TeamDashboard.svg")
);
const { ReactComponent: ThumbsUp } = lazily(() => import("./ThumbsUp.svg"));
const { ReactComponent: Timer } = lazily(() => import("./Timer.svg"));
const { ReactComponent: ToLink } = lazily(() => import("./ToLink.svg"));
const { ReactComponent: Toll } = lazily(() => import("./Toll.svg"));
const { ReactComponent: TourFlag } = lazily(() => import("./TourFlag.svg"));
const { ReactComponent: TrendingDown } = lazily(
  () => import("./TrendingDown.svg")
);
const { ReactComponent: TrendingUp } = lazily(() => import("./TrendingUp.svg"));
const { ReactComponent: Triggers } = lazily(() => import("./Triggers.svg"));
const { ReactComponent: Type } = lazily(() => import("./Type.svg"));
const { ReactComponent: Unsynced } = lazily(() => import("./Unsynced.svg"));
const { ReactComponent: UpArrow } = lazily(() => import("./UpArrow.svg"));
const { ReactComponent: Upload } = lazily(() => import("./Upload.svg"));
const { ReactComponent: Vector } = lazily(() => import("./Vector.svg"));
const { ReactComponent: Verified } = lazily(() => import("./Verified.svg"));
const { ReactComponent: VerifiedUser } = lazily(
  () => import("./VerifiedUser.svg")
);
const { ReactComponent: Verify } = lazily(() => import("./Verify.svg"));
const { ReactComponent: VideoCam } = lazily(() => import("./VideoCam.svg"));
const { ReactComponent: VirtualCardEditRequest } = lazily(
  () => import("./VirtualEditLimitRequest.svg")
);
const { ReactComponent: VisaCorporateSvg } = lazily(
  () => import("./VisaCorporateSvg.svg")
);
const { ReactComponent: Volopay3D } = lazily(() => import("./Volopay3D.svg"));
const { ReactComponent: VolopayLogo } = lazily(
  () => import("./VolopayLogo.svg")
);
const { ReactComponent: VolopayLogoOnCard } = lazily(
  () => import("./VolopayLogoOnCard.svg")
);
const { ReactComponent: Wallet } = lazily(() => import("./Wallet.svg"));
const { ReactComponent: WalletLimit } = lazily(
  () => import("./WalletLimit.svg")
);
const { ReactComponent: WalletState } = lazily(
  () => import("./WalletState.svg")
);
const { ReactComponent: WarningCircle } = lazily(
  () => import("./WarningCircle.svg")
);
const { ReactComponent: WarningCircleFill } = lazily(
  () => import("./WarningCircleFill.svg")
);
const { ReactComponent: WarningHex } = lazily(() => import("./WarningHex.svg"));
const { ReactComponent: WarningTriangle } = lazily(
  () => import("./WarningTriangle.svg")
);
const { ReactComponent: WhatsApp } = lazily(() => import("./WhatsApp.svg"));
const { ReactComponent: World } = lazily(() => import("./World.svg"));
const { ReactComponent: Deskera } = lazily(
  () => import("./accounting/Deskera.svg")
);
const { ReactComponent: MYOB } = lazily(() => import("./accounting/MYOB.svg"));
const { ReactComponent: Netsuite } = lazily(
  () => import("./accounting/Netsuite.svg")
);
const { ReactComponent: Power } = lazily(
  () => import("./accounting/Power.svg")
);
const { ReactComponent: QuickBooks } = lazily(
  () => import("./accounting/Quickbooks.svg")
);
const { ReactComponent: Tally } = lazily(
  () => import("./accounting/Tally.svg")
);
const { ReactComponent: UniversalCSV } = lazily(
  () => import("./accounting/UniversalCSV.svg")
);
const { ReactComponent: Xero } = lazily(() => import("./accounting/Xero.svg"));
const { ReactComponent: ZohoBooks } = lazily(
  () => import("./accounting/Zohobooks.svg")
);
const { ReactComponent: Comments } = lazily(() => import("./comments.svg"));
const { ReactComponent: Communication } = lazily(
  () => import("./communication.svg")
);
const { ReactComponent: FreezeCardIcon } = lazily(
  () => import("./freezeCard.svg")
);
const { ReactComponent: FreezeCloud } = lazily(
  () => import("./freezeCloud.svg")
);
const { ReactComponent: GppBad } = lazily(() => import("./gppBad.svg"));
const { ReactComponent: IosArrowBack } = lazily(
  () => import("./iosArrowBack.svg")
);
const { ReactComponent: IosArrowForward } = lazily(
  () => import("./iosArrowForward.svg")
);
const { ReactComponent: Pdf } = lazily(() => import("./pdf.svg"));
const { ReactComponent: pdfDocument } = lazily(
  () => import("./pdfDocument.svg")
);
const { ReactComponent: Png } = lazily(() => import("./png.svg"));
const { ReactComponent: Reject } = lazily(() => import("./reject.svg"));
const { ReactComponent: StepOver } = lazily(() => import("./stepOver.svg"));
const { ReactComponent: Zoom } = lazily(() => import("./zoom.svg"));

export default {
  ReportFailed,
  MintMark,
  InternationalCardTopUpRequest,
  VisaCorporateSvg,
  EditInternationalCard,
  CreditCardGear,
  InternationalCards,
  Timer,
  MasterCard,
  InCompleteCircle,
  Downtime,
  ReceiptStatus,
  ReadInbox,
  Play,
  Bulb,
  MarkEmail,
  SupportAgent,
  FeatureSearch,
  Book,
  EyeTracking,
  Heart,
  ListAlt,
  AcUnit,
  PineLabs,
  Rupay,
  NewReleases,
  VideoCam,
  FilledSchedule,
  World,
  PhysicalCardEditLimit,
  PhysicalCardRequest,
  VirtualCardEditRequest,
  CardStraps,
  NotificationActive,
  Priority,
  Celebration,
  FilterList,
  CallReceived,
  CallReceivedReverse,
  RoundedCloud,
  RoundedEditCloud,
  RoundedCreditCard,
  RoundedEditCreditCard,
  Recurring,
  TourFlag,
  VolopayLogo,
  VolopayLogoOnCard,
  Dashboard,
  DashboardOther,
  Expense,
  Notification,
  Card,
  Right,
  Info,
  Close,
  Upload,
  Contacts,
  Delete,
  Department,
  Download,
  BillPay,
  Reimbursement,
  Accounting,
  Company,
  Settings,
  ShoppingBag,
  AccountBalanceWallet,
  Received,
  Discussion,
  Verify,
  Flag,
  Google,
  SyncDisabled,
  // sync icons
  Copy,
  AccountSetUp,
  Comments,
  Location,
  Sync,
  DateRangeArrow,
  Calendar,
  Eye,
  SlashEye,
  CallSplit,
  NEArrow,
  BackArrow,
  ArrowForward,
  Plus,
  Cloud,
  CreditCard,
  CSVFile,
  Person,
  Edit,
  Password,
  Snowflake,
  ChevronDown,
  SubPolicy_Met,
  SubPolicy_NotMet,
  ReceiptSubmitted,
  ReceiptAutoCaptured,
  ReceiptAutoVerified,
  ReceiptMissing,
  ReceiptNotRequested,
  Memo_Present,
  Memo_NotPresent,
  Dollar,
  ThumbsUp,
  ArrowLeft,
  MissingBadge,
  PageNotFound,
  Down,
  VerifiedUser,
  ReceiptChecked,
  SubmissionPolicy,
  Receipt,
  CreditPending,
  CreditPaid,
  Remind,
  ChevronLeft,
  CSV,
  Mail,
  ManageAccount,
  SettingsSync,
  SettingsAccount,
  Savings,
  AccountingTree,

  // Alert Icons
  BellPlus,
  BellWarning,
  BinClock,
  WarningHex,
  WarningCircle,
  WarningTriangle,
  Search,
  ToLink,
  Memo,

  Hourglass,

  SyncAlt,
  DownArrow,
  TArrow,
  Assignment,
  AssignmentAddOn,
  AddOnField,
  Done,
  Emoji,
  Attach,
  MoreVerticle,
  Add,
  AddMileageRate,
  Review,
  UpArrow,
  Task,
  TaskCheck,
  Schedule,
  LeftArrow,
  RightArrow,
  Processing,

  // Dashboard My accounts section icons
  AddReceiveAmount,
  AccountDetails,
  Exchange,
  MeatballsMenu,
  MoveDown,
  Payments,
  SavingsFill,
  Toll,
  CaptivePortal,

  // Dashboard Quick access icons
  AddCard,
  PersonAdd,
  Integrations,
  OutstandingClaims,
  RequestClaim,
  Triggers,

  // Dashboard Quick access - all icons
  AddBusiness,
  AddLocationAlt,
  Calculate,
  ContentPaste,
  ContentPasteSearch,
  Group,
  Groups,
  GroupsAdd,
  LocalATM,
  LocationOn,
  OpenInNew,
  PendingActions,
  Send,
  Store,
  Vector,
  Verified,

  // Dashboard Pending requests icons
  BillPayRequests,
  EditCard,
  ReimbursementRequests,
  Checklist,
  PendingToVerify,
  Cancel,
  BillPayCancel,
  ShieldCross,
  EArrow,
  TrendingUp,
  TrendingDown,
  SupervisorAccount,

  Adjustment,
  InvoicePayment,
  Minus,
  PlayCircle,
  Replay,
  Repeat,
  Help,
  Chat,
  Redeem,
  Cashback,
  Dot,
  ArrowUpward,
  ArrowDownward,
  Notifications,
  ChevronUp,
  SwapHorzontal,
  HowToReg,
  CircleCheck,
  PersonSearch,
  PersonWithCircle,
  PersonCircled,
  GppBad,
  PlaylistRemove,
  MoneyIn,
  MoneyOut,
  SwiftLogo,
  WarningCircleFill,
  Quiz,
  MoveToInbox,
  Xero,
  Tally,
  MYOB,
  QuickBooks,
  Netsuite,
  Deskera,
  UniversalCSV,
  ZohoBooks,
  Volopay3D,
  OfflineBolt,
  Error,
  Power,
  Sheild,
  DashboardCustomise,
  EmojiFlagSolid,
  SolidCheckCircle,
  PriceCheck,
  ModeComment,
  Alert,
  Bolt,
  CheckCircle,
  SubdirectoryArrowRight,
  SwapHorizontal,
  BookOpen,
  LightningBolt,
  SyncFailed,
  Percentage,
  Type,
  FactCheck,
  Unsynced,
  Columns,
  PriceChange,
  DoNotDisturb,
  ArrowDropUp,
  ArrowDropDown,
  FreezeCardIcon,
  FreezeCloud,
  EventRepeat,
  AcUnits,
  ChangePin,
  Block,
  Pace,
  CalendarAddOn,
  CalendarClock,
  EditCalendar,
  Recreated,
  CloudSync,
  ArmingCountdown,
  OutgoingMail,
  Slack,
  Refresh,
  RefreshTime,
  WhatsApp,
  NoChange,
  LightiningBolt,
  LightningBoltCustom,
  DownRight,
  MapArrow,
  AirlineStops,
  PieChart,
  FormatLetterSpacing,
  Article,
  Admin,
  CardState,
  CardChecked,
  Frequency,
  Menu,
  Collapse,
  Expand,
  Link,
  DonutLarge,
  QrCode,
  Wallet,
  WalletLimit,
  WalletState,
  AirplaneTicket,
  AlarmOn,
  Forum,
  SelectGesture,
  CheckedHeart,
  AccountBox,
  Headset,
  Backup,
  Ellipse,
  NeedAnyHelp,
  LearnMore,
  QrPay,
  Paid,
  Encrypted,
  Cached,
  PaymentColor,
  AdsClick,
  Reject,
  CompanyNotFound,
  DottedDesign,
  Share,
  Badge,
  Call,
  PhotoCamera,
  Lock,
  AverageTimer,
  AddNotes,
  FileCopy,
  PayOutsideVolopay,
  AccountBalance,
  LoadingCircle,
  ClearInput,
  CategoriesIcon,
  FlexibleLimit,
  ExpenseChart,
  TaskAlt,
  Zoom,
  Png,
  Pdf,
  IosArrowBack,
  IosArrowForward,
  pdfDocument,
  LockClock,
  Email,
  Mobile,
  Authenticator,
  StepOver,
  SpinLoader,
  TeamDashboard,
  InvoiceFromMail,
  InvoiceUploaded,
  ExpandMore,
  NonFlexibleLimit,
  Report,
  SolidCancel,
  Inbox,
  OCRHigh,
  OCRLow,
  OCRMedium,
  OCRPending,
  Communication,
  LightBulb,
  Entity,
  Article2,
  EditNotification,
  Analytics,
  PageInfo,
  Error404,
  AccessDeniedError,
  EditUnderline,
  Rotate,
};
