import React, { useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { init } from "@/store/reducers/app";

import { allNavUrlsSelector } from "@/store/selectors/navigations";
import {
  isAdminSelector,
  isLoggedInSelector,
  userSelector,
} from "@/store/selectors/user";
import { onboardingMailDetailsSelector } from "@/store/selectors/vendors";
import { getUrlWithRedirectUri } from "@/utils/routes";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { getRedirectionLinkForKycStatusApproved } from "@/utils/common";
import { ROUTES } from "@/constants/routes";
import { KYC_STEPS_STATUS } from "@/constants/onboarding";

const Icon = React.lazy(() => import("@/components/core/Icon"));
const PageLoader = React.lazy(() => import("@/components/core/PageLoader"));

const LoginExceeded = React.lazy(
  () => import("@/components/Login/LoginExceeded")
);
const OnboardingPageHelper = React.lazy(
  () => import("@/components/Onboarding/OnboardingPageHelper")
);
// components
const PageNotFound = React.lazy(() => import("@/components/PageNotFound"));
const ProtectedRoute = React.lazy(() => import("@/components/ProtectedRoute"));
const AddBankDetailsComponent = React.lazy(
  () => import("@/components/common/BillPayAndPayroll/AddBankDetailsComponent")
);
const LeftColumn = React.lazy(
  () =>
    import(
      "@/components/common/BillPayAndPayroll/AddBankDetailsComponent/LeftColumn"
    )
);

// routes

// layouts
const TwoFactorAuthPage = React.lazy(() => import("@/pages/2fa"));
const AcceptInvitation = React.lazy(() => import("@/pages/accept-invitation"));
const AccountLockedPage = React.lazy(() => import("@/pages/account-locked"));
const CompanyNotFound = React.lazy(() => import("@/pages/company-not-found"));
const DowntimeRoute = React.lazy(() => import("@/pages/down-time"));
const Exports = React.lazy(() => import("@/pages/exports"));
const Files = React.lazy(() => import("@/pages/files"));
const ForgotPasswordPage = React.lazy(() => import("@/pages/forgot-password"));
const VerifyInboxPage = React.lazy(
  () => import("@/pages/forgot-password/verify")
);
// protected
const GettingStartedPage = React.lazy(() => import("@/pages/getting-started"));
const InactivtityLogout = React.lazy(() => import("@/pages/inactivity-logout"));
// pages
// public
const LoginPage = React.lazy(() => import("@/pages/login"));
const NotificationsPreferencesPage = React.lazy(
  () => import("@/pages/manage/notifications")
);
const OauthLogin = React.lazy(() => import("@/pages/oauth-login"));
const ResetPasswordPage = React.lazy(() => import("@/pages/reset-password"));
const SwitchEntity = React.lazy(() => import("@/pages/switch-entity"));
const PublicLayout = React.lazy(() => import("@/layouts/public"));
const HomeLayout = React.lazy(() => import("@/layouts/home"));
const SecondaryAuthLayout = React.lazy(
  () => import("@/layouts/auth/secondary")
);
const AuthLayout = React.lazy(() => import("@/layouts/auth"));
const OnboardingLayout = React.lazy(() => import("@/layouts/Onboarding"));
const QRPayments = React.lazy(() => import("@/routes/QRPayPayments"));
const AccountingRoutes = React.lazy(() => import("@/routes/accounting"));
const AnalyticsRoutes = React.lazy(() => import("@/routes/analytics"));
const BillPayRoutes = React.lazy(() => import("@/routes/bill-pay"));
const CardRoutes = React.lazy(() => import("@/routes/cards"));
const DashboardRoutes = React.lazy(() => import("@/routes/dashboard"));
const MainRoute = React.lazy(() => import("@/routes/main-route"));
const ManageRoutes = React.lazy(() => import("@/routes/manage"));
const MyVolopayRoutes = React.lazy(() => import("@/routes/my-volopay"));
const PayrollRoutes = React.lazy(() => import("@/routes/payroll"));
const ReimbursementRoutes = React.lazy(() => import("@/routes/reimbursement"));

export default function RoutesContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentUser = useSelector(userSelector);
  const currentUserKycStatus = currentUser?.kycState;
  const isAdmin = useSelector(isAdminSelector);
  const onboardingMailDetails = useSelector(onboardingMailDetailsSelector);
  const context =
    onboardingMailDetails?.vendorClass === BILL_PAYROLL_CONTEXT.PAYROLL
      ? BILL_PAYROLL_CONTEXT.PAYROLL
      : BILL_PAYROLL_CONTEXT.BILLPAY;
  const allNavUrls = useSelector(allNavUrlsSelector);

  const isAppLoading = localStorage.getItem("access-token") && !isLoggedIn;

  useEffect(() => {
    dispatch(init({ navigate }));
  }, []);
  useEffect(() => {
    // remove o-auth url as redirect uri
    const redirectParamValue = searchParams.get("redirect");
    const isOauthPageAsRedirect = redirectParamValue?.startsWith(
      ROUTES.oauthLogin.base.absolutePath
    );
    if (isOauthPageAsRedirect) {
      searchParams.delete("redirect");
      setSearchParams(searchParams);
    }
  }, [searchParams]);
  const getRedirectionUrl = (isLogged, user) => {
    if (isLogged && user) {
      if (currentUserKycStatus === KYC_STEPS_STATUS.APPROVED) {
        return getRedirectionLinkForKycStatusApproved(allNavUrls);
      }
      return ROUTES.onboarding.base.absolutePath;
    }
    return getUrlWithRedirectUri(ROUTES.login.base.absolutePath);
  };

  return isAppLoading ? (
    <PageLoader />
  ) : (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path="/" Component={MainRoute}>
          <Route Component={AuthLayout}>
            <Route
              path={ROUTES.auth.acceptInvitation}
              Component={AcceptInvitation}
            />
            <Route
              path={ROUTES.login.base.absolutePath}
              Component={LoginPage}
            />
            <Route
              path={ROUTES.companyNotFound.base.absolutePath}
              Component={CompanyNotFound}
            />
          </Route>
          <Route Component={SecondaryAuthLayout}>
            <Route
              path={ROUTES.twoFactor.base.absolutePath}
              Component={TwoFactorAuthPage}
            />
            <Route
              path={ROUTES.loginExceeded.base.absolutePath}
              Component={LoginExceeded}
            />
            <Route
              path={ROUTES.forgotPassword.base.absolutePath}
              Component={ForgotPasswordPage}
            />
            <Route
              path={ROUTES.forgotPassword.verifyInbox.absolutePath}
              Component={VerifyInboxPage}
            />
            <Route
              path={ROUTES.accountLocked.absolutePath}
              Component={AccountLockedPage}
            />
            <Route
              path={ROUTES.resetPassword.base.absolutePath}
              Component={ResetPasswordPage}
            />
            <Route
              path={ROUTES.inactivityLogout.base.absolutePath}
              Component={InactivtityLogout}
            />
          </Route>
          <Route
            element={
              !isLoggedIn ? (
                <PublicLayout
                  leftColumn={
                    <div className="min-h-screen pt-6 pl-10 bg-neutral-100">
                      <Icon
                        className="w-16 h-10 text-neutral-700 "
                        name="VolopayLogoOnCard"
                      />
                    </div>
                  }
                />
              ) : (
                <Navigate to={getRedirectionUrl(isLoggedIn, currentUser)} />
              )
            }
          >
            <Route
              path={ROUTES.login.base.absolutePath}
              Component={LoginPage}
            />

            <Route
              path={ROUTES.twoFactor.base.absolutePath}
              Component={TwoFactorAuthPage}
            />
            <Route
              path={ROUTES.forgotPassword.base.absolutePath}
              Component={ForgotPasswordPage}
            />
          </Route>
          <Route
            path={ROUTES.oauthLogin.base.absolutePath}
            Component={OauthLogin}
          />
          <Route Component={OnboardingLayout}>
            <Route
              path={ROUTES.onboarding.base.absolutePath}
              Component={OnboardingPageHelper}
            />
          </Route>
          <Route Component={HomeLayout}>
            <Route Component={ProtectedRoute}>
              <Route
                path={ROUTES.myVolopay.gettingStarted.pathName}
                Component={GettingStartedPage}
              />
              <Route
                path={`${ROUTES.dashboard.base.absolutePath}/*`}
                Component={DashboardRoutes}
              />

              <Route
                path={`${ROUTES.cards.base.absolutePath}/*`}
                Component={CardRoutes}
              />
              <Route
                path={`${ROUTES.QRPay.base.absolutePath}/*`}
                Component={QRPayments}
              />

              <Route
                path={`${ROUTES.billpay.base.absolutePath}/*`}
                Component={BillPayRoutes}
              />

              <Route
                path={`${ROUTES.myVolopay.base.absolutePath}/*`}
                Component={MyVolopayRoutes}
              />

              <Route
                path={`${ROUTES.payroll.base.absolutePath}/*`}
                Component={PayrollRoutes}
              />
              <Route
                path={`${ROUTES.reimbursement.base.absolutePath}/*`}
                Component={ReimbursementRoutes}
              />
              <Route
                path={`${ROUTES.accounting.base.absolutePath}/*`}
                Component={AccountingRoutes}
              />
              <Route
                path={`${ROUTES.analytics.base.absolutePath}/*`}
                Component={AnalyticsRoutes}
              />
              <Route
                path={`${ROUTES.manage.base.absolutePath}/*`}
                Component={ManageRoutes}
              />
            </Route>
            <Route
              path={ROUTES.exports.base.absolutePath}
              Component={Exports}
            />
            <Route
              path={ROUTES.notifications.pathName}
              Component={NotificationsPreferencesPage}
            />
          </Route>

          <Route
            element={
              <PublicLayout leftColumn={<LeftColumn context={context} />} />
            }
          >
            <Route
              path={`${ROUTES.billpay.addBankDetails.absolutePath}/:token`}
              element={<AddBankDetailsComponent context={context} />}
            />
          </Route>
          <Route path={ROUTES.files.base.absolutePath} Component={Files} />
          <Route
            path="/"
            element={
              <Navigate
                to={
                  isLoggedIn
                    ? getRedirectionUrl(isLoggedIn, currentUser)
                    : getUrlWithRedirectUri(ROUTES.login.base.absolutePath)
                }
              />
            }
          />

          <Route
            path={ROUTES.handleSwitchEntity.base.absolutePath}
            Component={SwitchEntity}
          />

          <Route path="*" Component={PageNotFound} />
        </Route>

        <Route
          path={ROUTES.downtime.base.absolutePath}
          Component={DowntimeRoute}
        />
      </Routes>
    </Suspense>
  );
}
