/**
 * Common path names
 */
export const REQUESTS_PATH_NAME = "requests";
export const SETTINGS_PATH_NAME = "settings";
export const OVERVIEW_PATH_NAME = "overview";

export const DASHBOARD_BASE_ROUTE = "/dashboard";

/**
 * Expenses Routes
 */
export const EXPENSES_BASE_ROUTE = "expenses";
export const EXPENSES_NEEDS_REVIEW_PATH_NAME = "needs-review";
export const EXPENSES_NEEDS_REVIEW_NEEDS_YOUR_REVIEW_TAB_PATH_NAME =
  "needs-your-review";
export const EXPENSES_NEEDS_REVIEW_PENDING_TAB_PATH_NAME = "pending";
export const EXPENSES_NEEDS_REVIEW_ALL_TAB_PATH_NAME = "all";

export const EXPENSES_OUT_OF_POLICY_PATH_NAME = "out-of-policy";
export const EXPENSES_PENDING_POLICY_PATH_NAME = "pending";
export const EXPENSES_DISCUSSION_POLICY_PATH_NAME = "ongoing-discussion";
export const EXPENSES_RESOLVED_POLICY_PATH_NAME = "resolved";
export const FLAGGED_PATH_NAME = "flagged";
export const DECLINES_PATH_NAME = "declines";
/**
 * Cards routes
 */
export const CARDS_BASE_ROUTE = "/cards";
export const PHYSICAL_CARDS_PATH_NAME = "physical-cards";
export const VIRTUAL_CARDS_PATH_NAME = "virtual-cards";
export const PHYSICAL_CARDS_ALL_PATH_NAME = "all";
export const PHYSICAL_CARDS_BLOCKED_PATH_NAME = "blocked";
export const PHYSICAL_CARDS_YET_TO_ACTIVATE_PATH_NAME = "yet-to-activate";
export const VIRTUAL_CARDS_ALL_PATH_NAME = "all";
export const VIRTUAL_CARDS_BLOCKED_PATH_NAME = "blocked";
export const CARDS_SETTINGS_GENERAL = "general";
export const CARDS_SETTINGS_SPEND_CONTROL = "spend-controls";
export const CARDS_REQUEST_NEEDS_YOUR_ACTION_PATH_NAME = "needs-my-action";
export const CARDS_REQUEST_PENDING_PATH_NAME = "pending";
export const CARDS_REQUEST_HISTORY_PATH_NAME = "history";
export const CARDS_REQUEST_ALL_PATH_NAME = "all";

/**
 * QR Payments routes
 */
export const QRPAY_BASE_ROUTE = "/qr-payments";
export const USER_WALLETS_PATH_NAME = "user-wallets";
export const USER_WALLETS_ALL_PATH_NAME = "all";
export const USER_WALLETS_YET_TO_ACTIVATE_PATH_NAME = "yet-to-activate";
export const QRPAY_SETTINGS_GENERAL = "general";
export const QRPAY_SETTINGS_SPEND_CONTROL = "spend-controls";

/**
 * QRPayments Payments Routes
 */
export const QR_PAYMENTS_BASE_ROUTE = "payments";
export const QR_PAYMENTS_NEEDS_REVIEW_PATH_NAME = "needs-review";
export const QR_PAYMENTS_FLAGGED_PATH_NAME = "flagged";
export const QR_PAYMENTS_FAILURES_PATH_NAME = "failures";
/**
 * Bill Pay Routes
 */
export const BILL_PAY_BASE_ROUTE = "/bill-pay";
export const PAYMENTS_BILL_PATH_NAME = "bill";
export const PAYMENT_INVOICE_BOX_PATH_NAME = "invoice-inbox";
export const PAYMENT_APPROVALS_PATH_NAME = "approvals";
export const PAYMENTS_PATH_NAME = "payments";
export const PAID_PAYMENTS_PATH_NAME = "paid";
export const BILL_PAY_VENDORS_PATH_NAME = "vendor";
export const BILL_PAY_EXCHANGE_RATE_PATH_NAME = "exchange-rate";
export const BILL_PAY_SETTINGS_PATH_NAME = "settings";
export const ADD_BANK_DETAILS_BILL_PAY_PATH_NAME = "add-bank-account";

/**
 * Payroll
 */
export const PAYROLL_BASE_ROUTE = "/payroll";
export const PAYROLL_SALARY_PAYMENT_PATH_NAME = "salary-payment";
export const PAYROLL_INBOX_PATH_NAME = "payroll-inbox";
export const PAYROLL_APPROVALS_PATH_NAME = "approvals";
export const PAYROLL_PAYMENT_PATH_NAME = "payments";
export const PAYROLL_PAID_PATH_NAME = "paid";
export const PAYROLL_EMPLOYEES_PATH_NAME = "employees";
export const PAYROLL_SETTINGS_PATH_NAME = "settings";
export const PAYROLL_EXCHANGE_RATE_PATH_NAME = "exchange-rate";
export const PAYROLL_APPROVALS_ALL_PATH_NAME = "all";
export const PAYROLL_APPROVALS_PENDING_PATH_NAME = "pending";
export const PAYROLL_APPROVALS_NEEDS_APPROVAL_PATH_NAME = "needs-your-approval";
export const PAYROLL_PAYMENTS_ALL_PATH_NAME = "all";
export const PAYROLL_PAYMENTS_FAILED_PATH_NAME = "failed";
export const ADD_BANK_DETAILS_PAYROLL_PATH_NAME = "add-bank-account";
export const PAYROLL_SETTINGS_GENERAL_PATH_NAME = "general";
export const PAYROLL_SETTINGS_SPEND_CONTROLS_PATH_NAME = "spend-controls";

/**
 * Reimbursement
 */
export const REIMBURSEMENT_BASE_ROUTE = "/reimbursement";
export const REIMBURSEMENT_APPROVALS_PATH_NAME = "approvals";
export const REIMBURSEMENT_APPROVALS_NEED_YOUR_ATTENTION_PATH_NAME =
  "needs-your-approval";
export const REIMBURSEMENT_APPROVALS_PENDING_PATH_NAME = "pending";
export const REIMBURSEMENT_APPROVALS_ALL_PATH_NAME = "all";
export const REIMBURSEMENT_PAYMENTS_PATH_NAME = "payments";
export const REIMBURSEMENT_PAID_PATH_NAME = "paid";
export const REIMBURSEMENT_SETTINGS_PATH_NAME = "settings";
export const REIMBURSEMENT_SETTINGS_GENERAL_PATH_NAME = "general";
export const REIMBURSEMENT_SETTINGS_SPEND_CONTROLS_PATH_NAME = "spend-controls";
export const PAYMENTS_ALL_PATH_NAME = "all";
export const PAYMENTS_FAILED_PATH_NAME = "failed";
export const PAYMENT_APPROVALS_ALL_PATH_NAME = "all";
export const PAYMENT_APPROVALS_PENDING_PATH_NAME = "pending";
export const PAYMENT_APPROVALS_NEEDS_APPROVAL_PATH_NAME = "needs-your-approval";
export const BILL_PAY_SETTINGS_GENERAL_PATH_NAME = "general";
export const BILL_PAY_SETTINGS_SPEND_CONTROLS_PATH_NAME = "spend-controls";

/**
 * Analytics
 */

export const ANALYTICS_BASE_ROUTE = "/analytics";
export const ANALYTICS_TRANSACTIONS_PATH_NAME = "transactions";
export const ANALYTICS_LEDGER_PATH_NAME = "ledger";

export const ANALYTICS_TRANSACTIONS_CARDS_PATH_NAME = "cards";
export const ANALYTICS_TRANSACTIONS_BILLPAY_PATH_NAME = "bill-pay";
export const ANALYTICS_TRANSACTIONS_REIMBURSEMENT_PATH_NAME = "reimbursement";
export const ANALYTICS_TRANSACTIONS_OTHERS_PATH_NAME = "others";
export const ANALYTICS_TRANSACTIONS_QR_PAY_PATH_NAME = "qr-pay";
export const ANALYTICS_TRANSACTIONS_PAYROLL_PATH_NAME = "payroll";

export const ANALYTICS_LEDGER_TAB_PATH_NAME = {
  CREDIT: "credit",
  SGD: "sgd",
  AUD: "aud",
  INR: "inr",
  IDR: "idr",
  USD: "usd",
  ALL: "all",
};
export const ANALYTICS_LEDGER_CREDIT_PATH_NAME = "credit";
export const ANALYTICS_LEDGER_SGD_PATH_NAME = "sgd";
export const ANALYTICS_LEDGER_AUD_PATH_NAME = "aud";
export const ANALYTICS_LEDGER_INR_PATH_NAME = "inr";
export const ANALYTICS_LEDGER_IDR_PATH_NAME = "idr";
export const ANALYTICS_LEDGER_USD_PATH_NAME = "usd";
export const ANALYTICS_LEDGER_ALL_PATH_NAME = "all";
/**
 * Accounting
 */

// level 0
export const ACCOUNTING_BASE_ROUTE = "/accounting";
export const ACCOUNTING_TRANSACTIONS_PATH_NAME = "transactions";
export const ACCOUNTING_LEDGER_PATH_NAME = "ledger";
export const ACCOUNTING_INTEGRATIONS_PATH_NAME = "integrations";
export const ACCOUNTING_TRIGGERS_PATH_NAME = "triggers";
export const ACCOUNTING_RULES_PATH_NAME = "rules";
export const ACCOUNTING_BILLING_PATH_NAME = "billing";
export const ACCOUNTING_SETTINGS_PATH_NAME = "settings";

// level 1
export const ACCOUNTING_TRANSACTIONS_CARDS_PATH_NAME = "cards";
export const ACCOUNTING_TRANSACTIONS_BILLPAY_PATH_NAME = "bill-pay";
export const ACCOUNTING_TRANSACTIONS_REMIMBURSEMENT_PATH_NAME = "reimbursement";
export const ACCOUNTING_TRANSACTIONS_OTHERS_PATH_NAME = "others";
export const ACCOUNTING_TRANSACTIONS_QR_PAY_PATH_NAME = "qr-pay";
export const ACCOUNTING_TRANSACTIONS_PAYROLL_PATH_NAME = "payroll";
// level 2
export const ACCOUNTING_TRANSACTIONS_CARDS_ALL_PATH_NAME = "all";
export const ACCOUNTING_TRANSACTIONS_CARDS_SYNCED_PATH_NAME = "synced";
export const ACCOUNTING_TRANSACTIONS_CARDS_PENDING_PATH_NAME = "pending";
export const ACCOUNTING_LEDGER_CREDIT_PATH_NAME = "credit";
export const ACCOUNTING_LEDGER_CARDS_PATH_NAME = "cards";
export const ACCOUNTING_LEDGER_INTERNATIONAL_CARDS_PATH_NAME =
  "internationalCards";
export const ACCOUNTING_LEDGER_PAYMENTS_PATH_NAME = "payments";
export const ACCOUNTING_LEDGER_PAYROLL_PATH_NAME = "payroll";
export const ACCOUNTING_LEDGER_SGD_PATH_NAME = "sgd";
export const ACCOUNTING_LEDGER_AUD_PATH_NAME = "aud";
export const ACCOUNTING_LEDGER_INR_PATH_NAME = "inr";
export const ACCOUNTING_LEDGER_IDR_PATH_NAME = "idr";
export const ACCOUNTING_LEDGER_USD_PATH_NAME = "usd";
export const ACCOUNTING_LEDGER_CNY_PATH_NAME = "cny";
export const ACCOUNTING_LEDGER_CAD_PATH_NAME = "cad";
export const ACCOUNTING_LEDGER_GBD_PATH_NAME = "gbd";
export const ACCOUNTING_LEDGER_HKD_PATH_NAME = "hkd";
export const ACCOUNTING_LEDGER_JPY_PATH_NAME = "jpy";
export const ACCOUNTING_LEDGER_NZD_PATH_NAME = "nzd";
export const ACCOUNTING_LEDGER_CHF_PATH_NAME = "chf";
export const ACCOUNTING_LEDGER_EUR_PATH_NAME = "eur";
export const ACCOUNTING_LEDGER_ALL_PATH_NAME = "all";
export const ACCOUNTING_INTEGRATIONS_XERO_PATH_NAME = "xero";
export const ACCOUNTING_INTEGRATIONS_TALLY_PATH_NAME = "tally";

export const ACCOUNTING_TRANSACTIONS_ALL_PATH_NAME = "all";
export const ACCOUNTING_TRANSACTIONS_SYNCED_PATH_NAME = "synced";
export const ACCOUNTING_TRANSACTIONS_PENDING_PATH_NAME = "pending";
/**
 * Company Routes
 */
export const COMPANY_BASE_ROUTE = "/company";
export const COMPANY_NOT_FOUND_ROUTE = "company-not-found";
export const COMPANY_OVERVIEW_PATHNAME = "overview";
export const COMPANY_ACCOUNT_OVERVIEW_PATH_NAME = "account";
export const COMPANY_GLOBAL_ACCOUNTS_OVERVIEW_PATH_NAME = "global-accounts";
export const COMPANY_ACCOUNT_OVERVIEW_PARAM_NAME = "currency";

export const PEOPLE_PATH_NAME = "people";
export const USERS_PATH_NAME = "users";
export const USERS_ACTIVE_PATH_NAME = "active";
export const USERS_ALL_PATH_NAME = "all";
export const USERS_BLOCKED_PATH_NAME = "blocked";

export const ROLES_PERMISSIONS_PATH_NAME = "roles-permissions";
export const TRAVEL_PATH_NAME = "travel";
export const BILLING_PATH_NAME = "billing";

export const COMPANY_DEPARTMENTS_PATH_NAME = "department";
export const COMPANY_LOCATION_PATH_NAME = "location";
export const COMPANY_PROJECT_PATH_NAME = "project";
export const COMPANY_BUDGET_PATH_NAME = "budget";
export const COMPANY_DEPATMENT_BUDGETS_PATH_NAME = "department-budgets";
export const COMPANY_PROJECT_BUDGETS_PATH_NAME = "project-budgets";

export const CREDIT_PATH_NAME = "credit";

/**
 * Exports Routes
 */
export const EXPORTS_BASE_ROUTES = "/exports";
export const FILES_BASE_ROUTES = "/files";

export const MANAGE_BASE_ROUTE = "/manage";
export const COMPANY_PATH_NAME = "company";
export const MASTER_HISTORY_PATHNAME = "master-history";

/**
 * Notifications Routes
 */
export const NOTIFICATIONS_BASE_ROUTE = "/notifications";
export const NOTIFICATIONS_PERSONAL_LEVEL_PATHNAME = "personal";
export const NOTIFICATIONS_COMPANY_LEVEL_PATHNAME = "company";

/**
 * Settings Routes
 */
export const SETTINGS_BASE_ROUTE = "/manage";
export const SETTINGS_MASTER_HISTORY_PATHNAME = "master-history";
export const SETTINGS_GLOBAL_PATHNAME = "settings";
export const SETTINGS_CONNECTIONS_PATHNAME = "connections";
export const SETTINGS_SPEND_CONTROLS = "spend-controls";
export const SETTINGS_ALERTS = "alerts";
export const SETTINGS_DEVELOPER = "developer";

// My volopay Routes

export const MY_VOLOPAY_BASE_ROUTE = "/my-volopay";
export const MY_VOLOPAY_GETTING_STARTED_PATH_NAME = "getting-started";
export const MY_VOLOPAY_REIMBURSEMENT_PATH_NAME = "reimbursement";
export const MY_VOLOPAY_EXPENSES_PATH_NAME = "expenses";
export const MY_VOLOPAY_EXPENSES_OVERVIEW_PATH_NAME = "overview";
export const MY_VOLOPAY_EXPENSES_FLAGGED_PATH_NAME = "flagged";
export const MY_VOLOPAY_EXPENSES_DECLINED_PATH_NAME = "declines";
export const MY_VOLOPAY_ACTIONCENTRE_PATH_NAME = "action-centre";
export const MY_VOLOPAY_ACTION_CENTRE_CARDS_PATH_NAME = "cards";
export const MY_VOLOPAY_ACTION_CENTRE_BILL_PAY_PATH_NAME = "billPay";
export const MY_VOLOPAY_ACTION_CENTRE_REIMBURSEMENT_PATH_NAME = "reimbursement";
export const MY_VOLOPAY_ACTION_CENTRE_PAYROLL_PATH_NAME = "payroll";
export const MY_VOLOPAY_REIMBURSEMENT_CLAIM_PATH_NAME = "claims";
export const MY_VOLOPAY_REIMBURSEMENT_DRAFTS_PATH_NAME = "drafts";
export const MY_VOLOPAY_REIMBURSEMENT_ACTIVE_PATH_NAME = "active";
export const MY_VOLOPAY_REIMBURSEMENT_HISTORY_PATH_NAME = "history";
export const MY_VOLOPAY_PAYMENT_HISTORY_PATH_NAME = "payment-history";
export const MY_VOLOPAY_VIRTUAL_CARDS_PATH_NAME = "virtual-cards";
export const MY_VOLOPAY_PHYSICAL_CARDS_ALL_PATH_NAME = "all";
export const MY_VOLOPAY_PHYSICAL_CARDS_ACTIVE_PATH_NAME = "active";
export const MY_VOLOPAY_PHYSICAL_CARDS_YET_TO_ACTIVATE_PATH_NAME =
  "yet-to-activate";
export const MY_VOLOPAY_VIRTUAL_CARDS_BLOCKED_PATH_NAME = "blocked";
export const MY_VOLOPAY_VIRTUAL_CARDS_ALL_PATH_NAME = "all";
export const MY_VOLOPAY_PHYSICAL_CARDS_BLOCKED_PATH_NAME = "blocked";
export const MY_VOLOPAY_REIMBURSEMENT_REPORTS_PATH_NAME = "reports";
export const MY_VOLOPAY_TRAVEL_PATH_NAME = "travel";

/**
 * Auth Routes
 */
export const ACCEPT_INVITATION_ROUTES = "/accept-invitation";
/**
 * Login Routes
 */
export const LOGIN_BASE_ROUTES = "/login";
/**
 * Account Locked  Routes
 */
export const ACCOUNT_LOCKED_ROUTES = "/account-locked";
/**
 * Login Exceeded Routes
 */
export const LOGIN_EXCEEDED_ROUTES = "/login-exceeded";
/**
 * 2 fa Routes
 */
export const TWO_FACTOR_BASE_ROUTES = "/2fa";
/**
 * forgot-password Routes
 */
export const FORGOT_PASSWORD_BASE_ROTES = "/forgot-password";
export const VERIFY_INBOX_ROUTE = "verify";
/**
 * Reset Password Routes
 */
export const RESET_PASSWORD_BASE_ROUTES = "/reset-password";
/**
 * google authentication Page
 */
export const GOOGLE_OAUTH_PAGE_BASE_ROUTES = "/oauth-login";
export const ONBOARDING_PAGE_BASE_ROUTES = "/onboarding";
export const INACTIVITY_LOGOUT_ROUTES = "/inactivity-logout";

export const HANDLE_SWITCH_ENTITY = "/handle-switch-entity";

export const DOWN_TIME = "/downtime";

export const ROUTES = {
  downtime: {
    base: {
      absolutePath: DOWN_TIME,
    },
  },
  auth: {
    acceptInvitation: ACCEPT_INVITATION_ROUTES,
  },
  login: {
    base: {
      absolutePath: LOGIN_BASE_ROUTES,
    },
  },
  handleSwitchEntity: {
    base: {
      absolutePath: HANDLE_SWITCH_ENTITY,
    },
  },
  companyNotFound: {
    base: {
      absolutePath: COMPANY_NOT_FOUND_ROUTE,
    },
  },
  inactivityLogout: {
    base: {
      absolutePath: `${INACTIVITY_LOGOUT_ROUTES}`,
    },
  },
  accountLocked: {
    absolutePath: ACCOUNT_LOCKED_ROUTES,
  },
  forgotPassword: {
    base: {
      absolutePath: FORGOT_PASSWORD_BASE_ROTES,
    },
    verifyInbox: {
      absolutePath: `${FORGOT_PASSWORD_BASE_ROTES}/${VERIFY_INBOX_ROUTE}`,
      pathName: VERIFY_INBOX_ROUTE,
    },
  },
  resetPassword: {
    base: {
      absolutePath: RESET_PASSWORD_BASE_ROUTES,
    },
  },
  twoFactor: {
    base: {
      absolutePath: TWO_FACTOR_BASE_ROUTES,
    },
  },
  loginExceeded: {
    base: {
      absolutePath: LOGIN_EXCEEDED_ROUTES,
    },
  },
  oauthLogin: {
    base: {
      absolutePath: GOOGLE_OAUTH_PAGE_BASE_ROUTES,
    },
  },
  onboarding: {
    base: {
      absolutePath: ONBOARDING_PAGE_BASE_ROUTES,
    },
  },
  myVolopay: {
    base: {
      title: "myVolopay.title",
      icon: "Contacts",
      key: "myVolopay",
      absolutePath: MY_VOLOPAY_BASE_ROUTE,
      order: 1,
      borderBottom: true,
    },
    gettingStarted: {
      order: 1,
      title: "myVolopay.gettingStarted.title",
      key: "myVolopay:gettingStarted",
      pathName: MY_VOLOPAY_GETTING_STARTED_PATH_NAME,
      absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_GETTING_STARTED_PATH_NAME}`,
    },
    actionCentre: {
      order: 2,
      title: "myVolopay.actionCentre.title",
      key: "myVolopay:actionCentre",
      pathName: MY_VOLOPAY_ACTIONCENTRE_PATH_NAME,
      absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_ACTIONCENTRE_PATH_NAME}`,
      cards: {
        pathName: MY_VOLOPAY_ACTION_CENTRE_CARDS_PATH_NAME,
        absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_ACTIONCENTRE_PATH_NAME}/${MY_VOLOPAY_ACTION_CENTRE_CARDS_PATH_NAME}`,
      },
      billPay: {
        pathName: MY_VOLOPAY_ACTION_CENTRE_BILL_PAY_PATH_NAME,
        absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_ACTIONCENTRE_PATH_NAME}/${MY_VOLOPAY_ACTION_CENTRE_BILL_PAY_PATH_NAME}`,
      },
      reimbursement: {
        pathName: MY_VOLOPAY_ACTION_CENTRE_REIMBURSEMENT_PATH_NAME,
        absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_ACTIONCENTRE_PATH_NAME}/${MY_VOLOPAY_ACTION_CENTRE_REIMBURSEMENT_PATH_NAME}`,
      },
      payroll: {
        pathName: MY_VOLOPAY_ACTION_CENTRE_PAYROLL_PATH_NAME,
        absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_ACTIONCENTRE_PATH_NAME}/${MY_VOLOPAY_ACTION_CENTRE_PAYROLL_PATH_NAME}`,
      },
    },
    expenses: {
      order: 3,
      title: "expenses.title",
      key: "myVolopay:expenses",
      pathName: MY_VOLOPAY_EXPENSES_PATH_NAME,
      absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_EXPENSES_PATH_NAME}`,
      overView: {
        order: 1,
        title: "expenses.overview.title",
        key: "myVolopay:expenses:overView",
        pathName: MY_VOLOPAY_EXPENSES_OVERVIEW_PATH_NAME,
        absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_EXPENSES_PATH_NAME}/${MY_VOLOPAY_EXPENSES_OVERVIEW_PATH_NAME}`,
      },
      flagged: {
        order: 2,
        title: "expenses.flagged.title",
        key: "myVolopay:expenses:flagged",
        pathName: MY_VOLOPAY_EXPENSES_FLAGGED_PATH_NAME,
        absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_EXPENSES_PATH_NAME}/${MY_VOLOPAY_EXPENSES_FLAGGED_PATH_NAME}`,
      },
      declines: {
        order: 3,
        title: "expenses.declines.title",
        key: "myVolopay:expenses:declines",
        pathName: MY_VOLOPAY_EXPENSES_DECLINED_PATH_NAME,
        absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_EXPENSES_PATH_NAME}/${MY_VOLOPAY_EXPENSES_DECLINED_PATH_NAME}`,
      },
    },
    cards: {
      order: 4,
      title: "cards.title.plural",
      key: "myVolopay:cards",
      pathName: MY_VOLOPAY_ACTION_CENTRE_CARDS_PATH_NAME,
      absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_ACTION_CENTRE_CARDS_PATH_NAME}`,
      physicalCards: {
        order: 2,
        title: "cards.navigationLabels.pCard",
        key: "myVolopay:cards:physicalCards",
        pathName: PHYSICAL_CARDS_PATH_NAME,
        absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_ACTION_CENTRE_CARDS_PATH_NAME}/${PHYSICAL_CARDS_PATH_NAME}`,
        all: {
          pathName: MY_VOLOPAY_PHYSICAL_CARDS_ALL_PATH_NAME,
          absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_ACTION_CENTRE_CARDS_PATH_NAME}/${PHYSICAL_CARDS_PATH_NAME}/${MY_VOLOPAY_PHYSICAL_CARDS_ALL_PATH_NAME}`,
        },
        active: {
          pathName: MY_VOLOPAY_PHYSICAL_CARDS_ACTIVE_PATH_NAME,
          absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_ACTION_CENTRE_CARDS_PATH_NAME}/${PHYSICAL_CARDS_PATH_NAME}/${MY_VOLOPAY_PHYSICAL_CARDS_ACTIVE_PATH_NAME}`,
        },
        yetToActivate: {
          pathName: MY_VOLOPAY_PHYSICAL_CARDS_YET_TO_ACTIVATE_PATH_NAME,
          absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_ACTION_CENTRE_CARDS_PATH_NAME}/${PHYSICAL_CARDS_PATH_NAME}/${MY_VOLOPAY_PHYSICAL_CARDS_YET_TO_ACTIVATE_PATH_NAME}`,
        },
        blocked: {
          pathName: MY_VOLOPAY_PHYSICAL_CARDS_BLOCKED_PATH_NAME,
          absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_ACTION_CENTRE_CARDS_PATH_NAME}/${PHYSICAL_CARDS_PATH_NAME}/${MY_VOLOPAY_PHYSICAL_CARDS_BLOCKED_PATH_NAME}`,
        },
      },
      virtualCards: {
        order: 1,
        title: "cards.navigationLabels.vCard",
        key: "myVolopay:cards:virtualCards",
        pathName: MY_VOLOPAY_VIRTUAL_CARDS_PATH_NAME,
        absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_ACTION_CENTRE_CARDS_PATH_NAME}/${MY_VOLOPAY_VIRTUAL_CARDS_PATH_NAME}`,
        all: {
          pathName: MY_VOLOPAY_VIRTUAL_CARDS_ALL_PATH_NAME,
          absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_ACTION_CENTRE_CARDS_PATH_NAME}/${MY_VOLOPAY_VIRTUAL_CARDS_PATH_NAME}/${MY_VOLOPAY_VIRTUAL_CARDS_ALL_PATH_NAME}`,
        },
        blocked: {
          pathName: MY_VOLOPAY_VIRTUAL_CARDS_BLOCKED_PATH_NAME,
          absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_ACTION_CENTRE_CARDS_PATH_NAME}/${MY_VOLOPAY_VIRTUAL_CARDS_PATH_NAME}/${MY_VOLOPAY_VIRTUAL_CARDS_BLOCKED_PATH_NAME}`,
        },
      },
    },
    reimbursement: {
      order: 5,
      title: "myVolopay.reimbursements.title",
      key: "myVolopay:reimbursement",
      pathName: MY_VOLOPAY_REIMBURSEMENT_PATH_NAME,
      absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_REIMBURSEMENT_PATH_NAME}`,
      claims: {
        order: 1,
        title: "myVolopay.reimbursements.childNav.claims",
        key: "myVolopay:reimbursement:claims",
        pathName: MY_VOLOPAY_REIMBURSEMENT_CLAIM_PATH_NAME,
        absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_REIMBURSEMENT_PATH_NAME}/${MY_VOLOPAY_REIMBURSEMENT_CLAIM_PATH_NAME}`,
        drafts: {
          pathName: MY_VOLOPAY_REIMBURSEMENT_DRAFTS_PATH_NAME,
          absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_REIMBURSEMENT_PATH_NAME}/${MY_VOLOPAY_REIMBURSEMENT_CLAIM_PATH_NAME}/${MY_VOLOPAY_REIMBURSEMENT_DRAFTS_PATH_NAME}`,
        },
        active: {
          pathName: MY_VOLOPAY_REIMBURSEMENT_ACTIVE_PATH_NAME,
          absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_REIMBURSEMENT_PATH_NAME}/${MY_VOLOPAY_REIMBURSEMENT_CLAIM_PATH_NAME}/${MY_VOLOPAY_REIMBURSEMENT_ACTIVE_PATH_NAME}`,
        },
        history: {
          pathName: MY_VOLOPAY_REIMBURSEMENT_HISTORY_PATH_NAME,
          absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_REIMBURSEMENT_PATH_NAME}/${MY_VOLOPAY_REIMBURSEMENT_CLAIM_PATH_NAME}/${MY_VOLOPAY_REIMBURSEMENT_HISTORY_PATH_NAME}`,
        },
      },
      reports: {
        order: 2,
        title: "myVolopay.reimbursements.childNav.reports",
        key: "myVolopay:reimbursement:reports",
        pathName: MY_VOLOPAY_REIMBURSEMENT_REPORTS_PATH_NAME,
        absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_REIMBURSEMENT_PATH_NAME}/${MY_VOLOPAY_REIMBURSEMENT_REPORTS_PATH_NAME}`,
        drafts: {
          pathName: MY_VOLOPAY_REIMBURSEMENT_DRAFTS_PATH_NAME,
          absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_REIMBURSEMENT_PATH_NAME}/${MY_VOLOPAY_REIMBURSEMENT_REPORTS_PATH_NAME}/${MY_VOLOPAY_REIMBURSEMENT_DRAFTS_PATH_NAME}`,
        },
        active: {
          pathName: MY_VOLOPAY_REIMBURSEMENT_ACTIVE_PATH_NAME,
          absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_REIMBURSEMENT_PATH_NAME}/${MY_VOLOPAY_REIMBURSEMENT_REPORTS_PATH_NAME}/${MY_VOLOPAY_REIMBURSEMENT_ACTIVE_PATH_NAME}`,
        },

        history: {
          pathName: MY_VOLOPAY_REIMBURSEMENT_HISTORY_PATH_NAME,
          absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_REIMBURSEMENT_PATH_NAME}/${MY_VOLOPAY_REIMBURSEMENT_REPORTS_PATH_NAME}/${MY_VOLOPAY_REIMBURSEMENT_HISTORY_PATH_NAME}`,
        },
      },
      paymentHistory: {
        order: 3,
        title: "myVolopay.reimbursements.childNav.paymentHistory",
        key: "myVolopay:reimbursement:paymentHistory",
        pathName: MY_VOLOPAY_PAYMENT_HISTORY_PATH_NAME,
        absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_REIMBURSEMENT_PATH_NAME}/${MY_VOLOPAY_PAYMENT_HISTORY_PATH_NAME}`,
      },
    },
    travel: {
      order: 6,
      title: "company.travel.title",
      key: "myVolopay:travel",
      pathName: MY_VOLOPAY_TRAVEL_PATH_NAME,
      absolutePath: `${MY_VOLOPAY_BASE_ROUTE}/${MY_VOLOPAY_TRAVEL_PATH_NAME}`,
    },
  },
  dashboard: {
    base: {
      title: "dashboard.title",
      icon: "Analytics",
      key: "dashboard",
      absolutePath: DASHBOARD_BASE_ROUTE,
      order: 2,
    },
    account: {
      pathName: COMPANY_ACCOUNT_OVERVIEW_PATH_NAME,
      absolutePath: `${DASHBOARD_BASE_ROUTE}/${COMPANY_ACCOUNT_OVERVIEW_PATH_NAME}`,
    },
    globalAccounts: {
      pathName: COMPANY_GLOBAL_ACCOUNTS_OVERVIEW_PATH_NAME,
      absolutePath: `${DASHBOARD_BASE_ROUTE}/${COMPANY_GLOBAL_ACCOUNTS_OVERVIEW_PATH_NAME}`,
    },
    credit: {
      pathName: CREDIT_PATH_NAME,
      absolutePath: `${DASHBOARD_BASE_ROUTE}/${CREDIT_PATH_NAME}`,
    },
  },
  cards: {
    base: {
      title: "cards.title.plural",
      icon: "Card",
      key: "cards",
      absolutePath: CARDS_BASE_ROUTE,
      order: 3,
    },
    expenses: {
      title: "expenses.title",
      key: "cards:expenses",
      absolutePath: `${CARDS_BASE_ROUTE}/${EXPENSES_BASE_ROUTE}`,
      pathName: EXPENSES_BASE_ROUTE,
      order: 1,
      overView: {
        title: "navigation.overview",
        key: "cards:expenses:overView",
        pathName: OVERVIEW_PATH_NAME,
        absolutePath: `${CARDS_BASE_ROUTE}/${EXPENSES_BASE_ROUTE}/${OVERVIEW_PATH_NAME}`,
        order: 1,
      },
      needsReview: {
        title: "navigation.needsReview",
        key: "cards:expenses:needsReview",
        pathName: EXPENSES_NEEDS_REVIEW_PATH_NAME,
        absolutePath: `${CARDS_BASE_ROUTE}/${EXPENSES_BASE_ROUTE}/${EXPENSES_NEEDS_REVIEW_PATH_NAME}`,
        order: 2,
        needsYourReview: {
          pathName: EXPENSES_NEEDS_REVIEW_NEEDS_YOUR_REVIEW_TAB_PATH_NAME,
          absolutePath: `${CARDS_BASE_ROUTE}/${EXPENSES_BASE_ROUTE}/${EXPENSES_NEEDS_REVIEW_PATH_NAME}/${EXPENSES_NEEDS_REVIEW_NEEDS_YOUR_REVIEW_TAB_PATH_NAME}`,
        },
        pending: {
          pathName: EXPENSES_NEEDS_REVIEW_PENDING_TAB_PATH_NAME,
          absolutePath: `${CARDS_BASE_ROUTE}/${EXPENSES_BASE_ROUTE}/${EXPENSES_NEEDS_REVIEW_PATH_NAME}/${EXPENSES_NEEDS_REVIEW_PENDING_TAB_PATH_NAME}`,
        },
        all: {
          pathName: EXPENSES_NEEDS_REVIEW_ALL_TAB_PATH_NAME,
          absolutePath: `${CARDS_BASE_ROUTE}/${EXPENSES_BASE_ROUTE}/${EXPENSES_NEEDS_REVIEW_PATH_NAME}/${EXPENSES_NEEDS_REVIEW_ALL_TAB_PATH_NAME}`,
        },
      },
      flagged: {
        title: "navigation.flagged",
        key: "cards:expenses:flagged",
        pathName: FLAGGED_PATH_NAME,
        absolutePath: `${CARDS_BASE_ROUTE}/${EXPENSES_BASE_ROUTE}/${FLAGGED_PATH_NAME}`,
        order: 3,
      },
      declines: {
        title: "navigation.declines",
        key: "cards:expenses:declines",
        pathName: DECLINES_PATH_NAME,
        absolutePath: `${CARDS_BASE_ROUTE}/${EXPENSES_BASE_ROUTE}/${DECLINES_PATH_NAME}`,
        order: 4,
      },
    },
    virtualCards: {
      title: "cards.navigationLabels.vCard",
      key: "cards:virtualCards",
      pathName: VIRTUAL_CARDS_PATH_NAME,
      absolutePath: `${CARDS_BASE_ROUTE}/${VIRTUAL_CARDS_PATH_NAME}`,
      order: 2,
      all: {
        pathName: VIRTUAL_CARDS_ALL_PATH_NAME,
        absolutePath: `${CARDS_BASE_ROUTE}/${VIRTUAL_CARDS_PATH_NAME}/${VIRTUAL_CARDS_ALL_PATH_NAME}`,
      },
      blocked: {
        pathName: VIRTUAL_CARDS_BLOCKED_PATH_NAME,
        absolutePath: `${CARDS_BASE_ROUTE}/${VIRTUAL_CARDS_PATH_NAME}/${VIRTUAL_CARDS_BLOCKED_PATH_NAME}`,
      },
    },
    physicalCards: {
      title: "cards.navigationLabels.pCard",
      key: "cards:physicalCards",
      pathName: PHYSICAL_CARDS_PATH_NAME,
      absolutePath: `${CARDS_BASE_ROUTE}/${PHYSICAL_CARDS_PATH_NAME}`,
      order: 3,
      all: {
        pathName: PHYSICAL_CARDS_ALL_PATH_NAME,
        absolutePath: `${CARDS_BASE_ROUTE}/${PHYSICAL_CARDS_PATH_NAME}/${PHYSICAL_CARDS_ALL_PATH_NAME}`,
      },
      blocked: {
        pathName: PHYSICAL_CARDS_BLOCKED_PATH_NAME,
        absolutePath: `${CARDS_BASE_ROUTE}/${PHYSICAL_CARDS_PATH_NAME}/${PHYSICAL_CARDS_BLOCKED_PATH_NAME}`,
      },
      yetToActivate: {
        pathName: PHYSICAL_CARDS_YET_TO_ACTIVATE_PATH_NAME,
        absolutePath: `${CARDS_BASE_ROUTE}/${PHYSICAL_CARDS_PATH_NAME}/${PHYSICAL_CARDS_YET_TO_ACTIVATE_PATH_NAME}`,
      },
    },
    requests: {
      title: "cards.navigationLabels.requests",
      key: "cards:requests",
      pathName: REQUESTS_PATH_NAME,
      absolutePath: `${CARDS_BASE_ROUTE}/${REQUESTS_PATH_NAME}`,
      order: 4,
      needsYourAction: {
        pathName: CARDS_REQUEST_NEEDS_YOUR_ACTION_PATH_NAME,
        absolutePath: `${CARDS_BASE_ROUTE}/${REQUESTS_PATH_NAME}/${CARDS_REQUEST_NEEDS_YOUR_ACTION_PATH_NAME}`,
      },
      pending: {
        pathName: CARDS_REQUEST_PENDING_PATH_NAME,
        absolutePath: `${CARDS_BASE_ROUTE}/${REQUESTS_PATH_NAME}/${CARDS_REQUEST_PENDING_PATH_NAME}`,
      },
      all: {
        pathName: CARDS_REQUEST_ALL_PATH_NAME,
        absolutePath: `${CARDS_BASE_ROUTE}/${REQUESTS_PATH_NAME}/${CARDS_REQUEST_ALL_PATH_NAME}`,
      },
    },
    settings: {
      title: "cards.navigationLabels.settings",
      key: "cards:settings",
      pathName: SETTINGS_PATH_NAME,
      absolutePath: `${CARDS_BASE_ROUTE}/${SETTINGS_PATH_NAME}`,
      order: 5,
      general: {
        pathName: CARDS_SETTINGS_GENERAL,
        absolutePath: `${CARDS_BASE_ROUTE}/${SETTINGS_PATH_NAME}/${CARDS_SETTINGS_GENERAL}`,
      },
      spendControl: {
        pathName: CARDS_SETTINGS_SPEND_CONTROL,
        absolutePath: `${CARDS_BASE_ROUTE}/${SETTINGS_PATH_NAME}/${CARDS_SETTINGS_SPEND_CONTROL}`,
      },
    },
  },
  billpay: {
    base: {
      title: "billPay.title",
      icon: "BillPay",
      key: "billpay",
      absolutePath: BILL_PAY_BASE_ROUTE,
      order: 4,
    },
    bill: {
      title: "billPay.bill.title",
      key: "billpay:bill",
      pathName: PAYMENTS_BILL_PATH_NAME,
      absolutePath: `${BILL_PAY_BASE_ROUTE}/${PAYMENTS_BILL_PATH_NAME}`,
      order: 1,
      invoiceInbox: {
        title: "billPay.bill.invoiceInbox.title",
        key: "billpay:bill:invoiceInbox",
        pathName: PAYMENT_INVOICE_BOX_PATH_NAME,
        absolutePath: `${BILL_PAY_BASE_ROUTE}/${PAYMENTS_BILL_PATH_NAME}/${PAYMENT_INVOICE_BOX_PATH_NAME}`,
        order: 1,
      },
      approvals: {
        title: "billPay.bill.approvals.title",
        key: "billpay:bill:approvals",
        pathName: PAYMENT_APPROVALS_PATH_NAME,
        absolutePath: `${BILL_PAY_BASE_ROUTE}/${PAYMENTS_BILL_PATH_NAME}/${PAYMENT_APPROVALS_PATH_NAME}`,
        order: 2,
        all: {
          pathName: PAYMENT_APPROVALS_ALL_PATH_NAME,
          absolutePath: `${BILL_PAY_BASE_ROUTE}/${PAYMENTS_BILL_PATH_NAME}/${PAYMENT_APPROVALS_PATH_NAME}/${PAYMENT_APPROVALS_ALL_PATH_NAME}`,
        },
        pending: {
          pathName: PAYMENT_APPROVALS_PENDING_PATH_NAME,
          absolutePath: `${BILL_PAY_BASE_ROUTE}/${PAYMENTS_BILL_PATH_NAME}/${PAYMENT_APPROVALS_PATH_NAME}/${PAYMENT_APPROVALS_PENDING_PATH_NAME}`,
        },
        needsApproval: {
          pathName: PAYMENT_APPROVALS_NEEDS_APPROVAL_PATH_NAME,
          absolutePath: `${BILL_PAY_BASE_ROUTE}/${PAYMENTS_BILL_PATH_NAME}/${PAYMENT_APPROVALS_PATH_NAME}/${PAYMENT_APPROVALS_NEEDS_APPROVAL_PATH_NAME}`,
        },
      },
      payments: {
        title: "billPay.bill.payments.title",
        key: "billpay:bill:payments",
        pathName: PAYMENTS_PATH_NAME,
        absolutePath: `${BILL_PAY_BASE_ROUTE}/${PAYMENTS_BILL_PATH_NAME}/${PAYMENTS_PATH_NAME}`,
        order: 3,
        all: {
          pathName: PAYMENTS_ALL_PATH_NAME,
          absolutePath: `${BILL_PAY_BASE_ROUTE}/${PAYMENTS_BILL_PATH_NAME}/${PAYMENTS_PATH_NAME}/${PAYMENTS_ALL_PATH_NAME}`,
        },
        failed: {
          pathName: PAYMENTS_FAILED_PATH_NAME,
          absolutePath: `${BILL_PAY_BASE_ROUTE}/${PAYMENTS_BILL_PATH_NAME}/${PAYMENTS_PATH_NAME}/${PAYMENTS_FAILED_PATH_NAME}`,
        },
      },
      paid: {
        title: "billPay.bill.paid.title",
        key: "billpay:bill:paid",
        pathName: PAID_PAYMENTS_PATH_NAME,
        absolutePath: `${BILL_PAY_BASE_ROUTE}/${PAYMENTS_BILL_PATH_NAME}/${PAID_PAYMENTS_PATH_NAME}`,
        order: 4,
      },
    },
    vendors: {
      title: "billPay.vendors.title",
      key: "billpay:vendors",
      pathName: BILL_PAY_VENDORS_PATH_NAME,
      absolutePath: `${BILL_PAY_BASE_ROUTE}/${BILL_PAY_VENDORS_PATH_NAME}`,
      order: 2,
    },
    exchangeRate: {
      title: "billPay.exchangeRate.title",
      key: "billpay:exchangeRate",
      pathName: BILL_PAY_EXCHANGE_RATE_PATH_NAME,
      absolutePath: `${BILL_PAY_BASE_ROUTE}/${BILL_PAY_EXCHANGE_RATE_PATH_NAME}`,
      order: 3,
    },
    requests: {
      title: "billPay.requests.title",
      key: "billpay:requests",
      pathName: REQUESTS_PATH_NAME,
      absolutePath: `${BILL_PAY_BASE_ROUTE}/${REQUESTS_PATH_NAME}`,
      order: 4,
    },
    settings: {
      title: "billPay.settings.title",
      key: "billpay:settings",
      pathName: BILL_PAY_SETTINGS_PATH_NAME,
      absolutePath: `${BILL_PAY_BASE_ROUTE}/${BILL_PAY_SETTINGS_PATH_NAME}`,
      order: 5,
      general: {
        pathName: BILL_PAY_SETTINGS_GENERAL_PATH_NAME,
        absolutePath: `${BILL_PAY_BASE_ROUTE}/${BILL_PAY_SETTINGS_PATH_NAME}/${BILL_PAY_SETTINGS_GENERAL_PATH_NAME}`,
      },
      spendControls: {
        pathName: BILL_PAY_SETTINGS_SPEND_CONTROLS_PATH_NAME,
        absolutePath: `${BILL_PAY_BASE_ROUTE}/${BILL_PAY_SETTINGS_PATH_NAME}/${BILL_PAY_SETTINGS_SPEND_CONTROLS_PATH_NAME}`,
      },
    },
    addBankDetails: {
      absolutePath: `${ADD_BANK_DETAILS_BILL_PAY_PATH_NAME}`,
    },
  },
  reimbursement: {
    base: {
      title: "reimbursement.title",
      icon: "AccountBalanceWallet",
      key: "reimbursement",
      absolutePath: REIMBURSEMENT_BASE_ROUTE,
      order: 5,
    },
    approvals: {
      title: "reimbursement.approvals.title",
      key: "reimbursement:approvals",
      pathName: REIMBURSEMENT_APPROVALS_PATH_NAME,
      absolutePath: `${REIMBURSEMENT_BASE_ROUTE}/${REIMBURSEMENT_APPROVALS_PATH_NAME}`,
      order: 1,
      needYourAttention: {
        pathName: REIMBURSEMENT_APPROVALS_NEED_YOUR_ATTENTION_PATH_NAME,
        absolutePath: `${REIMBURSEMENT_BASE_ROUTE}/${REIMBURSEMENT_APPROVALS_PATH_NAME}/${REIMBURSEMENT_APPROVALS_NEED_YOUR_ATTENTION_PATH_NAME}`,
      },
      pending: {
        pathName: REIMBURSEMENT_APPROVALS_PENDING_PATH_NAME,
        absolutePath: `${REIMBURSEMENT_BASE_ROUTE}/${REIMBURSEMENT_APPROVALS_PATH_NAME}/${REIMBURSEMENT_APPROVALS_PENDING_PATH_NAME}`,
      },
      all: {
        pathName: REIMBURSEMENT_APPROVALS_ALL_PATH_NAME,
        absolutePath: `${REIMBURSEMENT_BASE_ROUTE}/${REIMBURSEMENT_APPROVALS_PATH_NAME}/${REIMBURSEMENT_APPROVALS_ALL_PATH_NAME}`,
      },
    },
    payments: {
      title: "reimbursement.payments.title",
      key: "reimbursement:payments",
      pathName: REIMBURSEMENT_PAYMENTS_PATH_NAME,
      absolutePath: `${REIMBURSEMENT_BASE_ROUTE}/${REIMBURSEMENT_PAYMENTS_PATH_NAME}`,
      order: 2,
    },
    paid: {
      title: "reimbursement.paid.title",
      key: "reimbursement:paid",
      pathName: REIMBURSEMENT_PAID_PATH_NAME,
      absolutePath: `${REIMBURSEMENT_BASE_ROUTE}/${REIMBURSEMENT_PAID_PATH_NAME}`,
      order: 3,
    },
    settings: {
      title: "reimbursement.settings.title",
      key: "reimbursement:settings",
      pathName: REIMBURSEMENT_SETTINGS_PATH_NAME,
      absolutePath: `${REIMBURSEMENT_BASE_ROUTE}/${REIMBURSEMENT_SETTINGS_PATH_NAME}`,
      order: 4,
      general: {
        pathName: REIMBURSEMENT_SETTINGS_GENERAL_PATH_NAME,
        absolutePath: `${REIMBURSEMENT_BASE_ROUTE}/${REIMBURSEMENT_SETTINGS_PATH_NAME}/${REIMBURSEMENT_SETTINGS_GENERAL_PATH_NAME}`,
      },
      spendControl: {
        pathName: REIMBURSEMENT_SETTINGS_SPEND_CONTROLS_PATH_NAME,
        absolutePath: `${REIMBURSEMENT_BASE_ROUTE}/${REIMBURSEMENT_SETTINGS_PATH_NAME}/${REIMBURSEMENT_SETTINGS_SPEND_CONTROLS_PATH_NAME}`,
      },
    },
  },
  payroll: {
    base: {
      title: "payroll.title",
      icon: "Payments",
      key: "payroll",
      absolutePath: PAYROLL_BASE_ROUTE,
      order: 7,
    },
    salaryPayment: {
      title: "payroll.salaryPayment.title",
      key: "payroll:salaryPayment",
      pathName: PAYROLL_SALARY_PAYMENT_PATH_NAME,
      absolutePath: `${PAYROLL_BASE_ROUTE}/${PAYROLL_SALARY_PAYMENT_PATH_NAME}`,
      order: 1,
      payrollInbox: {
        title: "payroll.salaryPayment.payrollInbox.title",
        key: "payroll:salaryPayment:payrollInbox",
        pathName: PAYROLL_INBOX_PATH_NAME,
        absolutePath: `${PAYROLL_BASE_ROUTE}/${PAYROLL_SALARY_PAYMENT_PATH_NAME}/${PAYROLL_INBOX_PATH_NAME}`,
        order: 1,
      },
      approvals: {
        title: "payroll.salaryPayment.approvals.title",
        key: "payroll:salaryPayment:approvals",
        pathName: PAYROLL_APPROVALS_PATH_NAME,
        absolutePath: `${PAYROLL_BASE_ROUTE}/${PAYROLL_SALARY_PAYMENT_PATH_NAME}/${PAYROLL_APPROVALS_PATH_NAME}`,
        order: 2,
        all: {
          pathName: PAYMENT_APPROVALS_ALL_PATH_NAME,
          absolutePath: `${PAYROLL_BASE_ROUTE}/${PAYROLL_SALARY_PAYMENT_PATH_NAME}/${PAYROLL_APPROVALS_PATH_NAME}/${PAYROLL_APPROVALS_ALL_PATH_NAME}`,
        },
        pending: {
          pathName: PAYMENT_APPROVALS_PENDING_PATH_NAME,
          absolutePath: `${PAYROLL_BASE_ROUTE}/${PAYROLL_SALARY_PAYMENT_PATH_NAME}/${PAYROLL_APPROVALS_PATH_NAME}/${PAYROLL_APPROVALS_PENDING_PATH_NAME}`,
        },
        needsApproval: {
          pathName: PAYMENT_APPROVALS_NEEDS_APPROVAL_PATH_NAME,
          absolutePath: `${PAYROLL_BASE_ROUTE}/${PAYROLL_SALARY_PAYMENT_PATH_NAME}/${PAYROLL_APPROVALS_PATH_NAME}/${PAYROLL_APPROVALS_NEEDS_APPROVAL_PATH_NAME}`,
        },
      },
      payments: {
        title: "payroll.salaryPayment.payments.title",
        key: "payroll:salaryPayment:payments",
        pathName: PAYROLL_PAYMENT_PATH_NAME,
        absolutePath: `${PAYROLL_BASE_ROUTE}/${PAYROLL_SALARY_PAYMENT_PATH_NAME}/${PAYROLL_PAYMENT_PATH_NAME}`,
        order: 3,
        all: {
          pathName: PAYROLL_PAYMENTS_ALL_PATH_NAME,
          absolutePath: `${PAYROLL_BASE_ROUTE}/${PAYROLL_SALARY_PAYMENT_PATH_NAME}/${PAYROLL_PAYMENT_PATH_NAME}/${PAYROLL_PAYMENTS_ALL_PATH_NAME}`,
        },
        failed: {
          pathName: PAYROLL_PAYMENTS_FAILED_PATH_NAME,
          absolutePath: `${PAYROLL_BASE_ROUTE}/${PAYROLL_SALARY_PAYMENT_PATH_NAME}/${PAYROLL_PAYMENT_PATH_NAME}/${PAYROLL_PAYMENTS_FAILED_PATH_NAME}`,
        },
      },
      paid: {
        title: "payroll.salaryPayment.paid.title",
        key: "payroll:salaryPayment:paid",
        pathName: PAYROLL_PAID_PATH_NAME,
        absolutePath: `${PAYROLL_BASE_ROUTE}/${PAYROLL_SALARY_PAYMENT_PATH_NAME}/${PAYROLL_PAID_PATH_NAME}`,
        order: 4,
      },
    },
    payrollEmployees: {
      title: "payroll.payrollEmployees.title",
      key: "payroll:payrollEmployees",
      pathName: PAYROLL_EMPLOYEES_PATH_NAME,
      absolutePath: `${PAYROLL_BASE_ROUTE}/${PAYROLL_EMPLOYEES_PATH_NAME}`,
      order: 2,
    },
    addBankDetails: {
      absolutePath: `${ADD_BANK_DETAILS_PAYROLL_PATH_NAME}`,
    },
    exchangeRate: {
      title: "payroll.exchangeRate.title",
      key: "payroll:exchangeRate",
      pathName: PAYROLL_EXCHANGE_RATE_PATH_NAME,
      absolutePath: `${PAYROLL_BASE_ROUTE}/${PAYROLL_EXCHANGE_RATE_PATH_NAME}`,
      order: 3,
    },
    settings: {
      title: "payroll.payrollSettings.title",
      key: "payroll:settings",
      pathName: PAYROLL_SETTINGS_PATH_NAME,
      absolutePath: `${PAYROLL_BASE_ROUTE}/${PAYROLL_SETTINGS_PATH_NAME}`,
      order: 4,
      general: {
        pathName: PAYROLL_SETTINGS_GENERAL_PATH_NAME,
        absolutePath: `${PAYROLL_BASE_ROUTE}/${PAYROLL_SETTINGS_PATH_NAME}/${PAYROLL_SETTINGS_GENERAL_PATH_NAME}`,
      },
      spendControls: {
        pathName: PAYROLL_SETTINGS_SPEND_CONTROLS_PATH_NAME,
        absolutePath: `${PAYROLL_BASE_ROUTE}/${PAYROLL_SETTINGS_PATH_NAME}/${PAYROLL_SETTINGS_SPEND_CONTROLS_PATH_NAME}`,
      },
    },
  },

  analytics: {
    base: {
      title: "analytics.title",
      icon: "Analytics",
      key: "analytics",
      absolutePath: ANALYTICS_BASE_ROUTE,
      order: 10,
    },
    transactions: {
      title: "accounting.transactions.title",
      key: "analytics:transactions",
      pathName: ANALYTICS_TRANSACTIONS_PATH_NAME,
      order: 1,
      absolutePath: `${ANALYTICS_BASE_ROUTE}/${ANALYTICS_TRANSACTIONS_PATH_NAME}`,
      cards: {
        title: "accounting.transactions.cards.title",
        key: "accounting:transactions:cards",
        pathName: ANALYTICS_TRANSACTIONS_CARDS_PATH_NAME,
        order: 1,
        absolutePath: `${ANALYTICS_BASE_ROUTE}/${ANALYTICS_TRANSACTIONS_PATH_NAME}/${ANALYTICS_TRANSACTIONS_CARDS_PATH_NAME}`,
      },
      billpay: {
        title: "accounting.transactions.billPay.title",
        key: "analytics:transactions:billpay",
        pathName: ANALYTICS_TRANSACTIONS_BILLPAY_PATH_NAME,
        order: 2,
        absolutePath: `${ANALYTICS_BASE_ROUTE}/${ANALYTICS_TRANSACTIONS_PATH_NAME}/${ANALYTICS_TRANSACTIONS_BILLPAY_PATH_NAME}`,
      },
      reimbursement: {
        title: "accounting.transactions.reimbursement.title",
        key: "analytics:transactions:reimbursement",
        order: 3,
        pathName: ANALYTICS_TRANSACTIONS_REIMBURSEMENT_PATH_NAME,
        absolutePath: `${ANALYTICS_BASE_ROUTE}/${ANALYTICS_TRANSACTIONS_PATH_NAME}/${ANALYTICS_TRANSACTIONS_REIMBURSEMENT_PATH_NAME}`,
      },
      others: {
        title: "accounting.transactions.others.title",
        key: "analytics:transactions:others",
        order: 4,
        pathName: ANALYTICS_TRANSACTIONS_OTHERS_PATH_NAME,
        absolutePath: `${ANALYTICS_BASE_ROUTE}/${ANALYTICS_TRANSACTIONS_PATH_NAME}/${ANALYTICS_TRANSACTIONS_OTHERS_PATH_NAME}`,
      },
      qrPay: {
        title: "qrPay.title",
        key: "analytics:transactions:qrpay",
        order: 5,
        pathName: ANALYTICS_TRANSACTIONS_QR_PAY_PATH_NAME,
        absolutePath: `${ANALYTICS_BASE_ROUTE}/${ANALYTICS_TRANSACTIONS_PATH_NAME}/${ANALYTICS_TRANSACTIONS_QR_PAY_PATH_NAME}`,
      },
      payroll: {
        title: "accounting.transactions.payroll.title",
        key: "analytics:transactions:payroll",
        order: 6,
        pathName: ANALYTICS_TRANSACTIONS_PAYROLL_PATH_NAME,
        absolutePath: `${ANALYTICS_BASE_ROUTE}/${ANALYTICS_TRANSACTIONS_PATH_NAME}/${ANALYTICS_TRANSACTIONS_PAYROLL_PATH_NAME}`,
      },
    },

    ledger: {
      title: "accounting.ledger.title",
      key: "analytics:ledger",
      order: 2,
      pathName: ANALYTICS_LEDGER_PATH_NAME,
      absolutePath: `${ANALYTICS_BASE_ROUTE}/${ANALYTICS_LEDGER_PATH_NAME}`,
      credit: {
        pathName: ANALYTICS_LEDGER_TAB_PATH_NAME.CREDIT,
        absolutePath: `${ANALYTICS_BASE_ROUTE}/${ANALYTICS_LEDGER_PATH_NAME}/${ANALYTICS_LEDGER_TAB_PATH_NAME.CREDIT}`,
      },
      sgd: {
        pathName: ANALYTICS_LEDGER_TAB_PATH_NAME.SGD,
        absolutePath: `${ANALYTICS_BASE_ROUTE}/${ANALYTICS_LEDGER_PATH_NAME}/${ANALYTICS_LEDGER_TAB_PATH_NAME.SGD}`,
      },
      aud: {
        pathName: ANALYTICS_LEDGER_TAB_PATH_NAME.AUD,
        absolutePath: `${ANALYTICS_BASE_ROUTE}/${ANALYTICS_LEDGER_PATH_NAME}/${ANALYTICS_LEDGER_TAB_PATH_NAME.AUD}`,
      },
      inr: {
        pathName: ANALYTICS_LEDGER_TAB_PATH_NAME.INR,
        absolutePath: `${ANALYTICS_BASE_ROUTE}/${ANALYTICS_LEDGER_PATH_NAME}/${ANALYTICS_LEDGER_TAB_PATH_NAME.INR}`,
      },
      idr: {
        pathName: ANALYTICS_LEDGER_TAB_PATH_NAME.IDR,
        absolutePath: `${ANALYTICS_BASE_ROUTE}/${ANALYTICS_LEDGER_PATH_NAME}/${ANALYTICS_LEDGER_TAB_PATH_NAME.IDR}`,
      },
      usd: {
        pathName: ANALYTICS_LEDGER_TAB_PATH_NAME.USD,
        absolutePath: `${ANALYTICS_BASE_ROUTE}/${ANALYTICS_LEDGER_PATH_NAME}/${ANALYTICS_LEDGER_TAB_PATH_NAME.USD}`,
      },
    },
  },
  accounting: {
    base: {
      title: "accounting.title",
      icon: "Accounting",
      key: "accounting",
      absolutePath: ACCOUNTING_BASE_ROUTE,
      order: 8,
    },
    transactions: {
      title: "accounting.transactions.title",
      key: "accounting:transactions",
      pathName: ACCOUNTING_TRANSACTIONS_PATH_NAME,
      absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}`,
      order: 1,
      cards: {
        title: "accounting.transactions.cards.title",
        key: "accounting:transactions:cards",
        pathName: ACCOUNTING_TRANSACTIONS_CARDS_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_CARDS_PATH_NAME}`,
        order: 1,
        all: {
          pathName: ACCOUNTING_TRANSACTIONS_CARDS_ALL_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_CARDS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_CARDS_ALL_PATH_NAME}`,
        },
        pending: {
          pathName: ACCOUNTING_TRANSACTIONS_CARDS_PENDING_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_CARDS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_CARDS_PENDING_PATH_NAME}`,
        },
        synced: {
          pathName: ACCOUNTING_TRANSACTIONS_CARDS_SYNCED_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_CARDS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_CARDS_SYNCED_PATH_NAME}`,
        },
      },
      billpay: {
        title: "accounting.transactions.billPay.title",
        key: "accounting:transactions:billpay",
        pathName: ACCOUNTING_TRANSACTIONS_BILLPAY_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_BILLPAY_PATH_NAME}`,
        order: 2,
        all: {
          pathName: ACCOUNTING_TRANSACTIONS_ALL_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_BILLPAY_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_ALL_PATH_NAME}`,
        },
        pending: {
          pathName: ACCOUNTING_TRANSACTIONS_PENDING_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_BILLPAY_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_PENDING_PATH_NAME}`,
        },
        synced: {
          pathName: ACCOUNTING_TRANSACTIONS_SYNCED_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_BILLPAY_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_SYNCED_PATH_NAME}`,
        },
      },
      reimbursement: {
        title: "accounting.transactions.reimbursement.title",
        key: "accounting:transactions:reimbursement",
        pathName: ACCOUNTING_TRANSACTIONS_REMIMBURSEMENT_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_REMIMBURSEMENT_PATH_NAME}`,
        order: 3,
        all: {
          pathName: ACCOUNTING_TRANSACTIONS_ALL_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_REMIMBURSEMENT_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_ALL_PATH_NAME}`,
        },
        pending: {
          pathName: ACCOUNTING_TRANSACTIONS_PENDING_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_REMIMBURSEMENT_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_PENDING_PATH_NAME}`,
        },
        synced: {
          pathName: ACCOUNTING_TRANSACTIONS_SYNCED_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_REMIMBURSEMENT_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_SYNCED_PATH_NAME}`,
        },
      },
      payroll: {
        title: "accounting.transactions.payroll.title",
        key: "accounting:transactions:payroll",
        pathName: ACCOUNTING_TRANSACTIONS_PAYROLL_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_PAYROLL_PATH_NAME}`,
        order: 4,
        all: {
          pathName: ACCOUNTING_TRANSACTIONS_ALL_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_PAYROLL_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_ALL_PATH_NAME}`,
        },
        pending: {
          pathName: ACCOUNTING_TRANSACTIONS_PENDING_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_PAYROLL_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_PENDING_PATH_NAME}`,
        },
        synced: {
          pathName: ACCOUNTING_TRANSACTIONS_SYNCED_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_PAYROLL_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_SYNCED_PATH_NAME}`,
        },
      },
      others: {
        title: "accounting.transactions.others.title",
        key: "accounting:transactions:others",
        pathName: ACCOUNTING_TRANSACTIONS_OTHERS_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_OTHERS_PATH_NAME}`,
        order: 6,
        all: {
          pathName: ACCOUNTING_TRANSACTIONS_ALL_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_OTHERS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_ALL_PATH_NAME}`,
        },
        pending: {
          pathName: ACCOUNTING_TRANSACTIONS_PENDING_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_OTHERS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_PENDING_PATH_NAME}`,
        },
        synced: {
          pathName: ACCOUNTING_TRANSACTIONS_SYNCED_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_OTHERS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_SYNCED_PATH_NAME}`,
        },
      },
      qrpay: {
        title: "qrPay.title",
        key: "accounting:transactions:qrpay",
        pathName: ACCOUNTING_TRANSACTIONS_QR_PAY_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_QR_PAY_PATH_NAME}`,
        order: 5,
        all: {
          pathName: ACCOUNTING_TRANSACTIONS_ALL_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_QR_PAY_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_ALL_PATH_NAME}`,
        },
        pending: {
          pathName: ACCOUNTING_TRANSACTIONS_PENDING_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_QR_PAY_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_PENDING_PATH_NAME}`,
        },
        synced: {
          pathName: ACCOUNTING_TRANSACTIONS_SYNCED_PATH_NAME,
          absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_TRANSACTIONS_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_QR_PAY_PATH_NAME}/${ACCOUNTING_TRANSACTIONS_SYNCED_PATH_NAME}`,
        },
      },
    },
    ledger: {
      title: "accounting.ledger.title",
      key: "accounting:ledger",
      pathName: ACCOUNTING_LEDGER_PATH_NAME,
      absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}`,
      order: 2,
      credit: {
        pathName: ACCOUNTING_LEDGER_CREDIT_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_CREDIT_PATH_NAME}`,
      },
      payroll: {
        pathName: ACCOUNTING_LEDGER_PAYROLL_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_PAYROLL_PATH_NAME}`,
      },
      cards: {
        pathName: ACCOUNTING_LEDGER_CARDS_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_CARDS_PATH_NAME}`,
      },
      internationalCards: {
        pathName: ACCOUNTING_LEDGER_INTERNATIONAL_CARDS_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_INTERNATIONAL_CARDS_PATH_NAME}`,
      },
      payments: {
        pathName: ACCOUNTING_LEDGER_PAYMENTS_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_PAYMENTS_PATH_NAME}`,
      },
      sgd: {
        pathName: ACCOUNTING_LEDGER_SGD_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_SGD_PATH_NAME}`,
      },
      aud: {
        pathName: ACCOUNTING_LEDGER_SGD_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_AUD_PATH_NAME}`,
      },
      inr: {
        pathName: ACCOUNTING_LEDGER_SGD_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_INR_PATH_NAME}`,
      },
      idr: {
        pathName: ACCOUNTING_LEDGER_SGD_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_IDR_PATH_NAME}`,
      },
      usd: {
        pathName: ACCOUNTING_LEDGER_USD_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_USD_PATH_NAME}`,
      },
      cny: {
        pathName: ACCOUNTING_LEDGER_SGD_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_CNY_PATH_NAME}`,
      },
      cad: {
        pathName: ACCOUNTING_LEDGER_SGD_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_CAD_PATH_NAME}`,
      },
      gbd: {
        pathName: ACCOUNTING_LEDGER_SGD_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_GBD_PATH_NAME}`,
      },
      hkd: {
        pathName: ACCOUNTING_LEDGER_SGD_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_HKD_PATH_NAME}`,
      },
      jpy: {
        pathName: ACCOUNTING_LEDGER_SGD_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_JPY_PATH_NAME}`,
      },
      nzd: {
        pathName: ACCOUNTING_LEDGER_SGD_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_NZD_PATH_NAME}`,
      },
      chf: {
        pathName: ACCOUNTING_LEDGER_SGD_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_CHF_PATH_NAME}`,
      },
      eur: {
        pathName: ACCOUNTING_LEDGER_SGD_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_EUR_PATH_NAME}`,
      },
      all: {
        pathName: ACCOUNTING_LEDGER_ALL_PATH_NAME,
        absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_LEDGER_PATH_NAME}/${ACCOUNTING_LEDGER_ALL_PATH_NAME}`,
      },
    },
    integrations: {
      title: "accounting.integrations.title",
      key: "accounting:integrations",
      pathName: ACCOUNTING_INTEGRATIONS_PATH_NAME,
      absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_INTEGRATIONS_PATH_NAME}`,
      order: 3,
    },
    xero: {
      pathName: ACCOUNTING_INTEGRATIONS_XERO_PATH_NAME,
      absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_INTEGRATIONS_PATH_NAME}/${ACCOUNTING_INTEGRATIONS_XERO_PATH_NAME}`,
    },
    tally: {
      pathName: ACCOUNTING_INTEGRATIONS_TALLY_PATH_NAME,
      absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_INTEGRATIONS_PATH_NAME}/${ACCOUNTING_INTEGRATIONS_TALLY_PATH_NAME}`,
    },
    accountingRules: {
      pathName: ACCOUNTING_RULES_PATH_NAME,
      absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_RULES_PATH_NAME}`,
    },
    billing: {
      pathName: ACCOUNTING_BILLING_PATH_NAME,
      absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_BILLING_PATH_NAME}`,
    },
    settings: {
      title: "accounting.settings.title",
      key: "accounting:settings",
      pathName: ACCOUNTING_SETTINGS_PATH_NAME,
      absolutePath: `${ACCOUNTING_BASE_ROUTE}/${ACCOUNTING_SETTINGS_PATH_NAME}`,
      order: 4,
    },
  },
  manage: {
    base: {
      title: "settings.manage",
      icon: "PageInfo",
      key: "manage",
      absolutePath: MANAGE_BASE_ROUTE,
      order: 9,
    },
    company: {
      title: "common.company",
      key: "manage:company",
      pathName: COMPANY_PATH_NAME,
      absolutePath: `${MANAGE_BASE_ROUTE}/${COMPANY_PATH_NAME}`,
      order: 1,
      departments: {
        title: "company.department.title",
        key: "manage:company:departments",
        pathName: COMPANY_DEPARTMENTS_PATH_NAME,
        absolutePath: `${MANAGE_BASE_ROUTE}/${COMPANY_PATH_NAME}/${COMPANY_DEPARTMENTS_PATH_NAME}`,
        order: 1,
      },
      projects: {
        title: "company.project.title",
        key: "manage:company:projects",
        pathName: COMPANY_PROJECT_PATH_NAME,
        absolutePath: `${MANAGE_BASE_ROUTE}/${COMPANY_PATH_NAME}/${COMPANY_PROJECT_PATH_NAME}`,
        order: 2,
      },
      budgets: {
        title: "company.budgets.title",
        key: "manage:company:budgets",
        pathName: COMPANY_BUDGET_PATH_NAME,
        absolutePath: `${MANAGE_BASE_ROUTE}/${COMPANY_PATH_NAME}/${COMPANY_BUDGET_PATH_NAME}`,
        order: 3,
        "departments-budgets": {
          pathName: COMPANY_DEPATMENT_BUDGETS_PATH_NAME,
          absolutePath: `${MANAGE_BASE_ROUTE}/${COMPANY_PATH_NAME}/${COMPANY_BUDGET_PATH_NAME}/${COMPANY_DEPATMENT_BUDGETS_PATH_NAME}`,
        },
        "project-budgets": {
          pathName: COMPANY_PROJECT_BUDGETS_PATH_NAME,
          absolutePath: `${MANAGE_BASE_ROUTE}/${COMPANY_PATH_NAME}/${COMPANY_BUDGET_PATH_NAME}/${COMPANY_PROJECT_BUDGETS_PATH_NAME}`,
        },
      },
      locations: {
        title: "company.location.title",
        key: "manage:company:locations",
        pathName: COMPANY_LOCATION_PATH_NAME,
        absolutePath: `${MANAGE_BASE_ROUTE}/${COMPANY_PATH_NAME}/${COMPANY_LOCATION_PATH_NAME}`,
        order: 4,
      },
    },
    people: {
      title: "company.people.title",
      key: "manage:people",
      pathName: PEOPLE_PATH_NAME,
      absolutePath: `${MANAGE_BASE_ROUTE}/${PEOPLE_PATH_NAME}`,
      order: 2,
      users: {
        title: "Users",
        key: "manage:people:users",
        pathName: USERS_PATH_NAME,
        absolutePath: `${MANAGE_BASE_ROUTE}/${PEOPLE_PATH_NAME}/${USERS_PATH_NAME}`,
        order: 1,
        all: {
          pathName: USERS_ALL_PATH_NAME,
          absolutePath: `${MANAGE_BASE_ROUTE}/${PEOPLE_PATH_NAME}/${USERS_PATH_NAME}/${USERS_ALL_PATH_NAME}`,
        },
        blocked: {
          pathName: USERS_BLOCKED_PATH_NAME,
          absolutePath: `${MANAGE_BASE_ROUTE}/${PEOPLE_PATH_NAME}/${USERS_PATH_NAME}/${USERS_BLOCKED_PATH_NAME}`,
        },
      },
      rolesPermissions: {
        title: "Roles & permissions",
        key: "manage:people:rolesPermissions",
        pathName: ROLES_PERMISSIONS_PATH_NAME,
        absolutePath: `${MANAGE_BASE_ROUTE}/${PEOPLE_PATH_NAME}/${ROLES_PERMISSIONS_PATH_NAME}`,
        order: 2,
      },
    },
    travel: {
      title: "company.travel.title",
      key: "manage:travel",
      pathName: TRAVEL_PATH_NAME,
      absolutePath: `${MANAGE_BASE_ROUTE}/${TRAVEL_PATH_NAME}`,
      order: 3,
    },
    billing: {
      title: "company.billing.title",
      key: "manage:billing",
      pathName: BILLING_PATH_NAME,
      absolutePath: `${MANAGE_BASE_ROUTE}/${BILLING_PATH_NAME}`,
      order: 4,
    },
    masterHistory: {
      title: "settings.masterHistory.title",
      key: "manage:masterHistory",
      pathName: MASTER_HISTORY_PATHNAME,
      absolutePath: `${MANAGE_BASE_ROUTE}/${MASTER_HISTORY_PATHNAME}`,
      order: 5,
    },
    settings: {
      title: "settings.manageType.settings",
      key: "manage:settings",
      pathName: SETTINGS_PATH_NAME,
      absolutePath: `${MANAGE_BASE_ROUTE}/${SETTINGS_PATH_NAME}/${SETTINGS_SPEND_CONTROLS}`,
      order: 6,
      spendControls: {
        pathName: SETTINGS_SPEND_CONTROLS,
        absolutePath: `${MANAGE_BASE_ROUTE}/${SETTINGS_PATH_NAME}/${SETTINGS_SPEND_CONTROLS}`,
      },
      alerts: {
        pathName: SETTINGS_ALERTS,
        absolutePath: `${MANAGE_BASE_ROUTE}/${SETTINGS_PATH_NAME}/${SETTINGS_ALERTS}`,
      },
      developers: {
        pathName: SETTINGS_DEVELOPER,
        absolutePath: `${MANAGE_BASE_ROUTE}/${SETTINGS_PATH_NAME}/${SETTINGS_DEVELOPER}`,
      },
    },
  },
  notifications: {
    pathName: NOTIFICATIONS_BASE_ROUTE,
    absolutePath: NOTIFICATIONS_BASE_ROUTE,
    personalLevel: {
      pathName: NOTIFICATIONS_PERSONAL_LEVEL_PATHNAME,
      absolutePath: `${NOTIFICATIONS_BASE_ROUTE}/${NOTIFICATIONS_PERSONAL_LEVEL_PATHNAME}`,
    },
    companyLevel: {
      pathName: NOTIFICATIONS_COMPANY_LEVEL_PATHNAME,
      absolutePath: `${NOTIFICATIONS_BASE_ROUTE}/${NOTIFICATIONS_COMPANY_LEVEL_PATHNAME}`,
    },
  },
  exports: {
    base: {
      absolutePath: EXPORTS_BASE_ROUTES,
    },
  },
  files: {
    base: {
      absolutePath: FILES_BASE_ROUTES,
    },
  },
  QRPay: {
    base: {
      title: "QRPay.title",
      icon: "QrCode",
      key: "QRPay",
      absolutePath: QRPAY_BASE_ROUTE,
      order: 6,
    },
    qrPayments: {
      title: "Payments",
      key: "QRPay:qrPayments",
      absolutePath: `${QRPAY_BASE_ROUTE}/${QR_PAYMENTS_BASE_ROUTE}`,
      pathName: QR_PAYMENTS_BASE_ROUTE,
      order: 1,
      overview: {
        title: "navigation.overview",
        key: "QRPay:qrPayments:overview",
        pathName: OVERVIEW_PATH_NAME,
        absolutePath: `${QRPAY_BASE_ROUTE}/${QR_PAYMENTS_BASE_ROUTE}/${OVERVIEW_PATH_NAME}`,
        order: 1,
      },
      needsReview: {
        title: "navigation.needsReview",
        key: "QRPay:qrPayments:needsReview",
        pathName: QR_PAYMENTS_NEEDS_REVIEW_PATH_NAME,
        absolutePath: `${QRPAY_BASE_ROUTE}/${QR_PAYMENTS_BASE_ROUTE}/${QR_PAYMENTS_NEEDS_REVIEW_PATH_NAME}`,
        order: 2,
      },
      flagged: {
        title: "navigation.flagged",
        key: "QRPay:qrPayments:flagged",
        pathName: QR_PAYMENTS_FLAGGED_PATH_NAME,
        absolutePath: `${QRPAY_BASE_ROUTE}/${QR_PAYMENTS_BASE_ROUTE}/${QR_PAYMENTS_FLAGGED_PATH_NAME}`,
        order: 3,
      },
      failures: {
        title: "navigation.failures",
        key: "QRPay:qrPayments:failures",
        pathName: QR_PAYMENTS_FAILURES_PATH_NAME,
        absolutePath: `${QRPAY_BASE_ROUTE}/${QR_PAYMENTS_BASE_ROUTE}/${QR_PAYMENTS_FAILURES_PATH_NAME}`,
        order: 4,
      },
    },
    userWallets: {
      title: "QRPay.navigationLabels.userWallets",
      key: "QRPay:userWallets",
      pathName: USER_WALLETS_PATH_NAME,
      absolutePath: `${QRPAY_BASE_ROUTE}/${USER_WALLETS_PATH_NAME}`,
      order: 2,
      all: {
        pathName: USER_WALLETS_ALL_PATH_NAME,
        absolutePath: `${QRPAY_BASE_ROUTE}/${USER_WALLETS_PATH_NAME}/${USER_WALLETS_ALL_PATH_NAME}`,
      },
      yetToActivate: {
        pathName: USER_WALLETS_YET_TO_ACTIVATE_PATH_NAME,
        absolutePath: `${QRPAY_BASE_ROUTE}/${USER_WALLETS_PATH_NAME}/${USER_WALLETS_YET_TO_ACTIVATE_PATH_NAME}`,
      },
    },
    requests: {
      title: "QRPay.navigationLabels.requests",
      key: "QRPay:requests",
      pathName: REQUESTS_PATH_NAME,
      absolutePath: `${QRPAY_BASE_ROUTE}/${REQUESTS_PATH_NAME}`,
      order: 3,
    },
    settings: {
      title: "QRPay.navigationLabels.settings",
      key: "QRPay:settings",
      pathName: SETTINGS_PATH_NAME,
      absolutePath: `${QRPAY_BASE_ROUTE}/${SETTINGS_PATH_NAME}`,
      order: 4,
      general: {
        pathName: QRPAY_SETTINGS_GENERAL,
        absolutePath: `${QRPAY_BASE_ROUTE}/${SETTINGS_PATH_NAME}/${QRPAY_SETTINGS_GENERAL}`,
      },
      spendControl: {
        pathName: QRPAY_SETTINGS_SPEND_CONTROL,
        absolutePath: `${QRPAY_BASE_ROUTE}/${SETTINGS_PATH_NAME}/${QRPAY_SETTINGS_GENERAL}`,
      },
    },
  },
};

export const PUBLIC_LAYOUTS = {
  [ROUTES.auth.acceptInvitation]: ROUTES.auth.acceptInvitation,
  [ROUTES.login.base.absolutePath]: ROUTES.login.base.absolutePath,
  [ROUTES.companyNotFound.base.absolutePath]:
    ROUTES.companyNotFound.base.absolutePath,
  [ROUTES.twoFactor.base.absolutePath]: ROUTES.twoFactor.base.absolutePath,
  [ROUTES.loginExceeded.base.absolutePath]:
    ROUTES.loginExceeded.base.absolutePath,
  [ROUTES.forgotPassword.base.absolutePath]:
    ROUTES.forgotPassword.base.absolutePath,
  [ROUTES.forgotPassword.verifyInbox.absolutePath]:
    ROUTES.forgotPassword.verifyInbox.absolutePath,
  [ROUTES.accountLocked.absolutePath]: ROUTES.accountLocked.absolutePath,
  [ROUTES.resetPassword.base.absolutePath]:
    ROUTES.resetPassword.base.absolutePath,
  [ROUTES.resetPassword.base.absolutePath]:
    ROUTES.resetPassword.base.absolutePath,
  [ROUTES.inactivityLogout.base.absolutePath]:
    ROUTES.inactivityLogout.base.absolutePath,
  [ROUTES.oauthLogin.base.absolutePath]:
    `${ROUTES.oauthLogin.base.absolutePath}`,
};
