import { ACTION_CENTRE_PAGE_CONTEXT } from "@/constants/ActionCentre";

export const CURRENCYOBJ = {
  AED: {
    value: "AED",
    label: "AED",
    icon: "AED",
  },
  AFN: {
    value: "AFN",
    label: "AFN",
    icon: "AFN",
  },
  ALL: {
    value: "ALL",
    label: "ALL",
    icon: "ALL",
  },
  AMD: {
    value: "AMD",
    label: "AMD",
    icon: "AMD",
  },
  ANG: {
    value: "ANG",
    label: "ANG",
    icon: "ANG",
  },
  AOA: {
    value: "AOA",
    label: "AOA",
    icon: "AOA",
  },
  ARS: {
    value: "ARS",
    label: "ARS",
    icon: "ARS",
  },
  AUD: {
    value: "AUD",
    label: "AUD",
    icon: "AUD",
  },
  AWG: {
    value: "AWG",
    label: "AWG",
    icon: "AWG",
  },
  AZN: {
    value: "AZN",
    label: "AZN",
    icon: "AZN",
  },
  BAM: {
    value: "BAM",
    label: "BAM",
    icon: "BAM",
  },
  BBD: {
    value: "BBD",
    label: "BBD",
    icon: "BBD",
  },
  BDT: {
    value: "BDT",
    label: "BDT",
    icon: "BDT",
  },
  BGN: {
    value: "BGN",
    label: "BGN",
    icon: "BGN",
  },
  BHD: {
    value: "BHD",
    label: "BHD",
    icon: "BHD",
  },
  BIF: {
    value: "BIF",
    label: "BIF",
    icon: "BIF",
  },
  BMD: {
    value: "BMD",
    label: "BMD",
    icon: "BMD",
  },
  BND: {
    value: "BND",
    label: "BND",
    icon: "BND",
  },
  BOB: {
    value: "BOB",
    label: "BOB",
    icon: "BOB",
  },
  BRL: {
    value: "BRL",
    label: "BRL",
    icon: "BRL",
  },
  BSD: {
    value: "BSD",
    label: "BSD",
    icon: "BSD",
  },
  BTN: {
    value: "BTN",
    label: "BTN",
    icon: "BTN",
  },
  BWP: {
    value: "BWP",
    label: "BWP",
    icon: "BWP",
  },
  BYN: {
    value: "BYN",
    label: "BYN",
    icon: "BYN",
  },
  BYR: {
    value: "BYR",
    label: "BYR",
    icon: "BYR",
  },
  BZD: {
    value: "BZD",
    label: "BZD",
    icon: "BZD",
  },
  CAD: {
    value: "CAD",
    label: "CAD",
    icon: "CAD",
  },
  CDF: {
    value: "CDF",
    label: "CDF",
    icon: "CDF",
  },
  CHF: {
    value: "CHF",
    label: "CHF",
    icon: "CHF",
  },
  CLF: {
    value: "CLF",
    label: "CLF",
    icon: "CLF",
  },
  CLP: {
    value: "CLP",
    label: "CLP",
    icon: "CLP",
  },
  CNY: {
    value: "CNY",
    label: "CNY",
    icon: "CNY",
  },
  COP: {
    value: "COP",
    label: "COP",
    icon: "COP",
  },
  CRC: {
    value: "CRC",
    label: "CRC",
    icon: "CRC",
  },
  CUC: {
    value: "CUC",
    label: "CUC",
    icon: "CUC",
  },
  CUP: {
    value: "CUP",
    label: "CUP",
    icon: "CUP",
  },
  CVE: {
    value: "CVE",
    label: "CVE",
    icon: "CVE",
  },
  CZK: {
    value: "CZK",
    label: "CZK",
    icon: "CZK",
  },
  DJF: {
    value: "DJF",
    label: "DJF",
    icon: "DJF",
  },
  DKK: {
    value: "DKK",
    label: "DKK",
    icon: "DKK",
  },
  DOP: {
    value: "DOP",
    label: "DOP",
    icon: "DOP",
  },
  DZD: {
    value: "DZD",
    label: "DZD",
    icon: "DZD",
  },
  EGP: {
    value: "EGP",
    label: "EGP",
    icon: "EGP",
  },
  ERN: {
    value: "ERN",
    label: "ERN",
    icon: "ERN",
  },
  ETB: {
    value: "ETB",
    label: "ETB",
    icon: "ETB",
  },
  EUR: {
    value: "EUR",
    label: "EUR",
    icon: "EUR",
  },
  FJD: {
    value: "FJD",
    label: "FJD",
    icon: "FJD",
  },
  FKP: {
    value: "FKP",
    label: "FKP",
    icon: "FKP",
  },
  GBP: {
    value: "GBP",
    label: "GBP",
    icon: "GBP",
  },
  GEL: {
    value: "GEL",
    label: "GEL",
    icon: "GEL",
  },
  GHS: {
    value: "GHS",
    label: "GHS",
    icon: "GHS",
  },
  GIP: {
    value: "GIP",
    label: "GIP",
    icon: "GIP",
  },
  GMD: {
    value: "GMD",
    label: "GMD",
    icon: "GMD",
  },
  GNF: {
    value: "GNF",
    label: "GNF",
    icon: "GNF",
  },
  GTQ: {
    value: "GTQ",
    label: "GTQ",
    icon: "GTQ",
  },
  GYD: {
    value: "GYD",
    label: "GYD",
    icon: "GYD",
  },
  HKD: {
    value: "HKD",
    label: "HKD",
    icon: "HKD",
  },
  HNL: {
    value: "HNL",
    label: "HNL",
    icon: "HNL",
  },
  HRK: {
    value: "HRK",
    label: "HRK",
    icon: "HRK",
  },
  HTG: {
    value: "HTG",
    label: "HTG",
    icon: "HTG",
  },
  HUF: {
    value: "HUF",
    label: "HUF",
    icon: "HUF",
  },
  IDR: {
    value: "IDR",
    label: "IDR",
    icon: "IDR",
  },
  ILS: {
    value: "ILS",
    label: "ILS",
    icon: "ILS",
  },
  INR: {
    value: "INR",
    label: "INR",
    icon: "INR",
  },
  IQD: {
    value: "IQD",
    label: "IQD",
    icon: "IQD",
  },
  IRR: {
    value: "IRR",
    label: "IRR",
    icon: "IRR",
  },
  ISK: {
    value: "ISK",
    label: "ISK",
    icon: "ISK",
  },
  JMD: {
    value: "JMD",
    label: "JMD",
    icon: "JMD",
  },
  JOD: {
    value: "JOD",
    label: "JOD",
    icon: "JOD",
  },
  JPY: {
    value: "JPY",
    label: "JPY",
    icon: "JPY",
  },
  KES: {
    value: "KES",
    label: "KES",
    icon: "KES",
  },
  KGS: {
    value: "KGS",
    label: "KGS",
    icon: "KGS",
  },
  KHR: {
    value: "KHR",
    label: "KHR",
    icon: "KHR",
  },
  KMF: {
    value: "KMF",
    label: "KMF",
    icon: "KMF",
  },
  KPW: {
    value: "KPW",
    label: "KPW",
    icon: "KPW",
  },
  KRW: {
    value: "KRW",
    label: "KRW",
    icon: "KRW",
  },
  KWD: {
    value: "KWD",
    label: "KWD",
    icon: "KWD",
  },
  KYD: {
    value: "KYD",
    label: "KYD",
    icon: "KYD",
  },
  KZT: {
    value: "KZT",
    label: "KZT",
    icon: "KZT",
  },
  LAK: {
    value: "LAK",
    label: "LAK",
    icon: "LAK",
  },
  LBP: {
    value: "LBP",
    label: "LBP",
    icon: "LBP",
  },
  LKR: {
    value: "LKR",
    label: "LKR",
    icon: "LKR",
  },
  LRD: {
    value: "LRD",
    label: "LRD",
    icon: "LRD",
  },
  LSL: {
    value: "LSL",
    label: "LSL",
    icon: "LSL",
  },
  LYD: {
    value: "LYD",
    label: "LYD",
    icon: "LYD",
  },
  MAD: {
    value: "MAD",
    label: "MAD",
    icon: "MAD",
  },
  MDL: {
    value: "MDL",
    label: "MDL",
    icon: "MDL",
  },
  MGA: {
    value: "MGA",
    label: "MGA",
    icon: "MGA",
  },
  MKD: {
    value: "MKD",
    label: "MKD",
    icon: "MKD",
  },
  MMK: {
    value: "MMK",
    label: "MMK",
    icon: "MMK",
  },
  MNT: {
    value: "MNT",
    label: "MNT",
    icon: "MNT",
  },
  MOP: {
    value: "MOP",
    label: "MOP",
    icon: "MOP",
  },
  MRO: {
    value: "MRO",
    label: "MRO",
    icon: "MRO",
  },
  MUR: {
    value: "MUR",
    label: "MUR",
    icon: "MUR",
  },
  MVR: {
    value: "MVR",
    label: "MVR",
    icon: "MVR",
  },
  MWK: {
    value: "MWK",
    label: "MWK",
    icon: "MWK",
  },
  MXN: {
    value: "MXN",
    label: "MXN",
    icon: "MXN",
  },
  MXV: {
    value: "MXV",
    label: "MXV",
    icon: "MXV",
  },
  MYR: {
    value: "MYR",
    label: "MYR",
    icon: "MYR",
  },
  MZN: {
    value: "MZN",
    label: "MZN",
    icon: "MZN",
  },
  NAD: {
    value: "NAD",
    label: "NAD",
    icon: "NAD",
  },
  NGN: {
    value: "NGN",
    label: "NGN",
    icon: "NGN",
  },
  NIO: {
    value: "NIO",
    label: "NIO",
    icon: "NIO",
  },
  NOK: {
    value: "NOK",
    label: "NOK",
    icon: "NOK",
  },
  NPR: {
    value: "NPR",
    label: "NPR",
    icon: "NPR",
  },
  NZD: {
    value: "NZD",
    label: "NZD",
    icon: "NZD",
  },
  OMR: {
    value: "OMR",
    label: "OMR",
    icon: "OMR",
  },
  PAB: {
    value: "PAB",
    label: "PAB",
    icon: "PAB",
  },
  PEN: {
    value: "PEN",
    label: "PEN",
    icon: "PEN",
  },
  PGK: {
    value: "PGK",
    label: "PGK",
    icon: "PGK",
  },
  PHP: {
    value: "PHP",
    label: "PHP",
    icon: "PHP",
  },
  PKR: {
    value: "PKR",
    label: "PKR",
    icon: "PKR",
  },
  PLN: {
    value: "PLN",
    label: "PLN",
    icon: "PLN",
  },
  PYG: {
    value: "PYG",
    label: "PYG",
    icon: "PYG",
  },
  QAR: {
    value: "QAR",
    label: "QAR",
    icon: "QAR",
  },
  RON: {
    value: "RON",
    label: "RON",
    icon: "RON",
  },
  RSD: {
    value: "RSD",
    label: "RSD",
    icon: "RSD",
  },
  RUB: {
    value: "RUB",
    label: "RUB",
    icon: "RUB",
  },
  RWF: {
    value: "RWF",
    label: "RWF",
    icon: "RWF",
  },
  SAR: {
    value: "SAR",
    label: "SAR",
    icon: "SAR",
  },
  SBD: {
    value: "SBD",
    label: "SBD",
    icon: "SBD",
  },
  SCR: {
    value: "SCR",
    label: "SCR",
    icon: "SCR",
  },
  SDG: {
    value: "SDG",
    label: "SDG",
    icon: "SDG",
  },
  // SEK: {
  //   value: "SEK",
  //   label: "SEK",
  //   icon: "SEK",
  // },
  SGD: {
    value: "SGD",
    label: "SGD",
    icon: "SGD",
  },
  SHP: {
    value: "SHP",
    label: "SHP",
    icon: "SHP",
  },
  SLL: {
    value: "SLL",
    label: "SLL",
    icon: "SLL",
  },
  SOS: {
    value: "SOS",
    label: "SOS",
    icon: "SOS",
  },
  SRD: {
    value: "SRD",
    label: "SRD",
    icon: "SRD",
  },
  SSP: {
    value: "SSP",
    label: "SSP",
    icon: "SSP",
  },
  STD: {
    value: "STD",
    label: "STD",
    icon: "STD",
  },
  SVC: {
    value: "SVC",
    label: "SVC",
    icon: "SVC",
  },
  SYP: {
    value: "SYP",
    label: "SYP",
    icon: "SYP",
  },
  SZL: {
    value: "SZL",
    label: "SZL",
    icon: "SZL",
  },
  THB: {
    value: "THB",
    label: "THB",
    icon: "THB",
  },
  TJS: {
    value: "TJS",
    label: "TJS",
    icon: "TJS",
  },
  TMT: {
    value: "TMT",
    label: "TMT",
    icon: "TMT",
  },
  TND: {
    value: "TND",
    label: "TND",
    icon: "TND",
  },
  TOP: {
    value: "TOP",
    label: "TOP",
    icon: "TOP",
  },
  TRY: {
    value: "TRY",
    label: "TRY",
    icon: "TRY",
  },
  TTD: {
    value: "TTD",
    label: "TTD",
    icon: "TTD",
  },
  TWD: {
    value: "TWD",
    label: "TWD",
    icon: "TWD",
  },
  TZS: {
    value: "TZS",
    label: "TZS",
    icon: "TZS",
  },
  UAH: {
    value: "UAH",
    label: "UAH",
    icon: "UAH",
  },
  UGX: {
    value: "UGX",
    label: "UGX",
    icon: "UGX",
  },
  USD: {
    value: "USD",
    label: "USD",
    icon: "USD",
  },
  UYI: {
    value: "UYI",
    label: "UYI",
    icon: "UYI",
  },
  UYU: {
    value: "UYU",
    label: "UYU",
    icon: "UYU",
  },
  UZS: {
    value: "UZS",
    label: "UZS",
    icon: "UZS",
  },
  VEF: {
    value: "VEF",
    label: "VEF",
    icon: "VEF",
  },
  VND: {
    value: "VND",
    label: "VND",
    icon: "VND",
  },
  VUV: {
    value: "VUV",
    label: "VUV",
    icon: "VUV",
  },
  WST: {
    value: "WST",
    label: "WST",
    icon: "WST",
  },
  XAF: {
    value: "XAF",
    label: "XAF",
    icon: "XAF",
  },
  XCD: {
    value: "XCD",
    label: "XCD",
    icon: "XCD",
  },
  XOF: {
    value: "XOF",
    label: "XOF",
    icon: "XOF",
  },
  XPF: {
    value: "XPF",
    label: "XPF",
    icon: "XPF",
  },
  XXX: {
    value: "XXX",
    label: "XXX",
    icon: "XXX",
  },
  YER: {
    value: "YER",
    label: "YER",
    icon: "YER",
  },
  ZAR: {
    value: "ZAR",
    label: "ZAR",
    icon: "ZAR",
  },
  ZMW: {
    value: "ZMW",
    label: "ZMW",
    icon: "ZMW",
  },
  ZWL: {
    value: "ZWL",
    label: "ZWL",
    icon: "ZWL",
  },
};
export const CURRENCY_ARRAY = [
  {
    value: "AED",
    label: "AED",
    icon: "AED",
  },
  {
    value: "AFN",
    label: "AFN",
    icon: "AFN",
  },
  {
    value: "ALL",
    label: "ALL",
    icon: "ALL",
  },
  {
    value: "AMD",
    label: "AMD",
    icon: "AMD",
  },
  {
    value: "ANG",
    label: "ANG",
    icon: "ANG",
  },
  {
    value: "AOA",
    label: "AOA",
    icon: "AOA",
  },
  {
    value: "ARS",
    label: "ARS",
    icon: "ARS",
  },
  {
    value: "AUD",
    label: "AUD",
    icon: "AUD",
  },
  {
    value: "AWG",
    label: "AWG",
    icon: "AWG",
  },
  {
    value: "AZN",
    label: "AZN",
    icon: "AZN",
  },
  {
    value: "BAM",
    label: "BAM",
    icon: "BAM",
  },
  {
    value: "BBD",
    label: "BBD",
    icon: "BBD",
  },
  {
    value: "BDT",
    label: "BDT",
    icon: "BDT",
  },
  {
    value: "BGN",
    label: "BGN",
    icon: "BGN",
  },
  {
    value: "BHD",
    label: "BHD",
    icon: "BHD",
  },
  {
    value: "BIF",
    label: "BIF",
    icon: "BIF",
  },
  {
    value: "BMD",
    label: "BMD",
    icon: "BMD",
  },
  {
    value: "BND",
    label: "BND",
    icon: "BND",
  },
  {
    value: "BOB",
    label: "BOB",
    icon: "BOB",
  },
  {
    value: "BRL",
    label: "BRL",
    icon: "BRL",
  },
  {
    value: "BSD",
    label: "BSD",
    icon: "BSD",
  },
  {
    value: "BTN",
    label: "BTN",
    icon: "BTN",
  },
  {
    value: "BWP",
    label: "BWP",
    icon: "BWP",
  },
  // {
  //   value: "BYN",
  //   label: "BYN",
  //   icon: "BYN",
  // },
  {
    value: "BYR",
    label: "BYR",
    icon: "BYR",
  },
  {
    value: "BZD",
    label: "BZD",
    icon: "BZD",
  },
  {
    value: "CAD",
    label: "CAD",
    icon: "CAD",
  },
  {
    value: "CDF",
    label: "CDF",
    icon: "CDF",
  },
  {
    value: "CHF",
    label: "CHF",
    icon: "CHF",
  },
  // {
  //   value: "CLF",
  //   label: "CLF",
  //   icon: "CLF",
  // },
  {
    value: "CLP",
    label: "CLP",
    icon: "CLP",
  },
  {
    value: "CNY",
    label: "CNY",
    icon: "CNY",
  },
  {
    value: "COP",
    label: "COP",
    icon: "COP",
  },
  {
    value: "CRC",
    label: "CRC",
    icon: "CRC",
  },
  // {
  //   value: "CUC",
  //   label: "CUC",
  //   icon: "CUC",
  // },
  {
    value: "CUP",
    label: "CUP",
    icon: "CUP",
  },
  {
    value: "CVE",
    label: "CVE",
    icon: "CVE",
  },
  {
    value: "CZK",
    label: "CZK",
    icon: "CZK",
  },
  {
    value: "DJF",
    label: "DJF",
    icon: "DJF",
  },
  {
    value: "DKK",
    label: "DKK",
    icon: "DKK",
  },
  {
    value: "DOP",
    label: "DOP",
    icon: "DOP",
  },
  {
    value: "DZD",
    label: "DZD",
    icon: "DZD",
  },
  {
    value: "EGP",
    label: "EGP",
    icon: "EGP",
  },
  {
    value: "ERN",
    label: "ERN",
    icon: "ERN",
  },
  {
    value: "ETB",
    label: "ETB",
    icon: "ETB",
  },
  {
    value: "EUR",
    label: "EUR",
    icon: "EUR",
  },
  {
    value: "FJD",
    label: "FJD",
    icon: "FJD",
  },
  {
    value: "FKP",
    label: "FKP",
    icon: "FKP",
  },
  {
    value: "GBP",
    label: "GBP",
    icon: "GBP",
  },
  {
    value: "GEL",
    label: "GEL",
    icon: "GEL",
  },
  {
    value: "GHS",
    label: "GHS",
    icon: "GHS",
  },
  {
    value: "GIP",
    label: "GIP",
    icon: "GIP",
  },
  {
    value: "GMD",
    label: "GMD",
    icon: "GMD",
  },
  {
    value: "GNF",
    label: "GNF",
    icon: "GNF",
  },
  {
    value: "GTQ",
    label: "GTQ",
    icon: "GTQ",
  },
  {
    value: "GYD",
    label: "GYD",
    icon: "GYD",
  },
  {
    value: "HKD",
    label: "HKD",
    icon: "HKD",
  },
  {
    value: "HNL",
    label: "HNL",
    icon: "HNL",
  },
  {
    value: "HRK",
    label: "HRK",
    icon: "HRK",
  },
  {
    value: "HTG",
    label: "HTG",
    icon: "HTG",
  },
  {
    value: "HUF",
    label: "HUF",
    icon: "HUF",
  },
  {
    value: "IDR",
    label: "IDR",
    icon: "IDR",
  },
  {
    value: "ILS",
    label: "ILS",
    icon: "ILS",
  },
  {
    value: "INR",
    label: "INR",
    icon: "INR",
  },
  {
    value: "IQD",
    label: "IQD",
    icon: "IQD",
  },
  {
    value: "IRR",
    label: "IRR",
    icon: "IRR",
  },
  {
    value: "ISK",
    label: "ISK",
    icon: "ISK",
  },
  {
    value: "JMD",
    label: "JMD",
    icon: "JMD",
  },
  {
    value: "JOD",
    label: "JOD",
    icon: "JOD",
  },
  {
    value: "JPY",
    label: "JPY",
    icon: "JPY",
  },
  {
    value: "KES",
    label: "KES",
    icon: "KES",
  },
  {
    value: "KGS",
    label: "KGS",
    icon: "KGS",
  },
  {
    value: "KHR",
    label: "KHR",
    icon: "KHR",
  },
  {
    value: "KMF",
    label: "KMF",
    icon: "KMF",
  },
  {
    value: "KPW",
    label: "KPW",
    icon: "KPW",
  },
  {
    value: "KRW",
    label: "KRW",
    icon: "KRW",
  },
  {
    value: "KWD",
    label: "KWD",
    icon: "KWD",
  },
  {
    value: "KYD",
    label: "KYD",
    icon: "KYD",
  },
  {
    value: "KZT",
    label: "KZT",
    icon: "KZT",
  },
  {
    value: "LAK",
    label: "LAK",
    icon: "LAK",
  },
  {
    value: "LBP",
    label: "LBP",
    icon: "LBP",
  },
  {
    value: "LKR",
    label: "LKR",
    icon: "LKR",
  },
  {
    value: "LRD",
    label: "LRD",
    icon: "LRD",
  },
  {
    value: "LSL",
    label: "LSL",
    icon: "LSL",
  },
  {
    value: "LYD",
    label: "LYD",
    icon: "LYD",
  },
  {
    value: "MAD",
    label: "MAD",
    icon: "MAD",
  },
  {
    value: "MDL",
    label: "MDL",
    icon: "MDL",
  },
  {
    value: "MGA",
    label: "MGA",
    icon: "MGA",
  },
  {
    value: "MKD",
    label: "MKD",
    icon: "MKD",
  },
  {
    value: "MMK",
    label: "MMK",
    icon: "MMK",
  },
  {
    value: "MNT",
    label: "MNT",
    icon: "MNT",
  },
  {
    value: "MOP",
    label: "MOP",
    icon: "MOP",
  },
  {
    value: "MRO",
    label: "MRO",
    icon: "MRO",
  },
  {
    value: "MUR",
    label: "MUR",
    icon: "MUR",
  },
  {
    value: "MVR",
    label: "MVR",
    icon: "MVR",
  },
  {
    value: "MWK",
    label: "MWK",
    icon: "MWK",
  },
  {
    value: "MXN",
    label: "MXN",
    icon: "MXN",
  },
  // {
  //   value: "MXV",
  //   label: "MXV",
  //   icon: "MXV",
  // },
  {
    value: "MYR",
    label: "MYR",
    icon: "MYR",
  },
  {
    value: "MZN",
    label: "MZN",
    icon: "MZN",
  },
  {
    value: "NAD",
    label: "NAD",
    icon: "NAD",
  },
  {
    value: "NGN",
    label: "NGN",
    icon: "NGN",
  },
  {
    value: "NIO",
    label: "NIO",
    icon: "NIO",
  },
  {
    value: "NOK",
    label: "NOK",
    icon: "NOK",
  },
  {
    value: "NPR",
    label: "NPR",
    icon: "NPR",
  },
  {
    value: "NZD",
    label: "NZD",
    icon: "NZD",
  },
  {
    value: "OMR",
    label: "OMR",
    icon: "OMR",
  },
  {
    value: "PAB",
    label: "PAB",
    icon: "PAB",
  },
  {
    value: "PEN",
    label: "PEN",
    icon: "PEN",
  },
  {
    value: "PGK",
    label: "PGK",
    icon: "PGK",
  },
  {
    value: "PHP",
    label: "PHP",
    icon: "PHP",
  },
  {
    value: "PKR",
    label: "PKR",
    icon: "PKR",
  },
  {
    value: "PLN",
    label: "PLN",
    icon: "PLN",
  },
  {
    value: "PYG",
    label: "PYG",
    icon: "PYG",
  },
  {
    value: "QAR",
    label: "QAR",
    icon: "QAR",
  },
  {
    value: "RON",
    label: "RON",
    icon: "RON",
  },
  {
    value: "RSD",
    label: "RSD",
    icon: "RSD",
  },
  {
    value: "RUB",
    label: "RUB",
    icon: "RUB",
  },
  {
    value: "RWF",
    label: "RWF",
    icon: "RWF",
  },
  {
    value: "SAR",
    label: "SAR",
    icon: "SAR",
  },
  {
    value: "SBD",
    label: "SBD",
    icon: "SBD",
  },
  {
    value: "SCR",
    label: "SCR",
    icon: "SCR",
  },
  {
    value: "SDG",
    label: "SDG",
    icon: "SDG",
  },
  // {
  //   value: "SEK",
  //   label: "SEK",
  //   icon: "SEK",
  // },
  {
    value: "SGD",
    label: "SGD",
    icon: "SGD",
  },
  {
    value: "SHP",
    label: "SHP",
    icon: "SHP",
  },
  {
    value: "SLL",
    label: "SLL",
    icon: "SLL",
  },
  {
    value: "SOS",
    label: "SOS",
    icon: "SOS",
  },
  {
    value: "SRD",
    label: "SRD",
    icon: "SRD",
  },
  {
    value: "SSP",
    label: "SSP",
    icon: "SSP",
  },
  {
    value: "STD",
    label: "STD",
    icon: "STD",
  },
  // {
  //   value: "SVC",
  //   label: "SVC",
  //   icon: "SVC",
  // },
  {
    value: "SYP",
    label: "SYP",
    icon: "SYP",
  },
  {
    value: "SZL",
    label: "SZL",
    icon: "SZL",
  },
  {
    value: "THB",
    label: "THB",
    icon: "THB",
  },
  {
    value: "TJS",
    label: "TJS",
    icon: "TJS",
  },
  {
    value: "TMT",
    label: "TMT",
    icon: "TMT",
  },
  {
    value: "TND",
    label: "TND",
    icon: "TND",
  },
  {
    value: "TOP",
    label: "TOP",
    icon: "TOP",
  },
  {
    value: "TRY",
    label: "TRY",
    icon: "TRY",
  },
  {
    value: "TTD",
    label: "TTD",
    icon: "TTD",
  },
  {
    value: "TWD",
    label: "TWD",
    icon: "TWD",
  },
  {
    value: "TZS",
    label: "TZS",
    icon: "TZS",
  },
  {
    value: "UAH",
    label: "UAH",
    icon: "UAH",
  },
  {
    value: "UGX",
    label: "UGX",
    icon: "UGX",
  },
  {
    value: "USD",
    label: "USD",
    icon: "USD",
  },
  // {
  //   value: "UYI",
  //   label: "UYI",
  //   icon: "UYI",
  // },
  {
    value: "UYU",
    label: "UYU",
    icon: "UYU",
  },
  {
    value: "UZS",
    label: "UZS",
    icon: "UZS",
  },
  {
    value: "VEF",
    label: "VEF",
    icon: "VEF",
  },
  {
    value: "VND",
    label: "VND",
    icon: "VND",
  },
  {
    value: "VUV",
    label: "VUV",
    icon: "VUV",
  },
  {
    value: "WST",
    label: "WST",
    icon: "WST",
  },
  {
    value: "XAF",
    label: "XAF",
    icon: "XAF",
  },
  {
    value: "XCD",
    label: "XCD",
    icon: "XCD",
  },
  {
    value: "XOF",
    label: "XOF",
    icon: "XOF",
  },
  {
    value: "XPF",
    label: "XPF",
    icon: "XPF",
  },
  // {
  //   value: "XXX",
  //   label: "XXX",
  //   icon: "XXX",
  // },
  {
    value: "YER",
    label: "YER",
    icon: "YER",
  },
  {
    value: "ZAR",
    label: "ZAR",
    icon: "ZAR",
  },
  // {
  //   value: "ZMW",
  //   label: "ZMW",
  //   icon: "ZMW",
  // },
  {
    value: "ZWL",
    label: "ZWL",
    icon: "ZWL",
  },
];
export const PAGE_TYPE = {
  "reimbursement.tabTitles.needsYourAction": "needsYourAction",
  "reimbursement.tabTitles.pending": "pending",
  "reimbursement.tabTitles.all": "all",
};
export const REIMBURSEMENT_KEY = "reimbursement";
export const REIMBURSEMENT_PAGE_LABEL_TYPE_MAPPING = {
  "reimbursement.tabTitles.needsYourAction": REIMBURSEMENT_KEY,
  "reimbursement.tabTitles.pending": REIMBURSEMENT_KEY,
  "reimbursement.tabTitles.all": REIMBURSEMENT_KEY,
  "myVolopay.actionCentre.reimbursementKey":
    ACTION_CENTRE_PAGE_CONTEXT.REIMBURSEMT,
  "myVolopay.actionCentre.reimbursementReportKey":
    ACTION_CENTRE_PAGE_CONTEXT.REPORT,
};

export const REPORT_KEY = "report";
export const REPORT_PAGE_LABEL_TYPE_MAPPING = {
  "reimbursement.tabTitles.needsYourAction": REPORT_KEY,
  "reimbursement.tabTitles.pending": REPORT_KEY,
  "reimbursement.tabTitles.all": REPORT_KEY,
  "myVolopay.actionCentre.reimbursementReportKey":
    ACTION_CENTRE_PAGE_CONTEXT.REPORT,
};

export const API_END_PAGE_TYPE = {
  needsYourAction: "needsyourAction",
  pending: "pending",
  all: "all",
  active: "active",
  history: "history",
};

export const TAB = {
  needsYourAction: "needs-your-approval",
  pending: "pending",
  all: "all",
};

export const BUTTON_TYPE = {
  cancel: "cancel",
  submit: "submit",
};
export const APPROVAL_TABS = {
  needsYourAction: "needsyourAction",
  othersAction: "others-action",
  rejected: "rejected",
};

export const CATEGORY = [
  {
    id: 1,
    label: "Shoes",
    value: "shoes",
    show: true,
  },
  {
    id: 2,
    value: "Cloths",
    label: "Cloths",
    show: true,
  },
  {
    id: 3,
    value: "Software",
    label: "Software",
    show: true,
  },
  {
    id: 4,
    value: "Office Expenses",
    label: "Office Expenses",
    show: true,
  },
  {
    id: 5,
    value: "Food Expenses",
    label: "Food Expenses",
    show: true,
  },
  {
    id: 6,
    value: "Travel Expenses",
    label: "Travel Expenses",
    show: true,
  },
];
export const CONST_TYPE_REIMBURSEMENT = {
  outOfPocket: "out_of_pocket",
  mileage: "mileage",
};

export const REIMBURSEMENT_TYPE_LABEL = {
  [CONST_TYPE_REIMBURSEMENT.outOfPocket]:
    "reimbursement.createReimbursement.type.outOfPocket.title",
  [CONST_TYPE_REIMBURSEMENT.mileage]:
    "reimbursement.createReimbursement.type.mileaage.title",
};
export const TYPE_REIMBURSEMENT = {
  out_of_pocket: "Out of pocket",
  mileage: "Mileage",
};
export const PAID_SELECTED_TABS = [
  {
    name: "myVolopay.reimbursements.childNav.claims",
    count: null,
    key: "1",
  },
  {
    name: "myVolopay.reimbursements.childNav.paymentHistory",
    count: null,
    key: "2",
  },
];

export const REPORT_PAID_SELECTED_TABS = [
  {
    name: "myVolopay.reimbursements.childNav.reports",
    count: null,
    key: "1",
  },
  {
    name: "myVolopay.reimbursements.childNav.paymentHistory",
    count: null,
    key: "2",
  },
];

export const PAYMENT_MODES = {
  payViaVolopay: "paid_via_volopay",
  paid: "paid",
  payOutsideVolopay: "paid_outside_volopay",
  settleViaCard: "settled_via_card",
};

export const REIMBURSEMENT_STATUS = {
  approved: "approved",
  rejected: "rejected",
  paid: "paid",
  settled: "settled",
  in_draft: "in_draft",
  pending_approval: "pending_approval",
  processing: "processing",
  denied: "denied",
  paid_outside_volopay: "paid_outside_volopay",
  settled_via_card: "settled_via_card",
  failed: "failed",
  pending: "pending",
};

export const REPORT_STATUS = {
  approved: "approved",
  rejected: "rejected",
  paid: "paid",
  settled: "settled",
  in_draft: "in_draft",
  pending_approval: "pending_approval",
  processing: "processing",
  denied: "denied",
  paid_outside_volopay: "paid",
  failed: "failed",
};

export const PAID_STATUS = {
  [PAYMENT_MODES.payViaVolopay]:
    "billPay.bill.payments.paymentFooter.paymentBadgeText.paid",
  [PAYMENT_MODES.paid]:
    "billPay.bill.payments.paymentFooter.paymentBadgeText.paid",
  [PAYMENT_MODES.payOutsideVolopay]:
    "billPay.bill.payments.paymentFooter.paymentBadgeText.outsideVolopay",
  [REIMBURSEMENT_STATUS.failed]:
    "billPay.bill.payments.paymentFooter.paymentBadgeText.failed",
  [REIMBURSEMENT_STATUS.settled_via_card]:
    "reimbursement.transactionStatus.settledViaCard",
};

export const REIMBURSEMENT_TOASTER_DATA = {
  approved: {
    description: "reimbursement.toasterData.approved.description",
  },
  rejected: {
    title: "reimbursement.toasterData.rejected.title",
    description: "reimbursement.toasterData.rejected.description",
    variant: "danger",
  },
};
export const CREATE_REIMBURSEMENT_TYPE = {
  outOfPocket: {
    name: "reimbursement.createReimbursement.type.outOfPocket.title",
    value: CONST_TYPE_REIMBURSEMENT.outOfPocket,
    description:
      "reimbursement.createReimbursement.type.outOfPocket.description",
  },
  mileage: {
    name: "reimbursement.createReimbursement.type.mileaage.title",
    value: CONST_TYPE_REIMBURSEMENT.mileage,
    description: "reimbursement.createReimbursement.type.mileaage.description",
    dataFor: "mileage-tooltip",
    dataTip: true,
  },
};

export const CREATE_REIMBURSEMENT_TYPE_ARRAY = [
  {
    name: "reimbursement.createReimbursement.type.outOfPocket.title",
    value: CONST_TYPE_REIMBURSEMENT.outOfPocket,
    description:
      "reimbursement.createReimbursement.type.outOfPocket.description",
  },
  {
    name: "reimbursement.createReimbursement.type.mileaage.title",
    value: CONST_TYPE_REIMBURSEMENT.mileage,
    description: "reimbursement.createReimbursement.type.mileaage.description",
    dataFor: "mileage-tooltip",
    dataTip: true,
  },
];
const OTHER_PAGE = {
  tableSetting: {
    hheaderSticky: true,
    numberOfStickyColsLeft: 1,
    colWidths: [300, 200, 200, 200, 200, 200, 200, 200],
  },
  headerData: [
    { name: "reimbursement.approvals.listHeading.user", isSortable: false },
    { name: "reimbursement.approvals.listHeading.amount", isSortable: true },
    { name: "reimbursement.approvals.listHeading.type", isSortable: false },
    {
      name: "reimbursement.approvals.listHeading.approval",
      isSortable: false,
    },
    {
      name: "reimbursement.approvals.listHeading.submissionDate",
      isSortable: false,
    },
    {
      name: "reimbursement.approvals.listHeading.transactionDate",
      isSortable: false,
    },
    {
      name: "reimbursement.approvals.listHeading.receipt",
      isSortable: false,
    },
    { name: "reimbursement.approvals.listHeading.memo", isSortable: false },
  ],
};

export const REIMBURSEMENT_TYPE_KEY = {
  out_of_pocket: "OutOfPocket",
  mileage: "Mileage",
};

export const REIMBURSEMENT_PAGE_TYPE = {
  approvals: "approvals",
  payments: "payments",
  paid: "paid",
  drafts: "drafts",
  active: "active",
  history: "history",
};
export const REIMBURSEMENT_CARD = {
  payments: [
    {
      heading: "reimbursement.payments.sliderHeadings.unsettledAmount",
      hasLink: false,
      toolTipText: "reimbursement.payments.sliderHeadings.amountTooltipText",
      hasInfo: true,
      type: "amount",
    },
    {
      heading: "reimbursement.payments.sliderHeadings.unsettledClaims",
      hasLink: false,
      hasInfo: true,
      toolTipText: "reimbursement.payments.sliderHeadings.claimTooltipText",
      type: "noOfClaims",
    },
  ],
  paid: [
    {
      toolTipText:
        "reimbursement.payments.sliderHeadings.paidAmountTooltipText",
      heading: "reimbursement.paid.sliderHeadings.settledAmount",
      hasLink: false,
      hasInfo: true,

      type: "amount",
    },
    {
      toolTipText:
        "reimbursement.payments.sliderHeadings.paidClaimsTootltipText",
      heading: "reimbursement.paid.sliderHeadings.settledClaims",
      hasLink: false,
      hasInfo: true,
      type: "noOfClaims",
    },
  ],
};

export const PAYMENT_CARD_TYPE = {
  amount: "amount",
  noOfClaims: "noOfClaims",
  noOfReports: "noOfReports",
};
export const REPORT_CARD = {
  payments: [
    {
      heading: "reimbursement.payments.sliderHeadings.unsettledAmount",
      hasLink: false,
      toolTipText: "reimbursement.payments.sliderHeadings.amountTooltipText",
      hasInfo: true,
      type: "amount",
    },
    {
      heading: "reimbursement.payments.sliderHeadings.unsettledClaims",
      hasLink: false,
      hasInfo: true,
      toolTipText: "reimbursement.payments.sliderHeadings.claimTooltipText",
      type: "noOfClaims",
    },
    {
      heading: "reimbursement.payments.sliderHeadings.totalReports",
      hasLink: false,
      hasInfo: true,
      toolTipText: "reimbursement.payments.sliderHeadings.reportTooltipText",
      type: "noOfReports",
    },
  ],
  paid: [
    {
      toolTipText:
        "reimbursement.payments.sliderHeadings.paidAmountTooltipText",
      heading: "reimbursement.paid.sliderHeadings.settledAmount",
      hasLink: false,
      hasInfo: true,
      type: "amount",
    },
    {
      toolTipText:
        "reimbursement.payments.sliderHeadings.paidClaimsTootltipText",
      heading: "reimbursement.paid.sliderHeadings.settledClaims",
      hasLink: false,
      hasInfo: true,
      type: "noOfClaims",
    },
    {
      heading: "reimbursement.payments.sliderHeadings.totalReports",
      hasLink: false,
      hasInfo: true,
      toolTipText:
        "reimbursement.payments.sliderHeadings.paidReportTooltipText",
      type: "noOfReports",
    },
  ],
};
const DEFAULT_TABLE_SETTING = {
  headerSticky: true,
  numberOfStickyColsLeft: 1,
  colWidths: [300, 200, 200, 300, 300],
};

export const FOOTER_BUTTONS = [
  {
    label: "reimbursement.createReimbursement.footer.submitForApproval",
    buttonType: BUTTON_TYPE.submit,
    type: "default",
    variant: "primary",
    size: "md",
    classes: "w-18 h-11",
  },
  {
    label: "reimbursement.createReimbursement.footer.cancel",
    type: "default",
    variant: "tertiary",
    buttonType: BUTTON_TYPE.cancel,
    size: "sm",
    classes: "w-16 h-11",
  },
];
export const PAYMENTS = "payments";

export const REIMBURSEMENT_TRANSACTION_STATUS_TO_COLOR_MAP = {
  [REIMBURSEMENT_STATUS.approved]: {
    color: "success",
    text: "reimbursement.transactionStatus.approved",
  },
  [REIMBURSEMENT_STATUS.rejected]: {
    color: "danger",
    text: "reimbursement.transactionStatus.rejected",
  },
  [REIMBURSEMENT_STATUS.paid]: {
    color: "success",
    text: "reimbursement.transactionStatus.paid",
  },
  [REIMBURSEMENT_STATUS.paid_outside_volopay]: {
    color: "success",
    text: "reimbursement.transactionStatus.paid",
  },
  [REIMBURSEMENT_STATUS.settled]: {
    color: "success",
    text: "reimbursement.transactionStatus.paid",
  },
  [REIMBURSEMENT_STATUS.settled_via_card]: {
    color: "success",
    text: "reimbursement.transactionStatus.settledViaCard",
  },
  pending: {
    // design has this, although it makes no sense
    color: "warning",
    text: "pending",
  },
};

export const REIMBURSEMENT_ACTION_PARAMS = {
  TYPE_REIMBURSEMENT: "reimbursement",
  DO: {
    ADD_PAYMENT_DATE: "add_payment_date",
    VERIFIED: "verified",
    SKIP_APPROVALS: "skip_approvals",
    UNSCHEDULE_PAYMENT: "unschedule_payment",
  },
};

export const CLAIM_TIMELINE_ITEM_TYPE = {
  UPLOADED: "uploaded",
  SUBMITTED: "submitted",
  REQUESTED: "requested",
  // approvals
  // uses `status` for variations
  APPROVAL: "approval", // X level approval: Rajith Shaji, X level approval: Pending, Rejected

  // payments
  // uses `status` for variations
  PAYMENT: "payment", // Payment initiated by: Muraru K, variations handled using 'status' - initiated, processing, failed

  // paid
  PAID: "paid", // Paid by: Manjunath K, Paid
  MARKED_AS_PAID: "markedAsPaid", // Marked as Paid by: Payal A

  // bill actions
  PAYMENT_DATE_ADDED: "paymentDateAdded", // Payment date added by AP Clerk: Janhavi B
  PAYMENT_DATE_ADDED_BY_ADMIN: "paymentDateAddedByAdmin", // different icon, otherwise same as "payment date added"

  EDITED: "edited", // Edited by Bill creator: Manjunath Bhat
  // Approvals skipped by Admin: Manjunath K
  RECREATED: "recreated", // Recreated by AP clerk: Murari K
  BILL_RESYNCED: "billReSynced", // Bill resynced by Admin: Murari kumar
  APPROVAL_POLICY_UPDATED: "approvalPolicyUpdated", // Approval policy updated

  // creation platform
  RECEIVED_VIA_EMAIL: "recievedViaEmail", // Received via email: abc@123.com
  UPLOADED_VIA_WHATSAPP: "uploadedViaWhatsApp", // Uploaded via WhatsApp: +91 88373 83842
  UPLOADED_VIA_SLACK: "uploadedViaSlack", // Uploaded via Slack: username@123
};

// for approval levels
export const CLAIM_TIMELINE_ITEM_APPROVAL_STATUS_BASED_UI_CONFIG = {
  approved: {
    iconName: "CircleCheck",
    iconBgColor: "bg-success-100",
    iconStrokeColor: "text-success-600",
  },
  pending: {
    iconName: "Pace",
    iconBgColor: "bg-warning-100",
    iconStrokeColor: "text-warning-500",
  },
  rejected: {
    iconName: "Close",
    iconBgColor: "bg-danger-100",
    iconStrokeColor: "text-danger-500",
  },
  skipped: {
    iconName: "CircleCheck",
    iconBgColor: "bg-success-100",
    iconStrokeColor: "text-success-600",
  },
  paid: {
    iconName: "CircleCheck",
    iconBgColor: "bg-danger-100",
    iconStrokeColor: "text-danger-500",
  },
};

// payment status
export const CLAIM_TIMELINE_ITEM_PAYMENT_STATUS_BASED_UI_CONFIG = {
  initiated: {
    iconName: "BillPayRequests",
    iconBgColor: "bg-warning-50",
    iconStrokeColor: "text-warning-500",
  },
  processing: {
    iconName: "Pace",
    iconBgColor: "bg-warning-50",
    iconStrokeColor: "text-warning-500",
  },
  failed: {
    iconName: "Pace",
    iconBgColor: "bg-danger-50",
    iconStrokeColor: "text-danger-400",
  },
};

// for submitted and created
export const CLAIM_TIMELINE_ITEM_TYPE_BASED_UI_CONFIG = {
  [CLAIM_TIMELINE_ITEM_TYPE.UPLOADED]: {
    // covers: "Uploaded on Volopay by Admin:Murari K"
    iconName: "Upload",
    iconBgColor: "bg-neutral-100",
    iconStrokeColor: "text-neutral-500",
  },
  [CLAIM_TIMELINE_ITEM_TYPE.SUBMITTED]: {
    // covers: "Submitted by AP Clerk: Shameela"
    iconName: "Assignment",
    iconBgColor: "bg-neutral-100",
    iconStrokeColor: "text-neutral-500",
  },

  [CLAIM_TIMELINE_ITEM_TYPE.APPROVAL]: {
    // covers "level approval" of all types, "auto approved"
    // see PAYMENT_TIMELINE_ITEM_APPROVAL_STATUS_BASED_UI_CONFIG
    iconName: null,
    iconBgColor: null,
    iconStrokeColor: null,
    variations: CLAIM_TIMELINE_ITEM_APPROVAL_STATUS_BASED_UI_CONFIG,
  },

  [CLAIM_TIMELINE_ITEM_TYPE.PAYMENT]: {
    // covers "level approval" of all types, "auto approved"
    // see PAYMENT_TIMELINE_ITEM_APPROVAL_STATUS_BASED_UI_CONFIG
    iconName: null,
    iconBgColor: null,
    iconStrokeColor: null,
    variations: CLAIM_TIMELINE_ITEM_PAYMENT_STATUS_BASED_UI_CONFIG,
  },

  [CLAIM_TIMELINE_ITEM_TYPE.PAID]: {
    iconName: "Send",
    iconBgColor: "bg-success-100",
    iconStrokeColor: "text-success-600",
  },
  [CLAIM_TIMELINE_ITEM_TYPE.MARKED_AS_PAID]: {
    iconName: "Send",
    iconBgColor: "bg-success-100",
    iconStrokeColor: "text-success-600",
  },

  [CLAIM_TIMELINE_ITEM_TYPE.PAYMENT_DATE_ADDED]: {
    iconName: "CalendarAddOn",
    iconBgColor: "bg-primary-50",
    iconStrokeColor: "text-primary-500",
  },
  [CLAIM_TIMELINE_ITEM_TYPE.PAYMENT_DATE_ADDED_BY_ADMIN]: {
    iconName: "EditCalendar",
    iconBgColor: "bg-primary-50",
    iconStrokeColor: "text-primary-500",
  },

  [CLAIM_TIMELINE_ITEM_TYPE.EDITED]: {
    iconName: "Edit",
    iconBgColor: "bg-primary-50",
    iconStrokeColor: "text-primary-500",
  },
  [CLAIM_TIMELINE_ITEM_TYPE.RECREATED]: {
    iconName: "Recreated",
    iconBgColor: "bg-primary-50",
    iconStrokeColor: "text-primary-500",
  },
  [CLAIM_TIMELINE_ITEM_TYPE.BILL_RESYNCED]: {
    iconName: "CloudSync",
    iconBgColor: "bg-primary-50",
    iconStrokeColor: "text-primary-500",
  },
  [CLAIM_TIMELINE_ITEM_TYPE.APPROVAL_POLICY_UPDATED]: {
    iconName: "ArmingCountdown",
    iconBgColor: "bg-primary-50",
    iconStrokeColor: "text-primary-500",
  },

  // creation platform
  [CLAIM_TIMELINE_ITEM_TYPE.RECEIVED_VIA_EMAIL]: {
    iconName: "OutgoingMail",
    iconBgColor: "bg-neutral-100",
    iconStrokeColor: "text-neutral-500",
  },
  [CLAIM_TIMELINE_ITEM_TYPE.UPLOADED_VIA_SLACK]: {
    iconName: "Slack",
    iconBgColor: "bg-neutral-100",
    iconStrokeColor: "",
  },
  [CLAIM_TIMELINE_ITEM_TYPE.UPLOADED_VIA_WHATSAPP]: {
    iconName: "WhatsApp",
    iconBgColor: "bg-neutral-100",
    iconStrokeColor: "",
  },
};

export const STATUS_COLORS = {
  paid: {
    color: "success",
    name: "billPay.bill.payments.paymentFooter.sliderStatusText.paid",
  },
  paid_outside_volopay: {
    color: "neutral",
    name: "billPay.bill.payments.paymentFooter.sliderStatusText.outsideVolopay",
  },
  paid_via_card_settlement: {
    color: "success",
    name: "myVolopay.actionCentre.sliders.requestHistorySlider.pills.paidViaCardSettlement",
  },
  failed: {
    color: "danger",
    name: "billPay.bill.payments.paymentFooter.sliderStatusText.failed",
  },
  not_approved: {
    color: "danger",
    name: "billPay.bill.payments.paymentFooter.sliderStatusText.rejected",
  },
  [REIMBURSEMENT_STATUS.settled_via_card]: {
    color: "success",
    name: "reimbursement.transactionStatus.settledViaCard",
  },
};
