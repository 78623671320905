import {
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
  SLIDERS_SEARCH_PARAMS,
} from "@/constants/SearchParams";
import {
  SPEND_FREQUENCY,
  SPEND_FREQUENCY_MODULE_WISE,
} from "@/constants/company";
import { FILE_MIME } from "@/constants/fileViewer";
import { ROUTES } from "@/constants/routes";

export const PAYMENT_ACCOUNT_TYPE = "payments";
export const BE_KEY_PURCHASE_BILL = "purchase_bills";
export const PAGE_TYPE = {
  invoiceInbox: "invoice-inbox",
  approvals: "approvals",
  payments: "payments",
  paid: "paid",
};

export const TYPE_KEY = {
  outsideVolopay: "outsideVolopay",
  unscheduled: "unscheduled",
  failed: "failed",
};

export const CTA_ACTION_KEY_DEPRECATED = {
  markPaid: "markPaid",
  paid: "paid",
  processing: "processing",
};

export const GET_KEY_PAGE_TYPE = {
  all: "all",
  pending: "pending",
  "needs-your-approval": "needsYourApproval",
};

// payment page tabs
export const PAYMENTS_TABS = [
  {
    name: "billPay.bill.payments.childPage.all",
    count: null,
    path: ROUTES.billpay.bill.payments.all.absolutePath,
    key: 1,
    defaultQueryParams: {
      tab: "all",
    },
  },
  {
    name: "billPay.bill.payments.childPage.failed",
    count: null,
    path: ROUTES.billpay.bill.payments.failed.absolutePath,
    key: 2,
    defaultQueryParams: {
      tab: "failed",
    },
  },
];

export const PAYMENTS_PAID_LISTING_PARAMS = { tab: "paid" };

// 'Payment' here means 'Billpay Payments' (as opposed to Approvals)
export const PAYMENT_STATUSES = {
  outsideVolopay: "paid_outside_volopay",
  scheduled: "scheduled",
  unscheduled: "unscheduled",
  insufficientAccountBalance: "insufficientAccountBalance",
  insufficientDepartmentBudget: "insufficientDepartmentBudget",
  insufficientProjectBudget: "insufficientProjectBudget",
  vendorDetailMissing: "vendorDetailMissing",
  processing: "processing",
  pendingToBeTriggered: "pending_to_be_triggered",
  failed: "failed",
  paid: "paid",
  settledViaCard: "settled_via_card",
  synced: "synced",
  approved: "approved",
  unverified: "unverified",
  missing_details: "missing_details",
  awaitingFunds: "awaiting_funds",

  // approval ones
  pending: "pending_approval",
  rejected: "rejected",
  denied: "denied",
  notApproved: "not_approved",

  // pre-approval
  inDraft: "in_draft",
  inPreview: "in_preview",
};

export const PAYMENT_APPROVAL_STATUSES = {
  pending: PAYMENT_STATUSES.pending,
  approved: PAYMENT_STATUSES.approved,
  rejected: PAYMENT_STATUSES.rejected,
  denied: PAYMENT_STATUSES.denied,
  notApproved: PAYMENT_STATUSES.notApproved,
};

export const PAYMENT_APPROVAL_STATUS_TEXT = {
  [PAYMENT_APPROVAL_STATUSES.pending]:
    "billPay.bill.approvals.approvalStatusCard.title.pending",
  [PAYMENT_APPROVAL_STATUSES.approved]:
    "billPay.bill.approvals.approvalStatusCard.title.approved",
  [PAYMENT_APPROVAL_STATUSES.rejected]:
    "billPay.bill.approvals.approvalStatusCard.title.rejected",
};

export const APPROVAL_TOASTER_CONTROL = {
  approved: {
    description: "Request Approved",
  },
  rejected: {
    title: "Failed",
    description: "Request Rejected",
    variant: "danger",
  },
};

export const INVOICE_INBOX_LISTING_PARAMS = { status: "in_draft" };
export const INVOICE_INBOX_RECURRING_LISTING_PARAMS = {
  // only one is valid as per API - either /payrolls or /purchase_bills
  recurring_bills: true,
  recurring_payrolls: true,
  recurring: true,
};

export const PAYMENTS_APPROVALS_TABS = [
  {
    name: "billPay.bill.approvals.childPage.needsYourApproval",
    count: null,
    path: ROUTES.billpay.bill.approvals.needsApproval.absolutePath,
    key: 1,
    defaultQueryParams: {
      tab: "needs-your-approval",
    },
  },
  {
    name: "billPay.bill.approvals.childPage.pending",
    count: null,
    path: ROUTES.billpay.bill.approvals.pending.absolutePath,
    key: 2,
    defaultQueryParams: {
      tab: "pending",
    },
  },
  {
    name: "billPay.bill.approvals.childPage.all",
    count: null,
    path: ROUTES.billpay.bill.approvals.all.absolutePath,
    key: 3,
    defaultQueryParams: {
      tab: "all",
    },
  },
];
// string for outside volopay status
export const OUTSIDE_VOLOPAY = "outside_volopay";
// button keys for cta and options
export const BUTTON_KEYS = {
  editBill: "editBill",
  deleteBill: "deleteBill",
  resyncBill: "resyncBill",
  payNow: "payNow",
  addPaymentDate: "addPaymentDate",
  markAsPaid: "markPaid",
  addFunds: "addFunds",
  addVendor: "addVendor",
  retry: "retry",
};

export const PAYMENT_TOASTER_TEXT = {
  markPaid: "billPay.bill.payments.toasterText.markPaid",
  paid: "billPay.bill.payments.toasterText.paid",
  processing: "billPay.bill.payments.toasterText.processing",
};

// payments colors
const PAYMENT_COLORS = {
  success: "success",
  primary: "primary",
  danger: "danger",
  warning: "warning",
  neutral: "neutral",
  default: "default",
  noColor: "",
};

export const APPROVAL_OR_PAYMENT_STATUS_TEXT = {
  [PAYMENT_STATUSES.outsideVolopay]:
    "billPay.bill.payments.paymentFooter.sliderStatusText.outsideVolopay",
  [PAYMENT_STATUSES.scheduled]:
    "billPay.bill.payments.paymentFooter.sliderStatusText.scheduled",
  [PAYMENT_STATUSES.unscheduled]:
    "billPay.bill.payments.paymentFooter.sliderStatusText.unscheduled",
  [PAYMENT_STATUSES.vendorDetailMissing]:
    "billPay.bill.payments.paymentFooter.sliderStatusText.vendorDetailMissing",
  [PAYMENT_STATUSES.processing]:
    "billPay.bill.payments.paymentFooter.sliderStatusText.processing",
  [PAYMENT_STATUSES.failed]:
    "billPay.bill.payments.paymentFooter.sliderStatusText.failed",
  [PAYMENT_STATUSES.paid]:
    "billPay.bill.payments.paymentFooter.sliderStatusText.paid",
  [PAYMENT_STATUSES.settledViaCard]:
    "billPay.bill.payments.paymentFooter.sliderStatusText.settledViaCard",
  [PAYMENT_STATUSES.insufficientAccountBalance]:
    "billPay.bill.payments.paymentFooter.sliderStatusText.insufficientAccountBalance",
  [PAYMENT_STATUSES.insufficientDepartmentBudget]:
    "billPay.bill.payments.paymentFooter.sliderStatusText.insufficientDepartmentBudget",
  [PAYMENT_STATUSES.insufficientProjectBudget]:
    "billPay.bill.payments.paymentFooter.sliderStatusText.insufficientProjectBudget",
  [PAYMENT_STATUSES.approved]:
    "billPay.bill.payments.paymentFooter.sliderStatusText.approved",
  [PAYMENT_STATUSES.unverified]:
    "billPay.bill.payments.paymentFooter.sliderStatusText.approved",
  [PAYMENT_STATUSES.missing_details]:
    "billPay.bill.payments.paymentFooter.sliderStatusText.missingDetails",
  [PAYMENT_STATUSES.awaitingFunds]:
    "billPay.bill.payments.paymentFooter.sliderStatusText.awaitingFunds",
  // approval ones
  [PAYMENT_STATUSES.pending]:
    "billPay.bill.approvals.approvalStatusCard.title.awaiting",
  [PAYMENT_STATUSES.rejected]:
    "billPay.bill.approvals.approvalStatusCard.title.rejected",
  [PAYMENT_STATUSES.denied]:
    "billPay.bill.approvals.approvalStatusCard.title.rejected",
  [PAYMENT_STATUSES.notApproved]:
    "billPay.bill.approvals.approvalStatusCard.title.rejected",
};

export const APPROVAL_OR_PAYMENT_STATUS_PERCENTAGE = {
  [PAYMENT_STATUSES.outsideVolopay]: null,
  [PAYMENT_STATUSES.scheduled]: 70,
  [PAYMENT_STATUSES.unscheduled]: 50,
  [PAYMENT_STATUSES.vendorDetailMissing]: 60,
  [PAYMENT_STATUSES.processing]: 80,
  [PAYMENT_STATUSES.failed]: 100,
  [PAYMENT_STATUSES.paid]: 100,
  [PAYMENT_STATUSES.insufficientAccountBalance]: 70,
  [PAYMENT_STATUSES.insufficientDepartmentBudget]: 70,
  [PAYMENT_STATUSES.insufficientProjectBudget]: 70,
  [PAYMENT_STATUSES.approved]: 100,
  [PAYMENT_STATUSES.unverified]: 60,

  // approval ones
  [PAYMENT_STATUSES.rejected]: 100,
  [PAYMENT_STATUSES.notApproved]: 100,
  [PAYMENT_STATUSES.denied]: 100,
};

export const APPROVAL_OR_PAYMENT_STATUS_COLOR = {
  [PAYMENT_STATUSES.outsideVolopay]: PAYMENT_COLORS.neutral,
  [PAYMENT_STATUSES.scheduled]: PAYMENT_COLORS.success,
  [PAYMENT_STATUSES.unscheduled]: PAYMENT_COLORS.warning,
  [PAYMENT_STATUSES.insufficientAccountBalance]: PAYMENT_COLORS.warning,
  [PAYMENT_STATUSES.insufficientDepartmentBudget]: PAYMENT_COLORS.warning,
  [PAYMENT_STATUSES.insufficientProjectBudget]: PAYMENT_COLORS.warning,
  [PAYMENT_STATUSES.vendorDetailMissing]: PAYMENT_COLORS.warning,
  [PAYMENT_STATUSES.processing]: PAYMENT_COLORS.warning,
  [PAYMENT_STATUSES.failed]: PAYMENT_COLORS.danger,
  [PAYMENT_STATUSES.paid]: PAYMENT_COLORS.success,
  [PAYMENT_STATUSES.settledViaCard]: PAYMENT_COLORS.success,
  [PAYMENT_STATUSES.approved]: PAYMENT_COLORS.success,
  [PAYMENT_STATUSES.unverified]: PAYMENT_COLORS.noColor,
  [PAYMENT_STATUSES.awaitingFunds]: PAYMENT_COLORS.warning,

  // approval ones
  [PAYMENT_STATUSES.pending]: PAYMENT_COLORS.warning,
  [PAYMENT_STATUSES.rejected]: PAYMENT_COLORS.danger,
  [PAYMENT_STATUSES.denied]: PAYMENT_COLORS.danger,
  [PAYMENT_STATUSES.notApproved]: PAYMENT_COLORS.danger,
};

export const SLIDER_FOOTER_TEXT = {
  [PAYMENT_STATUSES.outsideVolopay]:
    "billPay.bill.payments.paymentFooter.sliderFooterText.outsideVolopay",
  [PAYMENT_STATUSES.scheduled]:
    "billPay.bill.payments.paymentFooter.sliderFooterText.scheduled",
  [PAYMENT_STATUSES.unscheduled]:
    "billPay.bill.payments.paymentFooter.sliderFooterText.unscheduled",
  [PAYMENT_STATUSES.insufficientAccountBalance]:
    "billPay.bill.payments.paymentFooter.sliderFooterText.insufficientAccountBalance",
  [PAYMENT_STATUSES.insufficientDepartmentBudget]:
    "billPay.bill.payments.paymentFooter.sliderFooterText.insufficientDepartmentBudget",
  [PAYMENT_STATUSES.insufficientProjectBudget]:
    "billPay.bill.payments.paymentFooter.sliderFooterText.insufficientProjectBudget",

  [PAYMENT_STATUSES.vendorDetailMissing]:
    "billPay.bill.payments.paymentFooter.sliderFooterText.vendorDetailMissing",
  [PAYMENT_STATUSES.processing]:
    "billPay.bill.payments.paymentFooter.sliderFooterText.processing",
  [PAYMENT_STATUSES.failed]:
    "billPay.bill.payments.paymentFooter.sliderFooterText.failed",
  [PAYMENT_STATUSES.paid]:
    "billPay.bill.payments.paymentFooter.sliderFooterText.paid",
  [PAYMENT_STATUSES.unverified]:
    "billPay.bill.payments.paymentFooter.sliderFooterText.unverified",
  [PAYMENT_STATUSES.awaitingFunds]:
    "billPay.bill.payments.paymentFooter.sliderFooterText.awaitingFunds",
};

export const PAYMENT_LIST_BADGE_TYPES = {
  [PAYMENT_STATUSES.outsideVolopay]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.outsideVolopay",
    icon: null,
    color: PAYMENT_COLORS.neutral,
  },
  [PAYMENT_STATUSES.scheduled]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.scheduled",
    icon: null,
    color: PAYMENT_COLORS.success,
  },
  [PAYMENT_STATUSES.unscheduled]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.unscheduled",
    icon: null,
    color: PAYMENT_COLORS.neutral,
  },
  [PAYMENT_STATUSES.notApproved]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.notApproved",
    icon: null,
    color: PAYMENT_COLORS.danger,
  },

  [PAYMENT_STATUSES.denied]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.notApproved",
    icon: null,
    color: PAYMENT_COLORS.danger,
  },

  [PAYMENT_STATUSES.rejected]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.notApproved",
    icon: null,
    color: PAYMENT_COLORS.danger,
  },
  [PAYMENT_STATUSES.insufficientAccountBalance]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.insufficientAccountBalance",
    icon: null,
    color: PAYMENT_COLORS.neutral,
  },
  [PAYMENT_STATUSES.insufficientDepartmentBudget]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.insufficientDepartmentBudget",
    icon: null,
    color: PAYMENT_COLORS.neutral,
  },
  [PAYMENT_STATUSES.insufficientProjectBudget]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.insufficientProjectBudget",
    icon: null,
    color: PAYMENT_COLORS.neutral,
  },
  [PAYMENT_STATUSES.vendorDetailMissing]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.vendorDetailMissing",
    icon: null,
    color: PAYMENT_COLORS.neutral,
  },
  [PAYMENT_STATUSES.processing]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.processing",
    icon: "Processing",
    color: PAYMENT_COLORS.warning,
  },
  [PAYMENT_STATUSES.pending]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.pending",
    icon: "null",
    color: PAYMENT_COLORS.warning,
  },
  [PAYMENT_STATUSES.failed]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.failed",
    icon: null,
    color: PAYMENT_COLORS.danger,
  },
  [PAYMENT_STATUSES.paid]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.paid",
    icon: null,
    color: PAYMENT_COLORS.success,
  },
  [PAYMENT_STATUSES.synced]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.synced",
    icon: "Done",
    color: PAYMENT_COLORS.success,
  },
  [PAYMENT_STATUSES.unverified]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.unverified",
    icon: null,
    color: PAYMENT_COLORS.neutral,
  },
  [PAYMENT_STATUSES.awaitingFunds]: {
    text: "billPay.bill.payments.paymentFooter.paymentBadgeText.awaitingFunds",
    icon: null,
    color: PAYMENT_COLORS.neutral,
  },
};

// Approval
export const PAYMENTS_APPROVALS_PROGRESS_COLOR = {
  approved: "success",
  pending: "warning",
  rejected: "danger",
};
export const APPROVERS_TYPES = {
  denied: "denied",
  notInApproval: "notInApproval",
  notCurrentApprover: "notCurrentApprover",
  approver: "approver",
};
export const APPROVAL_LIST_BUTTON = {
  denied: {
    actionButton: {
      text: "review",
      classes: "text-neutral-500 bg-neutral-50",
      type: "default",
      action: "rejected",
      variant: "tertiary",
      disabled: false,
    },
  },
  notInApproval: {
    actionButton: null,
  },
  notCurrentApprover: {
    actionButton: {
      text: "reimbursement.approvals.ctaButton.approve",
      action: null,
      classes: "text-neutral-500 bg-neutral-100",
      type: "default",
      variant: "tertiary",
      disabled: true,
    },
  },
  approver: {
    actionButton: {
      text: "reimbursement.approvals.ctaButton.approve",
      action: "approved",
      color: null,
      classes: null,
      type: "success",
      variant: "tertiary",
      disabled: false,
    },
  },
};

export const APPROVAL_SLIDER_CHANGES = {
  approver: {
    buttons: [
      {
        text: "reimbursement.approvals.sliderHeadings.footer.reject",
        action: "rejected",
        type: "danger",
        variant: "tertiary",
      },
      {
        text: "reimbursement.approvals.sliderHeadings.footer.approve",
        type: "success",
        action: "approved",
        variant: "primary",
      },
    ],
    sliderText: "billPay.bill.payments.approvalSlider.paymentWaiting",
  },
  notInApproval: {
    buttons: null,
    sliderText: "billPay.bill.payments.approvalSlider.notApprover",
  },
  notCurrentApprover: {
    buttons: null,
    sliderText: "billPay.bill.payments.approvalSlider.pendingForApproval",
  },
  denied: {
    buttons: null,
    sliderText: "billPay.bill.payments.approvalSlider.paymentDenied",
  },
};

export const PAYMENT_CHANNELS = {
  LOCAL: "LOCAL",
  SWIFT_SHA: "SWIFT_SHA",
  SWIFT_OUR: "SWIFT_OUR",
};

export const PAYMENT_CHANNEL_BE_FE_MAPPER = {
  local: "LOCAL",
  swift_sha: "SHIFT_SHA",
  swift_our: "SWIFT_OUR",
};

export const PAYMENT_CHANNEL_OPTIONS = {
  [PAYMENT_CHANNELS.LOCAL]: {
    value: PAYMENT_CHANNELS.LOCAL,
    name: "NON-SWIFT / LOCAL TRANSFER",
    description:
      "billPay.bill.invoiceInbox.createBill.sections.paymentDetails.paymentChannelDescription.LOCAL",
    icon: "local",
  },
  [PAYMENT_CHANNELS.SWIFT_SHA]: {
    icon: "swiftShared",
    value: PAYMENT_CHANNELS.SWIFT_SHA,
    name: "SWIFT - SHARED",
    description:
      "billPay.bill.invoiceInbox.createBill.sections.paymentDetails.paymentChannelDescription.SWIFT_SHA",
  },
  [PAYMENT_CHANNELS.SWIFT_OUR]: {
    value: PAYMENT_CHANNELS.SWIFT_OUR,
    name: "SWIFT - OUR",
    description:
      "billPay.bill.invoiceInbox.createBill.sections.paymentDetails.paymentChannelDescription.SWIFT_OUR",
    icon: "swiftOwn",
  },
};

export const PURCHASE_BILL = "PurchaseBill";
export const PAYROLL = "payroll";
export const PAYMENTS = "payments";
export const REIMBURSEMENT = "reimbursements";
export const REIMBURSEMENT_LABEL = {
  needsReview: "reimbursement.tabTitles.needsYourAction",
};
export const PAYMENT_APPROVERS_API_QPARAMS = {
  AMOUNT: "amount",
  POLICY_GROUP_TYPE: "policy_group_type",
  PAYMENT_POLICY_GROUP: "payment_policy_group",
  CURRENCY: "currency",
  PROJECT_ID: "project_id",
};

export const NEFT = "NEFT";
export const RTGS = "RTGS";
export const IMPS = "IMPS";
export const UPI = "UPI";

export const DECENTRO_PAYMENT_MODES = {
  NEFT,
  RTGS,
  IMPS,
  UPI,
};

export const PAYMENT_CHANNELS_TYPES = {
  LOCAL: "local",
  SWIFT: "swift",
};

export const APPROVAL_STATUS_TO_PILL_COLOR_MAP = {
  approved: "bg-success-600",
  pending: "bg-warning-500",
  rejected: "bg-danger-500",
  blank: "bg-neutral-200",
  processing: "bg-success-600",
  pending_approval: "bg-success-600",
  okay: "bg-success-600",
  not_okay: "bg-danger-500",
};
export const APPROVAL_STATUS_TO_ICON_MAP = {
  approved: {
    iconName: "Done",
    classes: " bg-success-600",
  },
  pending: {
    iconName: "WarningCircleFill",
    classes: "bg-warning-500",
  },
  rejected: {
    iconName: "Close",
    classes: "bg-danger-500",
  },
};

export const BILLPAY_BILL_CTA_KEYS = {
  // These are shared between listing CTAs and slider CTAs, are generally usable anywhere
  approve: "approve",
  reject: "reject",
  resync: "resync",
  addPaymentDate: "addPaymentDate",
  edit: "edit",
  review: "review",
  create: "create",
  deleteDraft: "deleteDraft",
  unSchedulePayment: "unSchedulePayment",
  skipApprovals: "skipApprovals",
  addFundsAccount: "addFundsAccount",
  addFundsDepartment: "addFundsDepartment",
  addFundsProject: "addFundsProject",
  archive: "archive",
  deleteRecurringPayment: "deleteRecurringPayment",
  payNow: "payNow",
  markAsPaid: "markAsPaid",
  retryPayment: "retryPayment",
  recreatePayment: "recreatePayment",
  createBill: "createBill",

  // FE only CTAS - the following ctas are frontend only ones, BE has no knowledge about them
  // used usually to handle scenarios like duplicate bill checks, balance low alert etc
};

export const REIMBURSEMENT_CTA_KEYS = {
  approve: "approve",
  reject: "reject",
  edit: "editClaim",
  create: "createClaim",
  skipApprovals: "skipApprovals",
  settleClaims: "settleClaims",
  review: "review",
  deleteDraft: "deleteDraft",
  archive: "archive",
  settleViaCard: "settleViaCard", // not used in normal status based ctas, this is for payment mode
};

export const REPORT_CTA_KEYS = {
  review: "review",
  deleteDraft: "deleteDraft",
  approve: "approve",
  reject: "reject",
};

export const BILLPAY_SETTINGS_TABS = [
  {
    name: "billPay.settings.tabs.general",
    count: null,
    path: ROUTES.billpay.settings.general.absolutePath,
    key: 1,
  },
  {
    name: "billPay.settings.tabs.spendControls",
    count: null,
    path: ROUTES.billpay.settings.spendControls.absolutePath,
    key: 2,
  },
];

// this is a flat object of all the settings entries
// settings may be part of a single setting row
// this determines if the setting is available for the user, and is also used for switch value manipulation
export const BILLPAY_SETTING_ENTRIES = {
  AP_CLERKS: "ap-clerks",
  PAYMENT_INITIATION: "payment-initiation",
  AUTOMATED_PAYMENTS: "automated-payments",
  MAKER_CHECKER: "maker-checker",

  APPROVAL_POLICY: "approval-policy",
  CUSTOM_APPROVAL_POLICIES: "custom-approval-policies",
  SUBMISSION_POLICY: "submission-policy",
  CUSTOM_SUBMISSION_POLICIES: "custom-submission-policies",
};
export const PAYROLL_SETTING_ENTRIES = {
  PAYROLL_USERS: "payroll-users",
  ADD_PAYROLL_MANAGERS: "add-payroll-managers",
  ADD_PAYROLL_MEMBERS: "add-payroll-members",
  PAYROLL_CLERKS: "payroll-clerks",
  PAYMENT_INITIATION: "payment-initiation",
  AUTOMATED_PAYMENTS: "payroll-automated-payments",
  APPROVAL_POLICY: "payroll-Approval-policy",
  SUBMISSION_POLICY: "payroll-Submission-policy",
};
export const REIMBURSEMENT_SETTING_ENTRIES = {
  AUTOMATED_PAYMENTS: "automated-payments",
  AUTO_SETTLEMENT: "auto-settlement",
  PAYMENT_INITIATION: "payment-initiation",
  MILEAGE_RATE: "mileage-rate",
  APPROVAL_POLICY: "payroll-Approval-policy",
  CUSTOM_APPROVAL_POLICIES: "custom-approval-policies",
  SUBMISSION_POLICY: "payroll-Submission-policy",
  CUSTOM_SUBMISSION_POLICIES: "custom-submission-policies",
  CLAIM_POLICIES: "Claim-policies",
  VOLOPAY_CATEGORIES: "Volopay categories",
  COMPANY_CLAIM_POLICIES: "Company claim policy",
  CUSTOM_CLAIM_POLICIES: "Custom claim policies",
};
export const PAYROLL_APPROVALS_TABS = [
  {
    name: "billPay.bill.approvals.childPage.needsYourApproval",
    count: null,
    path: ROUTES.payroll.salaryPayment.approvals.needsApproval.absolutePath,
    key: 1,
    defaultQueryParams: {
      tab: "needs-your-approval",
    },
  },
  {
    name: "billPay.bill.approvals.childPage.pending",
    count: null,
    path: ROUTES.payroll.salaryPayment.approvals.pending.absolutePath,
    key: 2,
    defaultQueryParams: {
      tab: "pending",
    },
  },
  {
    name: "billPay.bill.approvals.childPage.all",
    count: null,
    path: ROUTES.payroll.salaryPayment.approvals.all.absolutePath,
    key: 3,
    defaultQueryParams: {
      tab: "all",
    },
  },
];

// payroll payment page tabs
export const PAYROLL_PAYMENTS_TABS = [
  {
    name: "billPay.bill.payments.childPage.all",
    count: null,
    path: ROUTES.payroll.salaryPayment.payments.all.absolutePath,
    key: 1,
    defaultQueryParams: {
      tab: "all",
    },
  },
  {
    name: "billPay.bill.payments.childPage.failed",
    count: null,
    path: ROUTES.payroll.salaryPayment.payments.failed.absolutePath,
    key: 2,
    defaultQueryParams: {
      tab: "failed",
    },
  },
];
export const PAYROLL_SETTINGS_TABS = [
  {
    name: "payroll.payrollSettings.tabs.general",
    count: null,
    path: ROUTES.payroll.settings.general.absolutePath,
    key: 1,
  },
  {
    name: "payroll.payrollSettings.tabs.spendControls",
    count: null,
    path: ROUTES.payroll.settings.spendControls.absolutePath,
    key: 2,
  },
];
export const REIMBURSEMENT_SETTINGS_TABS = [
  {
    name: "payroll.payrollSettings.tabs.general",
    count: null,
    path: ROUTES.reimbursement.settings.general.absolutePath,
    key: 1,
  },
  {
    name: "payroll.payrollSettings.tabs.spendControls",
    count: null,
    path: ROUTES.reimbursement.settings.spendControl.absolutePath,
    key: 2,
  },
];
export const PAYROLL_INBOX_CTA_KEYS = {
  bulkUpload: "bulkUpload",
  create: "addEmployee",
  createSalaryPayment: "createSalaryPayment",
};
export const PAYROLL_INBOX_CTA_CONFIG = {
  [PAYROLL_INBOX_CTA_KEYS.bulkUpload]: {
    label: "payroll.salaryPayment.payrollInbox.pageOptions.bulkUpload",
    onClick: (val, componentProvidedStuff) => {},
    customComponent: true,
  },
  [PAYROLL_INBOX_CTA_KEYS.create]: {
    label: "payroll.salaryPayment.payrollInbox.pageOptions.addNewEmployee",
    onClick: (val, componentProvidedStuff) => {},
    customComponent: true,
  },
};
export const PAYMENT_METHOD_TYPE = {
  get: "GET",
  post: "POST",
};

export const EXCHANGE_RATE = "exchange_rate";

export const BULK_UPLOAD_DOCUMENT_ICONS = {
  [FILE_MIME.PNG]: { iconName: "Png", classes: "text-neutral-500" },
  [FILE_MIME.JPEG]: { iconName: "Png", classes: "text-neutral-500" },
  [FILE_MIME.JPG]: { iconName: "Png", classes: "text-neutral-500" },
  [FILE_MIME.PDF]: { iconName: "PdfDocument", classes: "text-neutral-500" },
};
/**
 *
 * @param {Object} billOrPayment
 */
export const getPaymentSliderInfo = (
  billOrPayment,
  inPayrollContext = false
) => {
  // Page 3
  const isPaymentSlider = [
    PAYMENT_STATUSES.scheduled,
    PAYMENT_STATUSES.unscheduled,
    PAYMENT_STATUSES.processing,
    PAYMENT_STATUSES.unverified,
    PAYMENT_STATUSES.awaitingFunds,
    PAYMENT_STATUSES.outsideVolopay,
  ].includes(billOrPayment.transactionStatus || billOrPayment.status);
  if (isPaymentSlider) {
    return {
      sliderKey: inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.payments.payrollSalaryId
        : SLIDERS_SEARCH_PARAMS.payments.id,
      sliderValue: billOrPayment?.paymentId || billOrPayment?.id, // both purchase and payments API use this util. This OR will work on both
      sliderType: PAGE_TYPE.payments,
      leftSideDisabled: true,
    };
  }

  // Page 4
  const isPaidSlider = [
    PAYMENT_STATUSES.paid,
    PAYMENT_STATUSES.failed,
  ].includes(billOrPayment.transactionStatus || billOrPayment.status);
  if (isPaidSlider) {
    return {
      sliderKey: inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.payments.payrollSalaryId
        : SLIDERS_SEARCH_PARAMS.payments.id,
      sliderValue: billOrPayment?.paymentId || billOrPayment?.id, // both purchase and payments API use this util. This OR will work on both
      sliderType: PAGE_TYPE.payments, // TODO: page type isn't proper in billpay module, but it works
      leftSideDisabled: true,
    };
  }

  // Page 1
  const isInboxSlider = [
    PAYMENT_STATUSES.inDraft,
    PAYMENT_STATUSES.inPreview,
  ].includes(billOrPayment.status);
  if (isInboxSlider) {
    return {
      sliderKey: inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.payrollPayments.editBill
        : SLIDERS_SEARCH_PARAMS.payments.editBill,
      sliderValue: billOrPayment?.paymentId || billOrPayment?.id, // both purchase and payments API use this util. This OR will work on both
      sliderType: PAGE_TYPE.invoiceInbox,
      leftSideDisabled: false,
    };
  }

  // Page 2
  const isApprovalSlider = [
    PAYMENT_STATUSES.approved,
    PAYMENT_STATUSES.pending,
    PAYMENT_STATUSES.rejected,
    PAYMENT_STATUSES.denied,
    PAYMENT_STATUSES.notApproved,
    PAYMENT_STATUSES.pendingToBeTriggered,
    ...Object.values(PAYMENT_APPROVAL_STATUSES),
  ].includes(billOrPayment.status);
  if (isApprovalSlider) {
    return {
      sliderKey: inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.payments.payrollSalaryId
        : SLIDERS_SEARCH_PARAMS.payments.id,
      sliderValue: billOrPayment?.paymentId || billOrPayment?.id,
      sliderType: PAGE_TYPE.approvals,
    };
  }

  return null;
};

export const openRelevantPaymentSlider = (
  billOrPayment,
  inPayrollContext = false,
  componentProvidedStuff = {}
) => {
  // open relevant payment slider
  const { searchParams, setSearchParams } = componentProvidedStuff;
  if (!searchParams) {
    console.error("Missing componentProvidedStuff");
  }
  const { sliderKey, sliderValue, sliderType } = getPaymentSliderInfo(
    billOrPayment,
    inPayrollContext
  );

  const paramsToAppend = {
    [sliderKey]: sliderValue,
    [SLIDERS_SEARCH_PARAMS.payments.sliderType]: [sliderType],
    [SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider]: sliderKey,
    [SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey]:
      SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments,
    [SLIDER_LEFT_SIDE_SEARCH_PARAMS.leftSideDisabled]: true,
  };
  Object.entries(paramsToAppend).forEach(([k, v]) => {
    searchParams.append(k, v);
  });
  //
  setSearchParams(searchParams);
};

export const PAYROLL_BULK_UPLOAD_SLIDER = "payrollBulkUploadSlider";

export const MODIFY_BULK_PAYROLL_ACTION_TYPES = {
  APPROVE: "approve",
  CANCEL: "cancel",
};

// TECH_DEBT: collect all filters here
export const BILLPAY_FILTERS = {
  PURCHASE_BILL_AND_PAYROLL: "vendor_id",
  PAYMENT: "vendor", // payments means purchase bill, or payroll in transit (after approval)
};

export const PAYMENT_SCROLL_TO_KEYS = {
  EDIT_RECURRING: "editRecurring",
  PAYMENT_DATE: "paymentDate",
  COMMENT_SECTION: "commentSection",
};

export const PAYMENT_OWNER_TYPE_FOR_COMMENT_PAYLOAD = {
  PURCHASE_BILL: "PurchaseBill",
  PAYROLL: "Payroll",
  REIMBURSEMENT: "Reimbursement",
};

export const COMMENTS_PAGINATION_LIMIT_PER_REQUEST = 5;

export const BILL_PAYMENT_FREQUENCY = [
  SPEND_FREQUENCY_MODULE_WISE.BILLPAY_AND_PAYROLL[SPEND_FREQUENCY.MONTHLY], // default so keep first in order
  SPEND_FREQUENCY_MODULE_WISE.BILLPAY_AND_PAYROLL[SPEND_FREQUENCY.WEEKLY],
  SPEND_FREQUENCY_MODULE_WISE.BILLPAY_AND_PAYROLL[SPEND_FREQUENCY.QUARTERLY],
  SPEND_FREQUENCY_MODULE_WISE.BILLPAY_AND_PAYROLL[SPEND_FREQUENCY.SEMIANNUALLY],
  SPEND_FREQUENCY_MODULE_WISE.BILLPAY_AND_PAYROLL[SPEND_FREQUENCY.YEARLY],
];

export const PAYROLL_PAYMENT_FREQUENCY = BILL_PAYMENT_FREQUENCY;

export const TAB_TYPES = {
  NEEDS_YOUR_APPROVAL: "needs-your-approval",
  ALL: "all",
  FAILED: "failed",
  PENDING: "pending",
};

export const DELETE_PAY_MODAL_OPTION_VALUES = {
  ARCHIVE_ALL: "archive_all",
  CURRENT: "current",
  UPCOMING: "upcoming",
};
