import PropTypes from "prop-types";
import React, { Suspense } from "react";

import { getIconComponent } from "./helper";

export default function Icon({
  name,
  className,
  handleClick,
  variant = "default",
  bgClassName,
}) {
  const IconComponent = getIconComponent(name);
  if (!IconComponent) return;

  return IconComponent ? (
    <Suspense fallback={<span>Icon...</span>}>
      {variant === "rounded" ? (
        <div
          onClick={handleClick}
          className={`
          ${bgClassName} +
          w-10 h-10 rounded-full flex items-center content-center justify-center`}
        >
          <IconComponent className={className} />
        </div>
      ) : (
        <IconComponent className={className} onMouseDown={handleClick} />
      )}
    </Suspense>
  ) : null;
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  variant: PropTypes.string,
  bgClassName: PropTypes.string,
};
