import AmountCell from "@/components/Reimbursement/cells/AmountCell";
import { SORTING_CATEGORY } from "@/utils/constants/sorting";

export const REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE = {
  PREVIEW: "preview",
  ADD: "add",
  EDIT: "edit",
  COMPANY: "Company",
  CUSTOM: "Custom",
};

export const REIMBURSEMENT_CONTEXT = "reimbursement";
export const ACTIVE = "active";
export const HISTORY = "history";
export const REIMBURSEMENT_TYPE = {
  OUT_OF_POCKET: "out_of_pocket",
  MILEAGE: "mileage",
};
export const REIMBURSEMENT_TYPE_TEXT_MAP = {
  [REIMBURSEMENT_TYPE.OUT_OF_POCKET]: "Out of pocket",
  [REIMBURSEMENT_TYPE.MILEAGE]: "Mileage",
};

export const REIMBURSEMENT_STATUSES = {
  PENDING: "pending",
  NOT_OKAY: "not_okay",
  VERIFIED: "verified",
  SYNC_IN_PROGRESS: "sync_in_progress",
  SYNCED: "synced",
  INCOMPLETE: "incompleted",
  NEED_DETAILS: "details_required",
  OKAY: "okay",
  SYNC_FAILED: "sync_failed",
  REQUESTED: "requested",
  RECEIVED: "received",
};

export const OWNER_TYPE_USER = "User";

export const CREATE_CLAIM_PARAMS = {
  REIMBURSEMENT_TYPE: "reimbursementType",
  TRANSACTION_AMOUNT: "transactionAmount",
  TOTAL_MILES: "totalMiles",
  MERCHANT: "merchant",
  CATEGORY_ID: "categoryId",
  TRANSACTION_DATE: "transactionDate",
  TRAVEL_DATE: "travelDate",
  TRAVEL_COUNTRY: "travelCountry",
  START_LOCATION: "startLocation",
  END_LOCATION: "endLocation",
  PROJECT_ID: "projectId",
};

export const FLEXIBLE = "flexible";
export const NON_FLEXIBLE = "non-flexible";

export const MILEAGE_NOT_SET = "mileage-not-set";
export const MERCHANT = "Merchant";
export const REIMBURSEMENTS_TYPES = {
  OUT_OF_POCKET: "outOfPocket",
  MILEAGE: "mileage",
};

export const REIMBURSEMENT_DEFAULT_HEADERS = {
  tableSetting: {
    headerSticky: true,
    numberOfStickyColsLeft: 1,
    colWidths: [300, 200, 200, 200, 200, 120, 300],
    styleId: "reimbursement-table",
  },
  tableHeading: [
    {
      id: "claimType",
      title: "reimbursement.payments.claimTable.type",
      classes: "text-left",
    },
    {
      id: "amount",
      title: "reimbursement.payments.claimTable.amount",
      classes: "text-right justify-end",
      cellComponent: AmountCell,
      haveSort: true,
      sortingCategory: SORTING_CATEGORY.AMOUNT,
    },

    {
      id: "submissionDate",
      title: "reimbursement.payments.claimTable.submittedDate",
      classes: "text-center",
    },
    {
      id: "transactionDate",
      title: "reimbursement.payments.claimTable.transactionDate",
      classes: "text-center",
    },
    {
      id: "linkedTo",
      title: "reimbursement.payments.claimTable.linkedTo",
      classes: "text-center",
    },
  ],
  tableHeadingRowClasses:
    "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
  tableDataRowClasses:
    "text-sm font-semibold text-center cursor-pointer text-neutral-800",
};

export const REPORT_DEFAULT_HEADERS = {
  tableSetting: {
    headerSticky: true,
    numberOfStickyColsLeft: 1,
    colWidths: [300, 200, 120, 300, 300, 300],
    styleId: "report-table",
  },
  tableHeading: [
    {
      id: "reportName",
      title: "myVolopay.reimbursements.reports.drafts.tableHeaders.reportName",
      classes: "text-left",
    },
    {
      id: "amount",
      title: "myVolopay.reimbursements.reports.drafts.tableHeaders.totalAmount",
      classes: "text-right justify-end",
      haveSort: true,
      sortingCategory: SORTING_CATEGORY.AMOUNT,
    },
    {
      id: "noOfClaims",
      title: "myVolopay.reimbursements.reports.drafts.tableHeaders.totalClaims",
      classes: "text-left",
    },

    {
      id: "submissionDate",
      title:
        "myVolopay.reimbursements.reports.drafts.tableHeaders.submittedDate",
      classes: "text-left",
    },
    {
      id: "linkedTo",
      title: "myVolopay.reimbursements.reports.drafts.tableHeaders.linkedTo",
      classes: "text-left",
    },
    {
      id: "description",
      title: "myVolopay.reimbursements.reports.drafts.tableHeaders.description",
      classes: "text-left",
    },
  ],
  tableHeadingRowClasses:
    "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
  tableDataRowClasses:
    "text-sm font-semibold text-center cursor-pointer text-neutral-800",
};

export const PAYMENT_HISTORY_DEFAULT_HEADERS = {
  tableSetting: {
    headerSticky: true,
    numberOfStickyColsLeft: 1,
    colWidths: [300, 200, 200, 200],
    styleId: "history-table",
  },
  tableHeading: [
    {
      id: "paymentDate",
      title: "reimbursement.paid.paymentHistory.paymentDate",
      classes: "text-left",
    },
    {
      id: "amount",
      title: "reimbursement.paid.paymentHistory.amount",
      classes: "text-right justify-end",
      haveSort: true,
      sortingCategory: SORTING_CATEGORY.AMOUNT,
    },
    {
      id: "status",
      title: "reimbursement.paid.paymentHistory.status",
      classes: "text-center justify-center",
    },

    {
      id: "noOfClaims",
      title: "reimbursement.paid.paymentHistory.settledClaims",
      classes: "text-center justify-center",
    },
  ],
  tableHeadingRowClasses:
    "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
  tableDataRowClasses:
    "text-sm font-semibold text-center cursor-pointer text-neutral-800",
};

export const REPORT_PAYMENT_HISTORY_DEFAULT_HEADERS = {
  tableSetting: {
    headerSticky: true,
    numberOfStickyColsLeft: 1,
    colWidths: [300, 200, 200, 200],
    styleId: "report-history-table",
  },
  tableHeading: [
    {
      id: "paymentDate",
      title: "reimbursement.paid.paymentHistory.paymentDate",
      classes: "text-left",
    },
    {
      id: "amount",
      title: "reimbursement.payments.listHeading.amount",
      classes: "text-right justify-end",
      haveSort: true,
      sortingCategory: SORTING_CATEGORY.AMOUNT,
    },
    {
      id: "status",
      title: "reimbursement.paid.paymentHistory.status",
      classes: "text-center justify-center",
    },

    {
      id: "noOfReports",
      title: "myVolopay.reimbursements.drafts.tableHeaders.noOfReports",
      classes: "text-center justify-center",
    },
  ],
  tableHeadingRowClasses:
    "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
  tableDataRowClasses:
    "text-sm font-semibold text-center cursor-pointer text-neutral-800",
};

export const REIMBURSEMENT_PAYLOAD_KEY = {
  RECEIPTS: "receipts",
  TAG_VALUE_MAIN_KEY: "reimbursement_tag_values_attributes",
  TRANSACTION_DATE: "transaction_date",
  TRAVEL_DATE: "travel_date",
  TAG_VALUE_ID: "tag_value_id",
  TAG_ID: "tag_id",
  CUSTOM_TEXT_VALUE: "custom_text_value",
};

export const REIMBURSEMENT_DATE_MAPPINGS = {
  [REIMBURSEMENT_TYPE.MILEAGE]: "travelDate",
  [REIMBURSEMENT_TYPE.OUT_OF_POCKET]: "transactionDate",
};

export const REIMBURSEMENT_SETTINGS = {
  REIMBURSEMENT_AUTOPAY_ENABLED: "reimbursement_autopay_enabled",
  REIMBURSEMENT_AUTOPAY_DAY: "reimbursement_autopay_day",
  AUTO_CLAIM_SETTLEMENT_TO_CARD_ENABLED:
    "auto_claim_settlement_to_card_enabled",
};

export const MILEAGE_RATE_UNITS = {
  MILES: {
    label: "Miles",
    value: "mile",
  },
  KMS: {
    label: "Kms",
    value: "km",
    amountDescription:
      "reimbursement.settings.sliders.mileageRate.mileageRateDescKm",
  },
};

export const MILEAGE_RATE_AMOUNT_DESC = {
  [MILEAGE_RATE_UNITS.MILES.value]:
    "reimbursement.settings.sliders.mileageRate.mileageRateDescMile",
  [MILEAGE_RATE_UNITS.KMS.value]:
    "reimbursement.settings.sliders.mileageRate.mileageRateDescKm",
};

export const MILEAGE_RATE_UNIT_OPTIONS = [
  MILEAGE_RATE_UNITS.MILES,
  MILEAGE_RATE_UNITS.KMS,
];

export const UNITS = {
  METERS: "m",
  FT: "ft",
};
