import { createSelector } from "@reduxjs/toolkit";

import { cardsEnabledSelector } from "@/store/selectors/user";
import { ACCOUNT_TYPE } from "@/utils/constants/client";

import { CARD_PROVIDER } from "@/constants/Cards";
import { PAYMENT_PROVIDERS } from "@/constants/provider";

export const clientStore = (store) => store.client;

export const clientSelector = createSelector(
  clientStore,
  (clientState) => clientState.client
);

export const isFetchingClientSelector = createSelector(
  clientStore,
  (client) => client.isFetching
);

export const walletSelector = createSelector(
  clientStore,
  (client) => client.wallets
);

export const generatedFundQuoteSelector = createSelector(
  clientStore,
  (client) => {
    return client.generatedFundQuote;
  }
);

export const accountDetailSelector = createSelector(
  clientStore,
  (client) => client.accountDetails
);

export const walletListSelector = createSelector(
  walletSelector,
  (walletObj) => walletObj.list
);

export const isFetchingWalletListSelector = createSelector(
  walletSelector,
  (walletObj) => walletObj.isFetching
);

export const paymentWalletListSelector = createSelector(
  walletListSelector,
  (wallets) =>
    wallets?.filter(
      (wallet) =>
        !wallet.accountType || wallet.accountType === ACCOUNT_TYPE.PAYMENTS // handles shallow API too
    )
);

export const cashbackSelector = createSelector(walletListSelector, (wallets) =>
  wallets?.find((wallet) => wallet.accountType === ACCOUNT_TYPE.CASHBACK)
);

export const payrollWalletSelector = createSelector(
  clientStore,
  (client) => client.payrollWallet
);

export const payrollWalletAsArraySelector = createSelector(
  clientStore,
  (client) =>
    Array.isArray(client.payrollWallet)
      ? client.payrollWallet
      : [client.payrollWallet]
);

export const isFetchingPayrollWalletSelector = createSelector(
  clientStore,
  (client) => client.isFetchingPayrollWallet
);

export const isFetchedPayrollWalletSelector = createSelector(
  clientStore,
  (client) => client.isFetchedPayrollWallet
);

export const currenciesSelector = createSelector(
  clientStore,
  (client) => client.currencies
);

export const creditAccountSelector = createSelector(
  walletListSelector,
  (wallets) =>
    wallets?.find(
      (wallet) => wallet.accountType === ACCOUNT_TYPE.CREDIT_ACCOUNT
    )
);

export const payrollAccountSelector = createSelector(
  walletListSelector,
  (wallets) =>
    wallets?.find((wallet) => wallet.accountType === ACCOUNT_TYPE.PAYROLL)
);

export const entityNameSelector = createSelector(
  clientSelector,
  (clientState) => clientState.entityName
);
export const billingDaySelector = createSelector(
  clientSelector,
  (clientState) => clientState.billingDay
);
export const clientCreatedAtSelector = createSelector(
  clientSelector,
  (clientState) => clientState.createdAt
);
export const kybStatusSelector = createSelector(
  clientSelector,
  (client) => client.kybStatus
);

export const baseCountrySelector = createSelector(
  clientSelector,
  (client) => client.baseCountry
);

export const defaultCurrencySelector = createSelector(
  clientSelector,
  (client) => client.defaultCurrency
);

export const defaultPaymentAccountSelector = createSelector(
  walletListSelector,
  defaultCurrencySelector,
  (wallets, defaultCurrency) =>
    wallets?.find(
      (wallet) =>
        wallet.accountType === ACCOUNT_TYPE.PAYMENTS &&
        wallet.currency === defaultCurrency
    )
);

export const accountingEnabledSelector = createSelector(
  clientSelector,
  (client) => !!client.accountingIntegrationSoftware
);

export const accountingIntegrationSoftwareSelector = createSelector(
  clientSelector,
  (client) => client.accountingIntegrationSoftware
);

export const accountingContinuousBillSyncSelector = createSelector(
  clientSelector,
  (client) => client.accountingContinuousBillSync
);

export const accountingTransferFeeSyncSelector = createSelector(
  clientSelector,
  (client) => client.accountingTransferFeeSync
);

export const cardMultiCurrencyEnabledSelector = createSelector(
  clientSelector,
  (client) => client.cardMultiCurrencyEnabled
);

export const clientAccountEnabledSelector = createSelector(
  clientSelector,
  (client) => client.clientAccountEnabled
);

export const clientBudgetTypeSelector = createSelector(
  clientSelector,
  (client) => client.clientBudgetType
);
export const isDepartmentClientBudgetTypeSelector = createSelector(
  clientSelector,
  (client) => true
);
export const creditEnabledSelector = createSelector(
  clientSelector,
  (client) => client.creditEnabled
);
export const accountFreezeSelector = createSelector(
  clientSelector,
  (client) => client.accountFreeze
);
export const isCreditWorthySelector = createSelector(
  clientSelector,
  (client) => client.isCreditWorthy
);
export const creditFreezeSelector = createSelector(
  clientSelector,
  (client) => client.creditFreeze
);

export const creditFreezeDateSelector = createSelector(
  clientSelector,
  (client) => client.creditFreezeDate
);

export const currentPaymentProviderSelector = createSelector(
  clientSelector,
  (client) => client.currentPaymentProvider
);

export const currentCardProviderSelector = createSelector(
  clientSelector,
  (client) => client.currentCardProvider
);

export const paymentProviderTypeSelector = createSelector(
  clientSelector,
  (client) => client.paymentProviderType
);

export const maxApproverLevelsSelector = createSelector(
  clientSelector,
  (client) => client.maxApproverLevels
);

export const maxPoliciesInPolicyGroupSelector = createSelector(
  clientSelector,
  (client) => client.maxPoliciesInPolicyGroup
);

export const billPayEnabledSelector = createSelector(
  clientSelector,
  (client) => client.billPayEnabled
);

export const physicalCardsEnabledSelector = createSelector(
  clientSelector,
  (client) => client.physicalCardsEnabled
);

export const reimbursementEnabledSelector = createSelector(
  clientSelector,
  (client) => client.reimbursementEnabled
);

export const splitExpenseEnabledSelector = createSelector(
  clientSelector,
  (client) => client.splitExpensesEnabled
);

export const approvalPolicyEnabledSelector = createSelector(
  clientSelector,
  (client) => client.approvalPolicyEnabled
);

export const customApprovalPolicyEnabledSelector = createSelector(
  clientSelector,
  (client) => client.customApprovalPolicyEnabled
);

export const claimPolicyEnabledSelector = createSelector(
  clientSelector,
  (client) => client.claimPolicyEnabled
);

export const customClaimPolicyEnabledSelector = createSelector(
  clientSelector,
  (client) => client.customClaimPolicyEnabled
);

export const submissionPolicyEnabledSelector = createSelector(
  clientSelector,
  (client) => client.submissionPolicyEnabled
);

export const customSubmissionPolicyEnabledSelector = createSelector(
  clientSelector,
  (client) => client.customSubmissionPolicyEnabled
);

export const departmentManagerEditEnabledSelector = createSelector(
  clientSelector,
  (client) => client.departmentManagerEditEnabled
);

export const projectManagerEditEnabledSelector = createSelector(
  clientSelector,
  (client) => client.projectManagerEditEnabled
);

export const physicalCardPolicyEnabledSelector = createSelector(
  clientSelector,
  (client) => client.physicalCardPolicyEnabled
);
export const reimbursementSubmissionDateFilterEnabledSelector = createSelector(
  clientSelector,
  (client) => client.reimbursementSubmissionDateFilterEnabled
);

export const isReimbursementClientSelector = createSelector(
  reimbursementEnabledSelector,
  cardsEnabledSelector,
  billPayEnabledSelector,
  (reimbursementEnabled, cardsEnabled, billPayEnabled) =>
    reimbursementEnabled && !cardsEnabled && !billPayEnabled
);
export const isFetchingAccountWalletSelector = createSelector(
  walletSelector,
  (wallet) => wallet.isFetching
);

export const isFetchedAccountWalletSelector = createSelector(
  walletSelector,
  (wallet) => wallet.isFetched
);

export const currentPolicySelector = createSelector(clientStore, (client) => {
  return client.currentPolicy;
});

export const currentPolicyModuleSelector = createSelector(
  clientStore,
  (client) => {
    return client.currentPolicyModule;
  }
);

export const cardsProvidersListItemSelector = createSelector(
  clientSelector,
  (client) => client?.cardProviders
);
export const primaryCardProviderSelector = createSelector(
  cardsProvidersListItemSelector,
  (providers) => providers?.find((item) => item?.primary)
);

// This selector is to get the cardProvider with international Card Order Enabled.
export const cardProviderWithInternationalCardOrderEnabledSelector =
  createSelector(cardsProvidersListItemSelector, (providers) =>
    providers?.find((provider) => provider?.config?.isInternationalCardEnabled)
  );

export const primaryCardProviderConfigSelector = createSelector(
  primaryCardProviderSelector,
  (primaryCardProvider) => primaryCardProvider?.config
);

export const isUOBCardProviderSelector = createSelector(
  primaryCardProviderSelector,
  (primaryProvider) => primaryProvider?.slug === CARD_PROVIDER.UOB
);

export const activeCardsProviderSelector = createSelector(
  cardsProvidersListItemSelector,
  (cardsProvidersList) => {
    return cardsProvidersList?.find(
      (cardProvider) => cardProvider.active === true
    );
  }
);

export const isFetchingClientDetailsSelector = createSelector(
  clientStore,
  (client) => client.isFetchingClientDetails
);

export const isUpdatingClientSettingsSelector = createSelector(
  clientStore,
  (client) => client.updatingClientSettings
);

export const previousBillingStartAndEndDateSelector = createSelector(
  clientSelector,
  (client) => ({
    startDate: client.previousBillingStartDate,
    endDate: client.previousBillingEndDate,
  })
);

export const currentBillingStartAndEndDateSelector = createSelector(
  clientSelector,
  (client) => ({
    startDate: client.currentBillingStartDate,
    endDate: client.currentBillingEndDate,
  })
);

export const billAutoPayEnabledSelector = createSelector(
  clientSelector,
  (client) => client.billPaymentAutopayEnabled
);

export const payrollAutoPayEnabledSelector = createSelector(
  clientSelector,
  (client) => client.payrollAutopayEnabled
);

export const subdomainSelector = createSelector(
  clientSelector,
  (client) => client.subdomain
);

export const reimbursementReportsEnabledSelector = createSelector(
  clientSelector,
  (client) => client.reimbursementReportsEnabled
);

export const cardsMakerCheckerEnabledSelector = createSelector(
  clientSelector,
  (client) => client.cardsMakerCheckerEnabled
);

export const qrwalletMakerCheckerEnabledSelector = createSelector(
  clientSelector,
  (client) => client.qrwalletMakerCheckerEnabled
);

export const paymentsMakerCheckerEnabledSelector = createSelector(
  clientSelector,
  (client) => client.paymentsMakerCheckerEnabled
);

export const reimbursementMakerCheckerEnabledSelector = createSelector(
  clientSelector,
  (client) => client.reimbursementMakerCheckerEnabled
);

export const payrollMakerCheckerEnabledSelector = createSelector(
  clientSelector,
  (client) => client.payrollMakerCheckerEnabled
);

export const expenseReviewMakerCheckerEnabledSelector = createSelector(
  clientSelector,
  (client) => client.expenseReviewMakerCheckerEnabled
);

export const expenseReviewPolicyEnabledSelector = createSelector(
  clientSelector,
  (client) => client.expenseReviewPolicyEnabled
);

export const dontShowUnsettledInCreditSelector = createSelector(
  clientSelector,
  (client) => client.dontShowUnsettledInCredit
);

export const customExpenseReviewPolicyEnabledSelector = createSelector(
  clientSelector,
  (client) => client.customExpenseReviewPolicyEnabled
);

export const qrpayReviewMakerCheckerEnabledSelector = createSelector(
  clientSelector,
  (client) => client.qrpayReviewMakerCheckerEnabled
);

export const paymentsAutoApproveEnabledSelector = createSelector(
  clientSelector,
  (client) => client.paymentsAutoApproveEnabled
);

export const isCorporateTravelEnabledSelector = createSelector(
  clientSelector,
  (client) => client.corporateTravelEnabled
);

export const transactionLevelTagsSetSelector = createSelector(
  clientSelector,
  (client) => new Set((client?.transactionLevelTags ?? []).map((tag) => tag.id))
);

export const defaultPayOnceApprovedEnabledSelector = createSelector(
  clientSelector,
  (client) => !!client?.defaultPayOnceApprovedEnabled
);

export const volopayAssistantEnabledSelector = createSelector(
  clientSelector,
  (client) => client?.volopayAssistantEnabled
);

export const expenseMultiLayerReviewEnabledSelector = createSelector(
  clientSelector,
  (client) => client?.expenseMultiLayerReviewEnabled
);

// rapido claims customization
export const claimAmountEditEnabledSelector = createSelector(
  clientSelector,
  (client) => client?.claimAmountEditEnabled
);

export const claimAmountDependentValidationEnabledSelector = createSelector(
  clientSelector,
  (client) => client?.claimAmountDependentValidationEnabled
);

export const claimApprovalAllTabEnabledSelector = createSelector(
  clientSelector,
  (client) => client?.claimApprovalAllTabEnabled
);

export const currentOcrProviderSelector = createSelector(
  clientSelector,
  (client) => client?.currentOcrProvider
);

export const ocrRegionSelector = createSelector(
  clientSelector,
  (client) => client?.region
);

export const showGlobalAccountSelector = createSelector(
  clientSelector,
  (client) => {
    return client.currentPaymentProvider === PAYMENT_PROVIDERS.AIRWALLEX;
  }
);

export const budgetedPaidOutsideEnabledSelector = createSelector(
  clientSelector,
  (client) => client?.paidOutsideBudgetedEnabled
);

export const cardsClaimSettlementEnabledSelector = createSelector(
  clientSelector,
  (client) => client?.cardsClaimSettlementEnabled
);
export const reimbursementTransactionAllowedDateRangeSelector = createSelector(
  clientSelector,
  (client) => client.reimbursementTransactionAllowedDateRange
);

export const payrollMultiLineItemsEnabledSelector = createSelector(
  clientSelector,
  (client) => client.payrollMultiLineItemsEnabled
);

export const skipKycFeatureEnabledSelector = createSelector(
  clientSelector,
  (client) => client.skipKycFeatureEnabled
);

export const moduleHistoryEnabledSelector = createSelector(
  clientSelector,
  (client) => client.moduleHistoryEnabled
);

export const slackNotificationEnabledSelector = createSelector(
  clientSelector,
  (client) => client.slackNotificationEnabled
);

export const whatsappNotificationEnabledSelector = createSelector(
  clientSelector,
  (client) => client.whatsappNotificationEnabled
);

export const customBeneficiaryPayoutSelector = createSelector(
  clientSelector,
  (client) => client.customBeneficiaryPayout
);
