import Icons from "@/assets/icons";

export const ICONS_MAP = {
  Card: () => Icons.Card,
  Celebration: () => Icons.Celebration,
  Upload: () => Icons.Upload,
  Delete: () => Icons.Delete,
  Department: () => Icons.Department,
  Download: () => Icons.Download,
  CircleFilled: () => Icons.CircleFilled,
  Info: () => Icons.Info,
  Close: () => Icons.Close,
  ShoppingBag: () => Icons.ShoppingBag,
  BellPlus: () => Icons.BellPlus,
  BellWarning: () => Icons.BellWarning,
  BinClock: () => Icons.BinClock,
  WarningCircle: () => Icons.WarningCircle,
  WarningHex: () => Icons.WarningHex,
  WarningTriangle: () => Icons.WarningTriangle,
  Search: () => Icons.Search,
  Right: () => Icons.Right,
  VolopayLogo: () => Icons.VolopayLogo,
  Accounting: () => Icons.Accounting,
  BillPay: () => Icons.BillPay,
  Company: () => Icons.Company,
  Dashboard: () => Icons.Dashboard,
  DashboardOther: () => Icons.DashboardOther,
  Expense: () => Icons.Expense,
  Reimbursement: () => Icons.Reimbursement,
  Settings: () => Icons.Settings,
  Contacts: () => Icons.Contacts,
  EArrow: () => Icons.EArrow,
  Plus: () => Icons.Plus,
  Sync: () => Icons.Sync,
  ChevronLeft: () => Icons.ChevronLeft,
  CSV: () => Icons.CSV,
  Mail: () => Icons.Mail,
  ManageAccount: () => Icons.ManageAccount,
  SettingsSync: () => Icons.SettingsSync,
  SettingsAccount: () => Icons.SettingsAccount,
  Savings: () => Icons.Savings,
  AccountingTree: () => Icons.AccountingTree,
  AccountBalanceWallet: () => Icons.AccountBalanceWallet,
  SyncDisabled: () => Icons.SyncDisabled,
  UpArrow: () => Icons.UpArrow,
  Location: () => Icons.Location,
  DownArrow: () => Icons.DownArrow,
  LeftArrow: () => Icons.LeftArrow,
  RightArrow: () => Icons.RightArrow,
  TArrow: () => Icons.TArrow,
  Received: () => Icons.Received,
  Hourglass: () => Icons.Hourglass,
  Assignment: () => Icons.Assignment,
  AssignmentAddOn: () => Icons.AssignmentAddOn,
  AddOnField: () => Icons.AddOnField,
  Done: () => Icons.Done,
  Emoji: () => Icons.Emoji,
  Attach: () => Icons.Attach,
  MoreVerticle: () => Icons.MoreVerticle,
  Add: () => Icons.Add,
  AddMileageRate: () => Icons.AddMileageRate,
  CategoriesIcon: () => Icons.CategoriesIcon,
  Review: () => Icons.Review,
  Copy: () => Icons.Copy,
  Task: () => Icons.Task,
  Schedule: () => Icons.Schedule,
  DateRangeArrow: () => Icons.DateRangeArrow,
  Calendar: () => Icons.Calendar,
  Eye: () => Icons.Eye,
  SlashEye: () => Icons.SlashEye,
  CallSplit: () => Icons.CallSplit,
  NEArrow: () => Icons.NEArrow,
  BackArrow: () => Icons.BackArrow,
  ArrowForward: () => Icons.ArrowForward,

  ToLink: () => Icons.ToLink,
  Memo: () => Icons.Memo,
  Comments: () => Icons.Comments,
  SyncAlt: () => Icons.SyncAlt,
  Cloud: () => Icons.Cloud,
  CreditCard: () => Icons.CreditCard,
  Person: () => Icons.Person,
  Edit: () => Icons.Edit,
  Password: () => Icons.Password,
  Snowflake: () => Icons.Snowflake,
  ChevronDown: () => Icons.ChevronDown,
  SubPolicy_Met: () => Icons.SubPolicy_Met,
  SubPolicy_NotMet: () => Icons.SubPolicy_NotMet,
  ReceiptSubmitted: () => Icons.ReceiptSubmitted,
  ReceiptAutoCaptured: () => Icons.ReceiptAutoCaptured,
  ReceiptAutoVerified: () => Icons.ReceiptAutoVerified,
  ReceiptMissing: () => Icons.ReceiptMissing,
  ReceiptNotRequested: () => Icons.ReceiptNotRequested,
  Memo_Present: () => Icons.Memo_Present,
  Memo_NotPresent: () => Icons.Memo_NotPresent,
  Dollar: () => Icons.Dollar,
  ThumbsUp: () => Icons.ThumbsUp,
  ArrowLeft: () => Icons.ArrowLeft,
  Processing: () => Icons.Processing,
  MissingBadge: () => Icons.MissingBadge,
  Down: () => Icons.Down,
  VerifiedUser: () => Icons.VerifiedUser,
  ReceiptChecked: () => Icons.ReceiptChecked,
  SubmissionPolicy: () => Icons.SubmissionPolicy,
  Receipt: () => Icons.Receipt,
  CreditPending: () => Icons.CreditPending,
  CreditPaid: () => Icons.CreditPaid,
  Remind: () => Icons.Remind,
  Discussion: () => Icons.Discussion,
  Verify: () => Icons.Verify,
  VolopayLogoOnCard: () => Icons.VolopayLogoOnCard,
  Flag: () => Icons.Flag,
  AddReceiveAmount: () => Icons.AddReceiveAmount,
  SavingsFill: () => Icons.SavingsFill,
  Toll: () => Icons.Toll,
  CaptivePortal: () => Icons.CaptivePortal,
  AccountDetails: () => Icons.AccountDetails,
  Exchange: () => Icons.Exchange,
  MeatballsMenu: () => Icons.MeatballsMenu,
  MoveDown: () => Icons.MoveDown,
  AddCard: () => Icons.AddCard,
  PersonAdd: () => Icons.PersonAdd,
  Integrations: () => Icons.Integrations,
  OutstandingClaims: () => Icons.OutstandingClaims,
  RequestClaim: () => Icons.RequestClaim,
  Triggers: () => Icons.Triggers,
  BillPayRequests: () => Icons.BillPayRequests,
  EditCard: () => Icons.EditCard,
  ReimbursementRequests: () => Icons.ReimbursementRequests,
  Checklist: () => Icons.Checklist,
  PendingToVerify: () => Icons.PendingToVerify,
  BillPayCancel: () => Icons.BillPayCancel,
  Cancel: () => Icons.Cancel,
  ShieldCross: () => Icons.ShieldCross,
  Payments: () => Icons.Payments,
  AddBusiness: () => Icons.AddBusiness,
  AddLocationAlt: () => Icons.AddLocationAlt,
  Calculate: () => Icons.Calculate,
  ContentPaste: () => Icons.ContentPaste,
  ContentPasteSearch: () => Icons.ContentPasteSearch,
  Google: () => Icons.Google,
  Group: () => Icons.Group,
  Groups: () => Icons.Groups,
  GroupsAdd: () => Icons.GroupsAdd,
  LocalATM: () => Icons.LocalATM,
  LocationOn: () => Icons.LocationOn,
  OpenInNew: () => Icons.OpenInNew,
  PendingActions: () => Icons.PendingActions,
  Send: () => Icons.Send,
  Store: () => Icons.Store,
  Vector: () => Icons.Vector,
  Verified: () => Icons.Verified,
  TrendingUp: () => Icons.TrendingUp,
  TrendingDown: () => Icons.TrendingDown,
  SupervisorAccount: () => Icons.SupervisorAccount,
  Adjustment: () => Icons.Adjustment,
  InvoicePayment: () => Icons.InvoicePayment,
  Minus: () => Icons.Minus,
  PlayCircle: () => Icons.PlayCircle,
  Replay: () => Icons.Replay,
  Repeat: () => Icons.Repeat,
  Notification: () => Icons.Notification,
  Help: () => Icons.Help,
  Chat: () => Icons.Chat,
  Redeem: () => Icons.Redeem,
  Cashback: () => Icons.Cashback,
  Dot: () => Icons.Dot,
  ArrowUpward: () => Icons.ArrowUpward,
  ArrowDownward: () => Icons.ArrowDownward,
  Notifications: () => Icons.Notifications,
  ChevronUp: () => Icons.ChevronUp,
  swapHorizontal: () => Icons.SwapHorzontal,
  HowToReg: () => Icons.HowToReg,
  CircleCheck: () => Icons.CircleCheck,
  PersonSearch: () => Icons.PersonSearch,
  PersonWithCircle: () => Icons.PersonWithCircle,
  PersonCircled: () => Icons.PersonCircled,
  GppBad: () => Icons.GppBad,
  PlaylistRemove: () => Icons.PlaylistRemove,
  MoneyIn: () => Icons.MoneyIn,
  MoneyOut: () => Icons.MoneyOut,
  SwiftLogo: () => Icons.SwiftLogo,
  WarningCircleFill: () => Icons.WarningCircleFill,
  Quiz: () => Icons.Quiz,
  MoveToInbox: () => Icons.MoveToInbox,
  Xero: () => Icons.Xero,
  Tally: () => Icons.Tally,
  MYOB: () => Icons.MYOB,
  Quickbooks: () => Icons.QuickBooks,
  Netsuite: () => Icons.Netsuite,
  Deskera: () => Icons.Deskera,
  UniversalCSV: () => Icons.UniversalCSV,
  Zohobooks: () => Icons.ZohoBooks,
  Volopay3D: () => Icons.Volopay3D,
  OfflineBolt: () => Icons.OfflineBolt,
  Error: () => Icons.Error,
  Power: () => Icons.Power,
  Sheild: () => Icons.Sheild,
  DashboardCustomise: () => Icons.DashboardCustomise,
  SolidCheckCircle: () => Icons.SolidCheckCircle,
  EmojiFlagSolid: () => Icons.EmojiFlagSolid,
  PriceCheck: () => Icons.PriceCheck,
  ModeComment: () => Icons.ModeComment,
  Alert: () => Icons.Alert,
  Bolt: () => Icons.Bolt,
  CheckCircle: () => Icons.CheckCircle,
  SubdirectoryArrowRight: () => Icons.SubdirectoryArrowRight,
  SwapHorizontal: () => Icons.SwapHorizontal,
  BookOpen: () => Icons.BookOpen,
  LightningBolt: () => Icons.LightningBolt,
  LightningBoltCustom: () => Icons.LightningBoltCustom,
  SyncFailed: () => Icons.SyncFailed,
  Percentage: () => Icons.Percentage,
  Type: () => Icons.Type,
  FactCheck: () => Icons.FactCheck,
  Unsynced: () => Icons.Unsynced,
  Columns: () => Icons.Columns,
  PriceChange: () => Icons.PriceChange,
  DoNotDisturb: () => Icons.DoNotDisturb,
  ArrowDropUp: () => Icons.ArrowDropUp,
  ArrowDropDown: () => Icons.ArrowDropDown,
  FreezedPhysicalCard: () => Icons.FreezeCardIcon,
  FreezeVirtualCard: () => Icons.FreezeCloud,
  EventRepeat: () => Icons.EventRepeat,
  AcUnits: () => Icons.AcUnits,
  ChangePin: () => Icons.ChangePin,
  Block: () => Icons.Block,
  Pace: () => Icons.Pace,
  CalendarAddOn: () => Icons.CalendarAddOn,
  CalendarClock: () => Icons.CalendarClock,
  EditCalendar: () => Icons.EditCalendar,
  Recreated: () => Icons.Recreated,
  CloudSync: () => Icons.CloudSync,
  ArmingCountdown: () => Icons.ArmingCountdown,
  OutgoingMail: () => Icons.OutgoingMail,
  Slack: () => Icons.Slack,
  WhatsApp: () => Icons.WhatsApp,
  NoChange: () => Icons.NoChange,
  LightiningBolt: () => Icons.LightiningBolt,
  DownRight: () => Icons.DownRight,
  MapArrow: () => Icons.MapArrow,
  AirlineStops: () => Icons.AirlineStops,
  Refresh: () => Icons.Refresh,
  RefreshTime: () => Icons.RefreshTime,
  PieChart: () => Icons.PieChart,
  FormatLetterSpacing: () => Icons.FormatLetterSpacing,
  Article: () => Icons.Article,
  Admin: () => Icons.Admin,
  CardState: () => Icons.CardState,
  CardChecked: () => Icons.CardChecked,
  Frequency: () => Icons.Frequency,
  Menu: () => Icons.Menu,
  TourFlag: () => Icons.TourFlag,
  Collapse: () => Icons.Collapse,
  Expand: () => Icons.Expand,
  Link: () => Icons.Link,
  DonutLarge: () => Icons.DonutLarge,
  QrCode: () => Icons.QrCode,
  Wallet: () => Icons.Wallet,
  WalletLimit: () => Icons.WalletLimit,
  WalletState: () => Icons.WalletState,
  AirplaneTicket: () => Icons.AirplaneTicket,
  AlarmOn: () => Icons.AlarmOn,
  Forum: () => Icons.Forum,
  SelectGesture: () => Icons.SelectGesture,
  CheckedHeart: () => Icons.CheckedHeart,
  AccountBox: () => Icons.AccountBox,
  Headset: () => Icons.Headset,
  Backup: () => Icons.Backup,
  Recurring: () => Icons.Recurring,
  RoundedCreditCard: () => Icons.RoundedCreditCard,
  RoundedEditCreditCard: () => Icons.RoundedEditCreditCard,
  RoundedCloud: () => Icons.RoundedCloud,
  RoundedEditCloud: () => Icons.RoundedEditCloud,
  CallReceived: () => Icons.CallReceived,
  CallReceivedReverse: () => Icons.CallReceivedReverse,
  FilterList: () => Icons.FilterList,
  AccountSetUp: () => Icons.AccountSetUp,
  Ellipse: () => Icons.Ellipse,
  NeedAnyHelp: () => Icons.NeedAnyHelp,
  LearnMore: () => Icons.LearnMore,
  QrPay: () => Icons.QrPay,
  Paid: () => Icons.Paid,
  NotificationActive: () => Icons.NotificationActive,
  CardStraps: () => Icons.CardStraps,
  Encrypted: () => Icons.Encrypted,
  Cached: () => Icons.Cached,
  PaymentColor: () => Icons.PaymentColor,
  AdsClick: () => Icons.AdsClick,
  PhysicalCardEditLimit: () => Icons.PhysicalCardEditLimit,
  PhysicalCardRequest: () => Icons.PhysicalCardRequest,
  VirtualCardEditRequest: () => Icons.VirtualCardEditRequest,
  World: () => Icons.World,
  Reject: () => Icons.Reject,
  Priority: () => Icons.Priority,
  CompanyNotFound: () => Icons.CompanyNotFound,
  DottedDesign: () => Icons.DottedDesign,
  Share: () => Icons.Share,
  Badge: () => Icons.Badge,
  PhotoCamera: () => Icons.PhotoCamera,
  Call: () => Icons.Call,
  FilledSchedule: () => Icons.FilledSchedule,
  NewReleases: () => Icons.NewReleases,
  VideoCam: () => Icons.VideoCam,
  Lock: () => Icons.Lock,
  AverageTimer: () => Icons.AverageTimer,
  AddNotes: () => Icons.AddNotes,
  FileCopy: () => Icons.FileCopy,
  PayOutsideVolopay: () => Icons.PayOutsideVolopay,
  AccountBalance: () => Icons.AccountBalance,
  LoadingCircle: () => Icons.LoadingCircle,
  ClearInput: () => Icons.ClearInput,
  FlexibleLimit: () => Icons.FlexibleLimit,
  NonFlexibleLimit: () => Icons.NonFlexibleLimit,
  ExpenseChart: () => Icons.ExpenseChart,
  Zoom: () => Icons.Zoom,
  Png: () => Icons.Png,
  Pdf: () => Icons.Pdf,
  IosArrowBack: () => Icons.IosArrowBack,
  IosArrowForward: () => Icons.IosArrowForward,
  TaskCheck: () => Icons.TaskCheck,
  TaskAlt: () => Icons.TaskAlt,
  PdfDocument: () => Icons.pdfDocument,
  LockClock: () => Icons.LockClock,
  Email: () => Icons.Email,
  Mobile: () => Icons.Mobile,
  Authenticator: () => Icons.Authenticator,
  StepOver: () => Icons.StepOver,
  SpinLoader: () => Icons.SpinLoader,
  TeamDashboard: () => Icons.TeamDashboard,
  InvoiceFromMail: () => Icons.InvoiceFromMail,
  PageNotFound: () => Icons.PageNotFound,
  InvoiceUploaded: () => Icons.InvoiceUploaded,
  Rupay: () => Icons.Rupay,
  Chip: () => Icons.Chip,
  PineLabs: () => Icons.PineLabs,
  AcUnit: () => Icons.AcUnit,
  Report: () => Icons.Report,
  SolidCancel: () => Icons.SolidCancel,
  ExpandMore: () => Icons.ExpandMore,
  Inbox: () => Icons.Inbox,
  OCRHigh: () => Icons.OCRHigh,
  OCRLow: () => Icons.OCRLow,
  OCRMedium: () => Icons.OCRMedium,
  OCRPending: () => Icons.OCRPending,
  Communication: () => Icons.Communication,
  LightBulb: () => Icons.LightBulb,

  Entity: () => Icons.Entity,
  Article2: () => Icons.Article2,
  EditNotification: () => Icons.EditNotification,
  Analytics: () => Icons.Analytics,
  PageInfo: () => Icons.PageInfo,
  ListAlt: () => Icons.ListAlt,
  Error404: () => Icons.Error404,
  AccessDeniedError: () => Icons.AccessDeniedError,
  ReadInbox: () => Icons.ReadInbox,
  ReceiptStatus: () => Icons.ReceiptStatus,
  Heart: () => Icons.Heart,
  EyeTracking: () => Icons.EyeTracking,
  Book: () => Icons.Book,
  FeatureSearch: () => Icons.FeatureSearch,
  SupportAgent: () => Icons.SupportAgent,
  MarkEmail: () => Icons.MarkEmail,
  Bulb: () => Icons.Bulb,
  Play: () => Icons.Play,
  Downtime: () => Icons.Downtime,
  InCompleteCircle: () => Icons.InCompleteCircle,
  MasterCard: () => Icons.MasterCard,
  EditUnderline: () => Icons.EditUnderline,
  Timer: () => Icons.Timer,
  InternationalCards: () => Icons.InternationalCards,
  CreditCardGear: () => Icons.CreditCardGear,
  EditInternationalCard: () => Icons.EditInternationalCard,
  VisaCorporateSvg: () => Icons.VisaCorporateSvg,
  InternationalCardTopUpRequest: () => Icons.InternationalCardTopUpRequest,
  MintMark: () => Icons.MintMark,
  ReportFailed: () => Icons.ReportFailed,
  Rotate: () => Icons.Rotate,
};

export const getIconComponent = (name) => {
  const applyMapper = ICONS_MAP[name];
  return applyMapper ? applyMapper() : null;
};
